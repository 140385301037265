import { createApp } from "vue";

import App from "./App.vue";
import ElementPlus from "element-plus";
import "element-plus/lib/theme-chalk/index.css";

import ApiClient from "@/libs/ApiClient";
import { RequestBase, RequestHead } from "@/api/contracts/base";
import AppRouter from "./router/AppRouter";
import StorageManager from "@/libs/StorageManager";
import Uuid from "@/libs/Uuid";
import zhCn from "element-plus/lib/locale/lang/zh-cn";
// import zhCn from 'element-plus/es/locale/lang/zh-cn'

import { Store } from "./data/storage";

import "@/assets/css/workaround.css";
import {
  LocalStorageProvider,
  MemoryStorageProvider,
} from "./libs/StorageProvider";
import Global from "./libs/Global";
import Debug from "./libs/Debug";

if (Global.isElectronApp() && process.env.NODE_ENV != "development") {
  StorageManager.provider = new MemoryStorageProvider();
  Debug.info("Storage provider: MemoryStorageProvider");
} else {
  StorageManager.provider = new LocalStorageProvider();
  Debug.info("Storage provider: LocalStorageProvider");
}

const localStorageProvider = new LocalStorageProvider();
if (localStorageProvider.getItem(StorageManager.deviceId).length === 0) {
  localStorageProvider.setItem(StorageManager.deviceId, Uuid.generate());
}

ApiClient.defaultBaseUrl = process.env.VUE_APP_API_DEFAULT_URL ?? "";
ApiClient.beforeRequest = function<TRequest extends RequestBase>(
  _: ApiClient,
  request: TRequest
) {
  if (request.head === undefined) {
    request.head = new RequestHead();
  }
  request.head.token = StorageManager.getString(StorageManager.token);
  if (request.head?.extensions === undefined) {
    request.head.extensions = {};
  }
  const deviceId = localStorageProvider.getItem(StorageManager.deviceId);
  if (deviceId.length > 0) {
    request.head.extensions.deviceId = deviceId;
  }
  if (Global.isElectronApp()) {
    request.head.extensions.platform = "electron";
  } else {
    request.head.extensions.platform = "web";
  }
  request.head.extensions.version = process.env.VUE_APP_VERSION ?? "0.0.0";
  request.head.extensions.channel = process.env.VUE_APP_CHANNEL ?? ""
  const store: Store | undefined = StorageManager.getObject(
    StorageManager.store
  );
  if (store) {
    request.head.extensions.shopId = store.id.toString();
  }
  return true;
};
createApp(App)
  .use(AppRouter.instance.getVueRouter(process.env.VUE_APP_CHANNEL))
  .use(ElementPlus, { locale:zhCn })
  .mount("#app");
