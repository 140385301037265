import ApiClient from "@/libs/ApiClient";
import {
  GetEmployeeBonusRequest,
  GetEmployeeBonusResponse,
  GetListOfEmployeeBonusRequest,
  GetListOfEmployeeBonusResponse,
  GetListOfItemBonusRequest,
  GetListOfItemBonusResponse,
  CalculateBonusRequest,
  CalculateBonusResponse,
  UpdateItemBonusRequest,
  UpdateItemBonusResponse,
  GetTopUpBonusRequest,
  GetTopUpBonusResponse,
  UpdateTopUpBonusRequest,
  UpdateTopUpBonusResponse,
} from "./contracts/bonus";
import {
  CreateCardTypeRequest,
  CreateCardTypeResponse,
  DeleteCardTypeRequest,
  DeleteCardTypeResponse,
  GetCardTypesRequest,
  GetCardTypesResponse,
  UpdateCardTypeRequest,
  UpdateCardTypeResponse,
} from "./contracts/cardtype";
import {
  GetEmployeeWebMenuRequest,
  GetEmployeeWebMenuResponse,
} from "./contracts/configuration";
import {
  GetListOfEmployeePermissionsRequest,
  GetListOfEmployeePermissionsResponse,
  GetListOfEmployeesRequest,
  GetListOfEmployeesResponse,
  OnboardingEmployeeRequest,
  OnboardingEmployeeResponse,
  RejectEmployeeRequest,
  RejectEmployeeResponse,
  ResignEmployeeRequest,
  ResignEmployeeResponse,
  UpdateEmployeeInfoRequest,
  UpdateEmployeeInfoResponse,
  UpdateEmployeePermissionsRequest,
  UpdateEmployeePermissionsResponse,
} from "./contracts/employee";
import { GetHomePageRequest, GetHomePageResponse } from "./contracts/home";
import {
  CreateItemRequest,
  CreateItemResponse,
  DeleteItemRequest,
  DeleteItemResponse,
  GetListOfItemsRequest,
  GetListOfItemsResponse,
  UpdateItemRequest,
  UpdateItemResponse,
  GetPageOfPayBillOrdersRequest,
  GetPageOfPayBillOrdersResponse,
  GetOrderDetailResponse,
  GetOrderDetailRequest,
} from "./contracts/item";
import {
  AddPayTypeRequest,
  AddPayTypeResponse,
  DeletePayTypeRequest,
  DeletePayTypeResponse,
  GetPayTypesRequest,
  GetPayTypesResponse,
} from "./contracts/paytype";
import {
  GetPointConfigurationsRequest,
  GetPointConfigurationsResponse,
  AddVipPointsRequest,
  AddVipPointsResponse,
  UpdatePointConfigurationRequest,
  UpdatePointConfigurationResponse,
} from "./contracts/point";
import {
  GetPrinterConfigurationRequest,
  GetPrinterConfigurationResponse,
  UpdatePrinterConfigurationRequest,
  UpdatePrinterConfigurationResponse,
} from "./contracts/printer";
import {
  GetPageOfEmployeeSalesRequest,
  GetPageOfEmployeeSalesResponse,
  GetSalesReportRequest,
  GetSalesReportResponse,
  GetVipAmountReportRequest,
  GetVipAmountReportResponse,
} from "./contracts/report";
import {
  GetSmsConfigurationRequest,
  GetSmsConfigurationResponse,
  UpdateSmsConfigurationRequest,
  UpdateSmsConfigurationResponse,
} from "./contracts/sms";
import {
  CheckIfCreatedCompanyRequest,
  CheckIfCreatedCompanyResponse,
  CreateCompanyRequest,
  CreateCompanyResponse,
  CreateShopRequest,
  CreateShopResponse,
  GetShopInfoRequest,
  GetShopInfoResponse,
  GetShopLinkRequest,
  GetShopLinkResponse,
  GetWeixinConfigurationRequest,
  GetWeixinConfigurationResponse,
  UpdateShopInfoRequest,
  UpdateShopInfoResponse,
  UpdateWeixinConfigurationRequest,
  UpdateWeixinConfigurationResponse,
} from "./contracts/store";
import {
  GetSubscriptionPackagesRequest,
  GetSubscriptionPackagesResponse,
  QuerySubscriptionInfoRequest,
  QuerySubscriptionInfoResponse,
  QuerySubscriptionUpgradeRequest,
  QuerySubscriptionUpgradeResponse,
} from "./contracts/subscription";
import {
  GetListOfShopRequest,
  GetListOfShopResponse,
  GetListOfShopsV2Request,
  GetListOfShopsV2Response,
  GetUserInfoRequest,
  GetUserInfoResponse,
  UpdateUserInfoRequest,
  UpdateUserInfoResponse,
} from "./contracts/user";
import {
  AddVipCardRequest,
  AddVipCardResponse,
  GetSystemBillNoRequest,
  GetSystemBillNoResponse,
  TopUpVipCardRequest,
  TopUpVipCardResponse,
  GetPageOfVipRequest,
  GetPageOfVipResponse,
  GetVipInfoRequest,
  GetVipInfoResponse,
  UpdateVipInfoRequest,
  UpdateVipInfoResponse,
  DeleteVipCardRequest,
  DeleteVipCardResponse,
  DeductVipCardRequest,
  DeductVipCardResponse,
  GetPageOfVipLogsRequest,
  GetPageOfVipLogsResponse,
  PayBillRequest,
  PayBillResponse,
  VoidPayBillRequest,
  VoidPayBillResponse,
  VoidVipBillRequest,
  VoidVipBillResponse,
} from "./contracts/vip";
import {
  GetBalanceRequest,
  GetBalanceResponse,
  GetInfoByTransactionIdRequest,
  GetInfoByTransactionIdResponse,
  GetLinkOfRenewSubscriptionRequest,
  GetLinkOfRenewSubscriptionResponse,
  GetLinkOfTopUpBalanceRequest,
  GetLinkOfTopUpBalanceResponse,
  GetPageOfBalanceLogsRequest,
  GetPageOfBalanceLogsResponse,
} from "./contracts/wallet";

import {
  SecurePayRequest,
  
} from "./contracts/securePay";

export default class OrderService {
  static readonly base = "/orderservice/api";
  static readonly client = new ApiClient("");

  static async getListOfShop(
    request: GetListOfShopRequest
  ): Promise<GetListOfShopResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/user/GetListOfShop",
      request
    );
  }

  static async getUserInfo(
    request: GetUserInfoRequest
  ): Promise<GetUserInfoResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/user/GetUserInfo",
      request
    );
  }

  static async AddVipCard(
    request: AddVipCardRequest
  ): Promise<AddVipCardResponse | undefined> {
    return await this.client.postAsync(this.base + "/vip/AddVipCard", request);
  }

  static async UpdateVipInfo(
    request: UpdateVipInfoRequest
  ): Promise<UpdateVipInfoResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/vip/UpdateVipInfo",
      request
    );
  }
  static async DeleteVipCard(
    request: DeleteVipCardRequest
  ): Promise<DeleteVipCardResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/vip/DeleteVipCard",
      request
    );
  }

  static async GetPageOfVip(
    request: GetPageOfVipRequest
  ): Promise<GetPageOfVipResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/vip/GetPageOfVip",
      request
    );
  }
  static async GetVipInfo(
    request: GetVipInfoRequest
  ): Promise<GetVipInfoResponse | undefined> {
    return await this.client.postAsync(this.base + "/vip/GetVipInfo", request);
  }
  static async GetPageOfVipLogs(
    request: GetPageOfVipLogsRequest
  ): Promise<GetPageOfVipLogsResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/vip/GetPageOfVipLogs",
      request
    );
  }

  static async GetPageOfPayBillOrders(
    request: GetPageOfPayBillOrdersRequest
  ): Promise<GetPageOfPayBillOrdersResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/order/GetPageOfPayBillOrders",
      request
    );
  }
  static async GetOrderDetail(
    request: GetOrderDetailRequest
  ): Promise<GetOrderDetailResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/order/GetOrderDetail",
      request
    );
  }

  static async VoidPayBill(
    request: VoidPayBillRequest
  ): Promise<VoidPayBillResponse | undefined> {
    return await this.client.postAsync(this.base + "/bill/VoidBill", request);
  }

  static async VoidVipBill(
    request: VoidVipBillRequest
  ): Promise<VoidVipBillResponse | undefined> {
    return await this.client.postAsync(this.base + "/vip/VoidBill", request);
  }

  static async TopUpVipCard(
    request: TopUpVipCardRequest
  ): Promise<TopUpVipCardResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/vip/TopUpVipCard",
      request
    );
  }

  static async PayBill(
    request: PayBillRequest
  ): Promise<PayBillResponse | undefined> {
    return await this.client.postAsync(this.base + "/bill/payBill", request);
  }

  static async DeductVipCard(
    request: DeductVipCardRequest
  ): Promise<DeductVipCardResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/vip/DeductVipCard",
      request
    );
  }
  static async AddVipPoints(
    request: AddVipPointsRequest
  ): Promise<AddVipPointsResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/points/AddVipPoints",
      request
    );
  }
  static async CalculateBonus(
    request: CalculateBonusRequest
  ): Promise<CalculateBonusResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/bonus/calculateBonus",
      request
    );
  }

  static async GetLisfOfCardTypes(
    request: GetCardTypesRequest
  ): Promise<GetCardTypesResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/card-type/GetListOfCardTypes",
      request
    );
  }

  static async GetLisfOfPayTypes(
    request: GetPayTypesRequest
  ): Promise<GetPayTypesResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/pay-type/GetListOfPayTypes",
      request
    );
  }

  static async GetEmployeeWebMenu(
    request: GetEmployeeWebMenuRequest
  ): Promise<GetEmployeeWebMenuResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/configuration/GetEmployeeWebMenu",
      request
    );
  }

  static async GetListOfEmployees(
    request: GetListOfEmployeesRequest
  ): Promise<GetListOfEmployeesResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/employee/GetListOfEmployees",
      request
    );
  }

  static async GetListOfItems(
    request: GetListOfItemsRequest
  ): Promise<GetListOfItemsResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/items/GetListOfItems",
      request
    );
  }

  static async GetPointConfigurations(
    request: GetPointConfigurationsRequest
  ): Promise<GetPointConfigurationsResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/points/GetPointConfigurations",
      request
    );
  }

  static async GetListOfItemBonus(
    request: GetListOfItemBonusRequest
  ): Promise<GetListOfItemBonusResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/bonus/GetListOfItemBonus",
      request
    );
  }

  static async GetShopInfo(
    request: GetShopInfoRequest
  ): Promise<GetShopInfoResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/store/GetShopInfo",
      request
    );
  }

  static async GetEmployeeBonus(
    request: GetEmployeeBonusRequest
  ): Promise<GetEmployeeBonusResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/bonus/GetEmployeeBonus",
      request
    );
  }

  static async GetListOfEmployeeBonus(
    request: GetListOfEmployeeBonusRequest
  ): Promise<GetListOfEmployeeBonusResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/bonus/GetListOfEmployeeBonus",
      request
    );
  }

  static async GetVipAmountReport(
    request: GetVipAmountReportRequest
  ): Promise<GetVipAmountReportResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/reports/GetVipAmountReport",
      request
    );
  }

  static async GetSystemBillNo(
    request: GetSystemBillNoRequest
  ): Promise<GetSystemBillNoResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/bill/GenerateSystemBillNo",
      request
    );
  }

  static async GetWeixinConfiguration(
    request: GetWeixinConfigurationRequest
  ): Promise<GetWeixinConfigurationResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/store/GetWeixinConfiguration",
      request
    );
  }

  static async ResignEmployee(
    request: ResignEmployeeRequest
  ): Promise<ResignEmployeeResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/employee/ResignEmployee",
      request
    );
  }

  static async OnboardingEmployee(
    request: OnboardingEmployeeRequest
  ): Promise<OnboardingEmployeeResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/employee/OnboardingEmployee",
      request
    );
  }

  static async RejectEmployee(
    request: RejectEmployeeRequest
  ): Promise<RejectEmployeeResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/employee/RejectEmployee",
      request
    );
  }

  static async UpdateEmployeeInfo(
    request: UpdateEmployeeInfoRequest
  ): Promise<UpdateEmployeeInfoResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/employee/UpdateEmployeeInfo",
      request
    );
  }

  static async GetListOfEmployeePermissions(
    request: GetListOfEmployeePermissionsRequest
  ): Promise<GetListOfEmployeePermissionsResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/employee/GetListOfEmployeePermissions",
      request
    );
  }

  static async UpdateEmployeePermissions(
    request: UpdateEmployeePermissionsRequest
  ): Promise<UpdateEmployeePermissionsResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/employee/UpdateEmployeePermissions",
      request
    );
  }

  static async UpdateItem(
    request: UpdateItemRequest
  ): Promise<UpdateItemResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/items/UpdateItem",
      request
    );
  }

  static async CreateItem(
    request: CreateItemRequest
  ): Promise<CreateItemResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/items/CreateItem",
      request
    );
  }

  static async DeleteItem(
    request: DeleteItemRequest
  ): Promise<DeleteItemResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/items/DeleteItem",
      request
    );
  }

  static async CreateCardType(
    request: CreateCardTypeRequest
  ): Promise<CreateCardTypeResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/card-type/CreateCardType",
      request
    );
  }

  static async UpdateShopInfo(
    request: UpdateShopInfoRequest
  ): Promise<UpdateShopInfoResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/store/UpdateShopInfo",
      request
    );
  }

  static async UpdateWeixinConfiguration(
    request: UpdateWeixinConfigurationRequest
  ): Promise<UpdateWeixinConfigurationResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/store/UpdateWeixinConfiguration",
      request
    );
  }

  static async UpdatePointConfiguration(
    request: UpdatePointConfigurationRequest
  ): Promise<UpdatePointConfigurationResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/points/UpdatePointConfiguration",
      request
    );
  }

  static async UpdateItemBonus(
    request: UpdateItemBonusRequest
  ): Promise<UpdateItemBonusResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/bonus/UpdateItemBonus",
      request
    );
  }

  static async AddPayType(
    request: AddPayTypeRequest
  ): Promise<AddPayTypeResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/pay-type/AddPayType",
      request
    );
  }

  static async DeletePayType(
    request: DeletePayTypeRequest
  ): Promise<DeletePayTypeResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/pay-type/DeletePayType",
      request
    );
  }

  static async CheckIfCreatedCompany(
    request: CheckIfCreatedCompanyRequest
  ): Promise<CheckIfCreatedCompanyResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/store/CheckIfCreatedCompany",
      request
    );
  }

  static async CreateCompany(
    request: CreateCompanyRequest
  ): Promise<CreateCompanyResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/store/CreateCompany",
      request
    );
  }

  static async CreateShop(
    request: CreateShopRequest
  ): Promise<CreateShopResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/store/CreateShop",
      request
    );
  }

  static async UpdateUserInfo(
    request: UpdateUserInfoRequest
  ): Promise<UpdateUserInfoResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/user/UpdateUserInfo",
      request
    );
  }

  static async GetSalesReport(
    request: GetSalesReportRequest
  ): Promise<GetSalesReportResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/reports/GetSalesReport",
      request
    );
  }

  static async GetPrinterConfiguration(
    request: GetPrinterConfigurationRequest
  ): Promise<GetPrinterConfigurationResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/printer/GetPrinterConfiguration",
      request
    );
  }

  static async UpdatePrinterConfiguration(
    request: UpdatePrinterConfigurationRequest
  ): Promise<UpdatePrinterConfigurationResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/printer/UpdatePrinterConfiguration",
      request
    );
  }

  static async GetPageOfEmployeeSales(
    request: GetPageOfEmployeeSalesRequest
  ): Promise<GetPageOfEmployeeSalesResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/reports/GetPageOfEmployeeSales",
      request
    );
  }

  static async GetHomePage(
    request: GetHomePageRequest
  ): Promise<GetHomePageResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/home/GetHomePage",
      request
    );
  }

  static async GetTopUpBonus(
    request: GetTopUpBonusRequest
  ): Promise<GetTopUpBonusResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/bonus/GetTopUpBonus",
      request
    );
  }

  static async UpdateTopUpBonus(
    request: UpdateTopUpBonusRequest
  ): Promise<UpdateTopUpBonusResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/bonus/UpdateTopUpBonus",
      request
    );
  }

  static async GetBalance(
    request: GetBalanceRequest
  ): Promise<GetBalanceResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/wallet/GetBalance",
      request
    );
  }

  static async GetLinkOfTopUpBalance(
    request: GetLinkOfTopUpBalanceRequest
  ): Promise<GetLinkOfTopUpBalanceResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/wallet/GetLinkOfTopUpBalance",
      request
    );
  }

  static async GetInfoByTransactionId(
    request: GetInfoByTransactionIdRequest
  ): Promise<GetInfoByTransactionIdResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/wallet/GetInfoByTransactionId",
      request
    );
  }

  static async GetPageOfBalanceLogs(
    request: GetPageOfBalanceLogsRequest
  ): Promise<GetPageOfBalanceLogsResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/wallet/GetPageOfBalanceLogs",
      request
    );
  }

  static async GetSmsConfiguration(
    request: GetSmsConfigurationRequest
  ): Promise<GetSmsConfigurationResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/sms/GetSmsConfiguration",
      request
    );
  }

  static async UpdateSmsConfiguration(
    request: UpdateSmsConfigurationRequest
  ): Promise<UpdateSmsConfigurationResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/sms/UpdateSmsConfiguration",
      request
    );
  }

  static async GetShopLink(
    request: GetShopLinkRequest
  ): Promise<GetShopLinkResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/store/GetShopLink",
      request
    );
  }

  static async GetSubscriptionPackages(
    request: GetSubscriptionPackagesRequest
  ): Promise<GetSubscriptionPackagesResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/subscription/GetSubscriptionPackages",
      request
    );
  }

  static async QuerySubscriptionInfo(
    request: QuerySubscriptionInfoRequest
  ): Promise<QuerySubscriptionInfoResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/subscription/QuerySubscriptionInfo",
      request
    );
  }

  static async GetLinkOfRenewSubscription(
    request: GetLinkOfRenewSubscriptionRequest
  ): Promise<GetLinkOfRenewSubscriptionResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/wallet/GetLinkOfRenewSubscription",
      request
    );
  }

  static async GetListOfShopsV2(
    request: GetListOfShopsV2Request
  ): Promise<GetListOfShopsV2Response | undefined> {
    return await this.client.postAsync(
      this.base + "/user/GetListOfShopsV2",
      request
    );
  }

  static async DeleteCardType(
    request: DeleteCardTypeRequest
  ): Promise<DeleteCardTypeResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/card-type/DeleteCardType",
      request
    );
  }

  static async UpdateCardType(
    request: UpdateCardTypeRequest
  ): Promise<UpdateCardTypeResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/card-type/UpdateCardType",
      request
    );
  }

  static async QuerySubscriptionUpgrade(
    request: QuerySubscriptionUpgradeRequest
  ): Promise<QuerySubscriptionUpgradeResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/subscription/QuerySubscriptionUpgrade",
      request
    );
  }

  static async GetSecurePayCode(
    request: SecurePayRequest
  ): Promise<any> {
    return await this.client.postAsync(
      this.base + "/bill/GetSecurePayCode",
      request
    );
  }
  
}
