import { RequestBase, ResponseBase } from "./base";

export class AddVipCardRequestData {
  phone?: string;
  name?: string;
  gender?: string;
  cardTypeId = 0;
  birthday?:string;
  cardNo?:string;
}

export class AddVipCardRequest extends RequestBase {
  data?: AddVipCardRequestData;
}

export class AddVipCardResponseData {
  vipId = 0;
}

export class AddVipCardResponse extends ResponseBase {
  data?: AddVipCardResponseData;
}


export class GetSystemBillNoResponseData {
  systemBillNo = "";
}
export class GetSystemBillNoRequest extends RequestBase {}

export class GetSystemBillNoResponse extends ResponseBase {
  data?: GetSystemBillNoResponseData;
}

export class TopUpVipCardRequestData {
  category="";
  vipId=0;
  payId=0;
  systemBillNo="";
  manualBillNo="";
  topUpAmount="";
  discountAmount=0;
  remark="";
  topUpItems=[] as any [];
  employeeBonuses=[] as any [];

}

export class TopUpVipCardRequest extends RequestBase {
  data?: TopUpVipCardRequestData;
}

export class TopUpVipCardResponseData {
  billId?: number
}

export class TopUpVipCardResponse extends ResponseBase {
  data?: TopUpVipCardResponseData;
}



export class GetPageOfVipRequest extends RequestBase {
  data?: GetPageOfVipRequestData;
}
export class GetPageOfVipRequestData {
  pageNumber=1
  pageSize=20
  keyword=''
}

export class GetPageOfVipResponse extends ResponseBase {
  data?: GetPageOfVipResponseData;
}
export class GetPageOfVipResponseData {
  items=[] as Array<PageOfVipItem>
  totalCount=0
}
export class PageOfVipItem{
  vipId=0
  name=""
  gender=""
  cardTypeId=0
  phone=""
  balance=0
  birthday=''
  cardNo=""
  disabled=false
}



export class GetVipInfoRequest extends RequestBase {
  data?: GetVipInfoRequestData;
}
export class GetVipInfoRequestData {
  phone?: string;
  vipId?: number;
}

export class GetVipInfoResponse extends ResponseBase {
  data=new GetVipInfoResponseData();
}
export class GetVipInfoResponseData {
  vipId=0
  name=""
  gender=""
  cardTypeId=0
  phone=""
  cardNo=""
  balance=0
  discountValue=0
  remark=""
  points=0
  timeBalance :VipInfoTimeBalance [] =[]
  cardType?: GetVipInfoResponseCardType
  enableSecurePay=false
}

export class GetVipInfoResponseCardType {
  id?: number;
  name?: string;
  oneShopOnly?: boolean; 
}

export class VipInfoTimeBalance{
  itemId=0
  count=0
  name=""
}


export class UpdateVipInfoRequest extends RequestBase {
  data?: UpdateVipInfoRequestData;
}
export class UpdateVipInfoRequestData {
  phone=''
  name= ""
  gender="F"
  cardTypeId=0
  vipId=0
  birthday=''
  cardNo=""
}

export class UpdateVipInfoResponse extends ResponseBase {}



export class DeleteVipCardRequest extends RequestBase {
  data?: DeleteVipCardRequestData;
}
export class DeleteVipCardRequestData {
  vipId=0
}

export class DeleteVipCardResponse extends ResponseBase {}

export class DeductVipCardRequestData {
  vipId=0;
  systemBillNo="";
  manualBillNo="";
  deductAmount="";
  discountAmount=0;
  remark="";
  deductItems=[] as any [];
  employeeBonuses=[] as any [];
  securePayCode=""

}

export class DeductVipCardRequest extends RequestBase {
  data?: DeductVipCardRequestData;
}

export class DeductVipCardResponseData {
  billId=0
}

export class DeductVipCardResponse extends ResponseBase {
  data?: DeductVipCardResponseData;
}



export class GetPageOfVipLogsRequest extends RequestBase {
  data?: GetPageOfVipLogsRequestData;
}
export class GetPageOfVipLogsRequestData {
  pageNumber?: number;
  pageSize?: number;
  phone?: string;
  vipId?: number;
}

export class GetPageOfVipLogsResponse extends ResponseBase {
  data?: GetPageOfVipLogsResponseData;
}
export class GetPageOfVipLogsResponseData {
  items=[] as Array<VipLogsItem>
  totalCount=0
}
export class VipLogsItem{
  logType=""
  logName=""
  amount=0
  time=""
  vipName=""
  phone=""
  remark=""
  billId=0
  systemBillNo=""
}
 
 
export class PayBillRequest extends RequestBase {
  data?: PayBillRequestData;
}
export class PayBillRequestData {
  vipId=0
  payId=0
  systemBillNo=''
  manualBillNo=''
  discountAmount=0
  couponNumber=''
  payTime=''
  remark=''
  billItems=[] as any []
  employeeBonuses=[] as any []

  disableNodify=false
  securePayCode=''
   
}

export class PayBillResponse extends ResponseBase {
  data?: PayBillResponseData;
}
export class PayBillResponseData {
  billId?: number
}



export class VoidPayBillRequest extends RequestBase {
  data?: VoidPayBillRequestData;
}
export class VoidPayBillRequestData {
  billId=0
}

export class VoidPayBillResponse extends ResponseBase {}

export class VoidVipBillRequest extends RequestBase {
  data?: VoidVipBillRequestData;
}
export class VoidVipBillRequestData {
  billId=0
}

export class VoidVipBillResponse extends ResponseBase {}
