
import OrderService from "@/api/OrderService";
import { TabVue } from "@/libs/TabVue";
import Validator from "@/libs/Validator";
import { Options } from "vue-class-component";

@Options({})
export default class PointConfigurationPage extends TabVue {
  loading = true;
  saving = false;
  showSaveButton = false;
  formData: PointFormData = {
    enableGiftForTopUp: false,
    enableGiftForPayBill: false,
    enableRedeemForPayBill: false,
    pointRatio: "",
    pointRatioValue: 0,
  };
  enableGiftForTopUpLoading = false;
  enableGiftForPayBillLoading = false;
  enableRedeemForPayBillLoading = false;
  async refresh(): Promise<void> {
    await this.loadData();
  }
  async loadData(): Promise<void> {
    this.loading = true;
    const response = await OrderService.GetPointConfigurations({});
    this.loading = false;
    if (response && response.status === 200) {
      if (response.data) {
        this.formData.enableGiftForTopUp =
          response.data.enableGiftForTopUp ?? false;
        this.formData.enableGiftForPayBill =
          response.data.enableGiftForPayBill ?? false;
        this.formData.enableRedeemForPayBill =
          response.data.enableRedeemForPayBill ?? false;
        this.formData.pointRatio = (response.data.pointRatio ?? 0).toString();
        this.formData.pointRatioValue = response.data.pointRatio ?? 0;
      }
    } else {
      this.handleApiError(response);
    }
  }
  async toggleEnableGiftForTopUp(): Promise<boolean> {
    this.enableGiftForTopUpLoading = true;
    const response = await OrderService.UpdatePointConfiguration({
      data: {
        enableGiftForTopUp:
          this.formData.enableGiftForTopUp == true ? "OFF" : "ON",
      },
    });
    this.enableGiftForTopUpLoading = false;
    if (!response || response.status != 200) {
      this.handleApiError(response);
      return false;
    }
    this.showSuccessToast("保存成功");
    return true;
  }
  async toggleEnableGiftForPayBill(): Promise<boolean> {
    this.enableGiftForPayBillLoading = true;
    const response = await OrderService.UpdatePointConfiguration({
      data: {
        enableGiftForPayBill:
          this.formData.enableGiftForPayBill == true ? "OFF" : "ON",
      },
    });
    this.enableGiftForPayBillLoading = false;
    if (!response || response.status != 200) {
      this.handleApiError(response);
      return false;
    }
    this.showSuccessToast("保存成功");
    return true;
  }
  async toggleEnableRedeemForPayBill(): Promise<boolean> {
    this.enableRedeemForPayBillLoading = true;
    const response = await OrderService.UpdatePointConfiguration({
      data: {
        enableRedeemForPayBill:
          this.formData.enableRedeemForPayBill == true ? "OFF" : "ON",
      },
    });
    this.enableRedeemForPayBillLoading = false;
    if (!response || response.status != 200) {
      this.handleApiError(response);
      return false;
    }
    this.showSuccessToast("保存成功");
    return true;
  }
  changed(value: string): void {
    console.log("input change to " + value);
    if (!Validator.isPositiveNumber(this.formData.pointRatio)) {
      this.showErrorToast("积分比例值必须为大于0的数字");
      return;
    }
    this.showSaveButton =
      Number(this.formData.pointRatio) != this.formData.pointRatioValue;
  }
  async save(): Promise<void> {
    if (!Validator.isPositiveNumber(this.formData.pointRatio)) {
      this.showErrorToast("积分比例值必须为大于0的数字");
      return;
    }
    this.saving = true;
    const response = await OrderService.UpdatePointConfiguration({
      data: {
        pointRatio: this.formData.pointRatio,
      },
    });
    this.saving = false;
    if (!response || response.status != 200) {
      this.handleApiError(response);
      return;
    }
    this.formData.pointRatioValue = Number(this.formData.pointRatio);
    this.changed(this.formData.pointRatio);
    this.showSuccessToast("保存成功");
    return;
  }
}

interface PointFormData {
  enableGiftForTopUp: boolean;
  enableGiftForPayBill: boolean;
  enableRedeemForPayBill: boolean;
  pointRatio: string;
  pointRatioValue: number;
}
