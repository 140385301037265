
import OrderService from "@/api/OrderService";
import { BaseVue } from "@/libs/BaseVue";
import { Options } from "vue-class-component";

@Options({
  emits: {
    selected: () => true,
    cleared: () => true,
    "update:modelValue": () => true,
  },
  props: {
    modelValue: Number,
  },
})
export default class VipSearchSelector extends BaseVue {
  modelValue = 0;
  public get selectedVipId(): number | string {
    return this.modelValue <= 0 ? "" : this.modelValue;
  }
  public set selectedVipId(value: number | string) {
    if (value == "") {
      this.$emit("update:modelValue", 0);
    } else {
      this.$emit("update:modelValue", value ?? 0);
    }
  }
  vipSelectionItems: VipSelectionItem[] = [];
  searching = false;
  created(): void {}
  onVipSelected(value: number) {
    this.$emit("selected", value);
  }
  onVipCleared() {
    this.vipSelectionItems = [];
    this.$emit("cleared");
  }
  async search(query: string) {
    if (query.trim().length == 0) {
      this.vipSelectionItems = [];
      return;
    }
    this.searching = true;
    const response = await OrderService.GetPageOfVip({
      data: {
        keyword: query.trim(),
        pageNumber: 1,
        pageSize: 20,
      },
    });
    this.searching = false;
    if (response && response.status === 200) {
      this.vipSelectionItems = (response.data?.items ?? []).map((x) => {
        return {
          id: x.vipId,
          name: x.name,
          phone: x.phone,
        };
      });
    } else {
      this.handleApiError(response);
    }
  }
}

interface VipSelectionItem {
  id: number;
  name: string;
  phone: string;
}
