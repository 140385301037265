
import OrderService from "@/api/OrderService";
import { TabVue } from "@/libs/TabVue";
import { Options } from "vue-class-component";

@Options({})
export default class WeixinConfigurationPage extends TabVue {
  loading = true;
  messageEnabledLoading = false;
  formData: WeixinFormData = {
    messageEnabled: false,
    securePayEnabled:false,
  };
  async refresh(): Promise<void> {
    await this.loadData();
  }
  async loadData(): Promise<void> {
    this.loading = true;
    const response = await OrderService.GetWeixinConfiguration({});
    this.loading = false;
    if (response && response.status === 200) {
      if (response.data) {
        this.formData.messageEnabled = response.data.messageEnabled ?? false;
        this.formData.securePayEnabled = response.data.securePayEnabled ?? false;
      }
    } else {
      this.handleApiError(response);
    }
  }
  async toggleMessageEnabled(): Promise<boolean> {
    this.messageEnabledLoading = true;
    const response = await OrderService.UpdateWeixinConfiguration({
      data: {
        messageEnabled: this.formData.messageEnabled == true ? "OFF" : "ON",
        securePayEnabled:this.formData.securePayEnabled?'ON':'OFF'
      },
    });
    this.messageEnabledLoading = false;
    if (!response || response.status != 200) {
      this.handleApiError(response);
      return false;
    }
    this.showSuccessToast("保存成功");
    return true;
  }

  async toggleSecurePayEnabled(): Promise<boolean> {
    this.messageEnabledLoading = true;
    const response = await OrderService.UpdateWeixinConfiguration({
      data: {
        messageEnabled: this.formData.messageEnabled?'ON':'OFF',
        securePayEnabled:this.formData.securePayEnabled?'OFF':'ON'
      },
    });
    this.messageEnabledLoading = false;
    if (!response || response.status != 200) {
      this.handleApiError(response);
      return false;
    }
    this.showSuccessToast("保存成功");
    return true;
  }
}

interface WeixinFormData {
  messageEnabled?: boolean;
  securePayEnabled?:boolean;
}
