
import { Options } from "vue-class-component";
import SubscriptionPackages from "@/components/SubscriptionPackages.vue";
import { BaseVue } from "@/libs/BaseVue";
import AppRouter from "@/router/AppRouter";

@Options({
  components: {
    SubscriptionPackages,
  },
})
export default class SubscriptionPlansPage extends BaseVue {
  shopId = 0;
  created(): void {
    this.shopId = Number.parseInt(this.$route.query.shopId as string);
  }
  subscribed(): void {
    AppRouter.instance.goTo(this.$route.query.origin as string);
  }
  goBack(): void {
    AppRouter.instance.goTo(this.$route.query.origin as string);
  }
}
