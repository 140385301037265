
import { GetPayTypesResponseItem } from "@/api/contracts/paytype";
import OrderService from "@/api/OrderService";
import { TabVue } from "@/libs/TabVue";
import Validator from "@/libs/Validator";
import { ElMessageBox } from "element-plus";
import { Options } from "vue-class-component";
import StorageManager from '@/libs/StorageManager'

@Options({})
export default class PayTypeConfigurationPage extends TabVue {
  loading = true;
  tableData: PayTypeTableRow<GetPayTypesResponseItem>[] = [];
  dialogVisible = false;
  formData: PayTypeFormData = {};
  saving = false;
  async refresh(): Promise<void> {
    await this.loadTableData();
  }
  async loadTableData(): Promise<void> {
    this.tableData = [];
    this.loading = true;
    const response = await OrderService.GetLisfOfPayTypes({});
    this.loading = false;
    if (response && response.status === 200) {

       const storeList = response.data?.items?.map(a=>{
        return {id:a.id,name:a.name,type:a.type}
      })
       StorageManager.setObject(StorageManager.payTypes, storeList);

      response.data?.items?.forEach((item) => {
        this.tableData.push({
          source: item,
          name: item.name,
        });
      });
    } else {
      this.handleApiError(response);
    }
  }
  handleCreate() {
    this.formData = { };
    this.dialogVisible = true;
  }
  handleDelete(_: number, row: PayTypeTableRow<GetPayTypesResponseItem>): void {
    ElMessageBox.confirm("确认删除支付方式, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        this.loading = true;
        const response = await OrderService.DeletePayType({
          data: {
            id: row.source.id,
          },
        });
        this.loading = false;
        if (!response || response.status != 200) {
          this.handleApiError(response);
          return;
        }
        await this.loadTableData();
      })
      .catch(() => {});
  }
  async save(): Promise<void> {
    if (!Validator.isNotBlankString(this.formData.name)) {
      this.showErrorToast("支付方式名称不能为空");
      return;
    }
    this.saving = true;
    const response = await OrderService.AddPayType({
      data: {
        name: this.formData.name,
      },
    });
    this.saving = false;
    if (!response || response.status != 200) {
      this.handleApiError(response);
      return;
    }
    this.dialogVisible = false;
    this.loadTableData();
  }
}

interface PayTypeTableRow<T> {
  source: T;
  name?: string;
}

interface PayTypeFormData {
  name?: string;
}
