
import OrderService from "@/api/OrderService";
import { TabVue } from "@/libs/TabVue";
import { Options } from "vue-class-component";
import SubscriptionPackages from "@/components/SubscriptionPackages.vue";

@Options({
  components: {
    SubscriptionPackages,
  },
})
export default class AccountSubscriptionPage extends TabVue {
  statusIcon = "info";
  statusTitle = "";
  statusSubtitle = "";
  statusLoading = false;
  expireTime = "";
  async refresh(): Promise<void> {
    await this.loadStatus();
  }
  async loadStatus(): Promise<void> {
    this.statusLoading = true;
    const response = await OrderService.QuerySubscriptionInfo({});
    this.statusLoading = false;
    if (response && response.status === 200) {
      if (response.data) {
        if (response.data.pc?.status == "SUBSCRIBED") {
          this.statusIcon = "success";
          this.statusTitle =  `已购买（${response.data.editionName}）`;
          this.statusSubtitle = response.data.pc?.prompt ?? "";
          this.expireTime = response.data.pc?.expireTime ?? "";
        } else if (response.data.pc?.status == "EXPIRED") {
          this.statusIcon = "warning";
          this.statusTitle = "已过期";
          this.statusSubtitle = response.data.pc?.prompt ?? "";
        } else if (response.data.pc?.status == "TRIAL") {
          this.statusIcon = "info";
          this.statusTitle = "试用中";
          this.statusSubtitle = response.data.pc?.prompt ?? "";
        } else {
          this.statusIcon = "error";
          this.statusTitle = "未获取";
          this.statusSubtitle = response.data.pc?.prompt ?? "";
        }
      }
    } else {
      this.handleApiError(response);
    }
  }
  async subscribed(): Promise<void> {
    await this.loadStatus();
  }
}
