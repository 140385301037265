
import { GetSubscriptionPackagesResponsePackage } from "@/api/contracts/subscription";
import OrderService from "@/api/OrderService";
import { BaseVue } from "@/libs/BaseVue";
import Timer from "@/libs/Timer";
import QRCodeVue3 from "qrcode-vue3";
import { Options } from "vue-class-component";

@Options({
  components: {
    QRCodeVue3,
  },
  emits: {
    subscribed: null,
  },
  props: {
    modelValue: {
      type: Object,
      default: {
        source: new GetSubscriptionPackagesResponsePackage(),
      },
    },
    shopId: {
      type: Number,
      default: 0,
    },
  },
})
export default class SubscriptionPackages extends BaseVue {
  modelValue = {
    source: new GetSubscriptionPackagesResponsePackage(),
  };
  gettingPcakages = false;
  shopId = 0;
  transactionId?: string;
  payAmount = 0;
  qrcode = "";
  dialogVisible = false;
  linkStatusChecker?: LinkStatusChecker;
  packages: SubscriptionPackage[] = [];
  upgradeable = false;
  upgradeEdition = "";
  async created(): Promise<void> {
    await this.refresh();
  }
  async refresh() {
    this.gettingPcakages = true;
    const response = await OrderService.GetSubscriptionPackages({
      data: {
        shopId: this.shopId,
      },
    });
    this.gettingPcakages = false;
    if (response && response.status === 200) {
      if (response.data) {
        response.data.packages?.forEach((x) =>
          this.packages.push({
            source: x,
            title: x.title,
            price: (x.price ?? 0).toString(),
            period: x.period,
            features: (x.remark ?? "").split("\n"),
            gettingLink: false,
          })
        );
        if (response.data.upgrade && response.data.upgrade.upgradeable) {
          this.upgradeable = true;
          this.upgradeEdition = response.data.upgrade.editionName ?? "";
        }
      }
    } else {
      this.handleApiError(response);
    }
  }
  beforeUnmount(): void {
    this.linkStatusChecker?.stop();
  }
  public get selectedPackage(): SubscriptionPackage {
    return this.modelValue;
  }
  public set selectedPackage(value: SubscriptionPackage) {
    this.$emit("update:modelValue", value);
  }
  async subscribe(pkg: SubscriptionPackage): Promise<void> {
    pkg.gettingLink = true;
    const response = await OrderService.GetLinkOfRenewSubscription({
      data: {
        packageId: pkg.source.packageId,
        shopId: this.shopId,
      },
    });
    pkg.gettingLink = false;
    if (!response || response.status != 200) {
      this.handleApiError(response);
      return;
    }
    this.dialogShow(
      pkg.source.price ?? 0,
      response.data?.link ?? "",
      response.data?.transactionId ?? "",
      response.data?.validSeconds ?? 60,
      () => {
        this.$emit("subscribed", pkg);
      }
    );
  }
  async upgrade() {
    const response = await OrderService.QuerySubscriptionUpgrade({});
    if (!response || response.status != 200) {
      this.handleApiError(response);
      return;
    }
    if ((response.data?.amount ?? 0) <= 0) {
      return;
    }
    this.dialogShow(
      response.data?.amount ?? 0,
      response.data?.link ?? "",
      response.data?.transactionId ?? "",
      response.data?.validSeconds ?? 60,
      async () => {
        await this.refresh();
      }
    );
  }
  dialogShow(
    payAmount: number,
    link: string,
    transactionId: string,
    seconds: number,
    completed?: () => void
  ): void {
    this.dialogVisible = true;
    this.payAmount = payAmount;
    this.qrcode = link;
    this.transactionId = transactionId;
    this.linkStatusChecker = new LinkStatusChecker(
      seconds,
      async (secondsLeft) => {
        if (secondsLeft <= 0) {
          this.showErrorToast("支付链接已过期，请重新点击购买");
          this.dialogVisible = false;
          return;
        }
        if (this.transactionId) {
          const response = await OrderService.GetInfoByTransactionId({
            data: {
              transactionId: this.transactionId,
            },
          });
          if (
            response &&
            response.status == 200 &&
            response.data?.paid == true
          ) {
            this.showSuccessToast("支付成功");
            this.dialogVisible = false;
            if (completed) {
              completed();
            }
          }
        }
      }
    );
  }
  dialogClose(): void {
    this.linkStatusChecker?.stop();
  }
}

export interface SubscriptionPackage {
  source: GetSubscriptionPackagesResponsePackage;
  title?: string;
  price?: string;
  period?: string;
  features?: string[];
  gettingLink?: boolean;
}

class LinkStatusChecker extends Timer {
  constructor(
    secondsLeft: number,
    backgroundTask: (secondsLeft: number) => Promise<void>
  ) {
    super(3000);
    this.secondsLeft = secondsLeft;
    this.backgroundTask = backgroundTask;
    this.start();
  }
  private secondsLeft = -1;
  private backgroundTask: (secondsLeft: number) => Promise<void>;
  elapse(): void {
    if (this.secondsLeft <= 0) {
      this.stop();
    }
    this.secondsLeft -= 3;
    this.backgroundTask(this.secondsLeft);
  }
}
