
import OrderService from "@/api/OrderService";
import { Options } from "vue-class-component";
import VipSearchSelector from "@/components/VipSearchSelector.vue";
import { TabVue } from "@/libs/TabVue";

@Options({
  components: {
    VipSearchSelector,
  },
})
export default class VipRecordPage extends TabVue {
  selectedVipId = 0;
  logItems: LogItem[] = [];
  vipInfo: VipInfo = {
    name: "",
    phone: "",
    cardNo: "",
    cardTypeName: "",
    balance: "",
    times: "",
    points: "",
    scope: "",
  };
  async refresh(): Promise<void> {
    this.selectedVipId = 0;
    this.vipInfo = {
      name: "",
      phone: "",
      cardNo: "",
      cardTypeName: "",
      scope: "",
      balance: "",
      times: "",
      points: "",
    };
    this.logItems = [];
  }
  async getVipLogs(vipId: number): Promise<void> {
    const response = await OrderService.GetPageOfVipLogs({
      data: {
        vipId: vipId,
        pageNumber: 1,
        pageSize: 100,
      },
    });
    if (response && response.status === 200) {
      if (response.data?.items) {
        this.logItems = response.data?.items.map((a) => {
          return {
            logName: a.logName,
            time: a.time,
            amount: a.amount,
            vipName: a.vipName,
            phone: a.phone,
            remark: a.remark,
            billId: a.billId,
            systemBillNo: a.systemBillNo,
          };
        });
      }
    } else {
      this.handleApiError(response);
    }
  }
  async onVipSelect(val: number) {
    if (val <= 0) {
      return;
    }
    const response = await OrderService.GetVipInfo({
      data: {
        vipId: val,
      },
    });
    if (response && response.status == 200) {
      this.vipInfo = {
        name: response.data.name,
        phone: response.data.phone,
        cardNo: response.data.cardNo,
        cardTypeName: response.data.cardType?.name ?? "",
        balance: response.data.balance.toString(),
        times: response.data.timeBalance.length.toString(),
        points: response.data.points.toString(),
        scope:
          response.data.cardType?.oneShopOnly == true
            ? "仅限开卡门店使用"
            : "全部门店可用",
      };
      await this.getVipLogs(val);
    } else {
      this.handleApiError(response);
    }
  }
  onVipClear() {
    this.refresh();
  }
}

interface VipInfo {
  name: string;
  phone: string;
  cardNo: string;
  cardTypeName: string;
  scope: string;
  balance: string;
  times: string;
  points: string;
}

interface LogItem {
  logName?: string;
  amount?: number;
  time?: string;
  vipName?: string;
  phone?: string;
  remark?: string;
  billId?: number;
  systemBillNo?: string;
}
