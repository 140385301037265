import { RequestBase, ResponseBase } from "./base";

export class GetListOfItemsRequest extends RequestBase {}

export class GetListOfItemsResponseItem {
  id?: number;
  name?: string;
  price?: number;
  vipPrice?: number;
}

export class GetListOfItemsResponseData {
  items?: GetListOfItemsResponseItem[];
}

export class GetListOfItemsResponse extends ResponseBase {
  data?: GetListOfItemsResponseData;
}

export class UpdateItemRequestData {
  id?: number;
  name?: string;
  price?: number;
  vipPrice?: number;
}

export class UpdateItemRequest extends RequestBase {
  data?: UpdateItemRequestData;
}

export class UpdateItemResponse extends ResponseBase {}

export class CreateItemRequestData {
  name?: string;
  price?: number;
  vipPrice?: number;
}

export class CreateItemRequest extends RequestBase {
  data?: CreateItemRequestData;
}

export class CreateItemResponse extends ResponseBase {}

export class DeleteItemRequestData {
  id?: number;
}

export class DeleteItemRequest extends RequestBase {
  data?: DeleteItemRequestData;
}

export class DeleteItemResponse extends ResponseBase {}


export class GetPageOfPayBillOrdersRequest extends RequestBase {
  data?: GetPageOfPayBillOrdersData;
}

export class GetPageOfPayBillOrdersData {
  keyword=""
  pageNumber=1
  pageSize=10
}

export class GetPageOfPayBillOrdersResponse extends ResponseBase {
  data?:GetPageOfPayBillOrdersResponseData;
}
export class GetPageOfPayBillOrdersResponseData {
  items=[] as Array<GetPageOfPayBillOrdersResponseItem>
  totalCount=0
}

export class GetPageOfPayBillOrdersResponseItem {
  billId=0
  systemBillNo=""
  vipId=0
  vipName=""
  billType=0
  phone=""
  payType=0
  payId=0
  payAmount=0
  couponAmount=0
  time=""
}

export class GetOrderDetailRequest extends RequestBase {
  data?: GetOrderDetailRequestData;
}

export class GetOrderDetailRequestData {
  billId?: number;
}
export class GetOrderDetailResponse extends ResponseBase {
  data?:GetOrderDetailResponseData
}
export class GetOrderDetailResponseData {
  billId=0
  systemBillNo=""
  manualBillNo=""
  billType=0
  vipName=""
  phone=""
  payType=0
  payAmount=0
  orderAmount = 0
  payId=0
  discountAmount=0
  couponAmount=0
  costPoints=0
  remark=''
  time=''
  operatorName=''
  bonuses=[] as Array<GetOrderDetailResponseBonuse>
  items=[] as Array<GetOrderDetailResponseItem>
}
export class GetOrderDetailResponseBonuse {
  userId=0
  name=""
  bonus=0
}

export class GetOrderDetailResponseItem {
  itemId=0
  itemName=""
  price=0
  purchaseType=0
  count=0
}








