
import AppRouter from "@/router/AppRouter";
import { Options, Vue } from "vue-class-component";

@Options({})
export default class AuthWindow extends Vue {
  currentRouteName = AppRouter.loginByPassword;
  loginByPassword(): void {
    AppRouter.instance.goTo(AppRouter.loginByPassword, {});
    this.currentRouteName = AppRouter.loginByPassword;
  }
  loginByCaptcha(): void {
    AppRouter.instance.goTo(AppRouter.loginByCaptcha, {});
    this.currentRouteName = AppRouter.loginByCaptcha;
  }
  findPassword(): void {
    AppRouter.instance.goTo(AppRouter.findPassword, {});
    this.currentRouteName = AppRouter.findPassword;
  }
}
