import ApiClient from "@/libs/ApiClient";
import {
  AddCouponStrategyRequest,
  AddCouponStrategyResponse,
  DeleteCouponStrategyRequest,
  DeleteCouponStrategyResponse,
  DisableCouponStrategyRequest,
  DisableCouponStrategyResponse,
  EnableCouponStrategyRequest,
  EnableCouponStrategyResponse,
  GetCouponStrategiesRequest,
  GetCouponStrategiesResponse,
  IssueCouponRequest,
  IssueCouponResponse,
  GetCouponInfoRequest,
  GetCouponInfoResponse
} from "./contracts/coupon";

export default class MarketingService {
  static readonly base = "/marketingservice/api";
  static readonly client = new ApiClient("");

  static async GetCouponStrategies(
    request: GetCouponStrategiesRequest
  ): Promise<GetCouponStrategiesResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/coupon/GetCouponStrategies",
      request
    );
  }

  static async GetCouponInfo(
    request: GetCouponInfoRequest
  ): Promise<GetCouponInfoResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/coupon/GetCouponInfo",
      request
    );
  }
  

  static async IssueCoupon(
    request: IssueCouponRequest
  ): Promise<IssueCouponResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/coupon/IssueCoupon",
      request
    );
  }

  static async AddCouponStrategy(
    request: AddCouponStrategyRequest
  ): Promise<AddCouponStrategyResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/coupon/AddCouponStrategy",
      request
    );
  }

  static async DeleteCouponStrategy(
    request: DeleteCouponStrategyRequest
  ): Promise<DeleteCouponStrategyResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/coupon/DeleteCouponStrategy",
      request
    );
  }

  static async EnableCouponStrategy(
    request: EnableCouponStrategyRequest
  ): Promise<EnableCouponStrategyResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/coupon/EnableCouponStrategy",
      request
    );
  }

  static async DisableCouponStrategy(
    request: DisableCouponStrategyRequest
  ): Promise<DisableCouponStrategyResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/coupon/DisableCouponStrategy",
      request
    );
  }
}
