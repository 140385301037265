
import OrderService from "@/api/OrderService";
import { BaseVue } from "@/libs/BaseVue";
import { Options } from "vue-class-component";
import { watch } from "vue";
import { BillTypeConstants, PurchaseTypeConstants } from "@/data/constants";
import { PayTypeConfiguration } from "@/data/configurations";

@Options({
  emits: {
    close: () => true,
  },
  props: {
    billId: {
      type: Number,
      default: 0,
    },
  },
})
export default class BillDetailDialog extends BaseVue {
  dialogVisible = false;
  billId = 0;
  billDetail: BillDetail = {};
  billItems: BillItem[] = [];
  billBonuses: BillBonus[] = [];
  loading = false;
  created(): void {
    watch(
      () => this.billId,
      (newValue, oldValue) => {
        if (newValue != oldValue) {
          if (newValue > 0) {
            this.dialogVisible = true;
            this.query();
          } else {
            this.dialogVisible = false;
            this.billDetail = {};
            this.billItems = [];
            this.billBonuses = [];
          }
        }
      }
    );
  }
  closeButtonClicked(): void {
    this.$emit("close");
  }
  async query(): Promise<void> {
    this.loading = true;
    const response = await OrderService.GetOrderDetail({
      data: {
        billId: this.billId,
      },
    });
    this.loading = false;
    if (!response || response.status != 200) {
      this.handleApiError(response);
      return;
    }
    this.billDetail = {
      time: response.data?.time ?? "",
      systemBillNo: response.data?.systemBillNo ?? "",
      manualBillNo: response.data?.manualBillNo ?? "",
      billTypeLabel: BillTypeConstants.valueOf(response.data?.billType ?? 0),
      phone: response.data?.phone ?? "",
      name: response.data?.vipName ?? "",
      orderAmount: (response.data?.orderAmount ?? 0).toString(),
      couponAmount: (response.data?.couponAmount ?? 0).toString(),
      costPoints: (response.data?.costPoints ?? 0).toString(),
      discountAmount: (response.data?.discountAmount ?? 0).toString(),
      payAmount: (response.data?.payAmount ?? 0).toString(),
      payTypeLabel:
        PayTypeConfiguration.nameOf(response.data?.payId ?? 0) ?? "无",
      remark: response.data?.remark ?? "",
      operatorName: response.data?.operatorName ?? "",
    };
    if (response.data?.items && response.data?.items.length > 0) {
      response.data?.items.forEach((value, index) => {
        this.billItems.push({
          index: (index + 1).toString(),
          name: value.itemName ?? "",
          purchaseTypeLabel: PurchaseTypeConstants.valueOf(value.purchaseType),
          price: value.price.toString(),
          count: value.count.toString(),
        });
      });
    }
    if (response.data?.bonuses && response.data?.bonuses.length > 0) {
      response.data.bonuses.forEach((value, index) => {
        this.billBonuses.push({
          index: (index + 1).toString(),
          name: value.name,
          bonus: value.bonus.toString(),
        });
      });
    }
  }
}

interface BillDetail {
  time?: string;
  systemBillNo?: string;
  manualBillNo?: string;
  billTypeLabel?: string;
  phone?: string;
  name?: string;
  orderAmount?: string;
  couponAmount?: string;
  costPoints?: string;
  discountAmount?: string;
  payAmount?: string;
  payTypeLabel?: string;
  remark?: string;
  operatorName?: string;
}

interface BillItem {
  index?: string;
  name?: string;
  purchaseTypeLabel?: string;
  price?: string;
  count?: string;
}

interface BillBonus {
  index?: string;
  name?: string;
  bonus?: string;
}
