import { GetEmployeeWebMenuResponseMenuItem } from "@/api/contracts/configuration";
import OrderService from "@/api/OrderService";
import {
  CardType,
  Employee,
  MenuItem,
  MenuRoute,
  PayType,
  Printer,
  ProjectItem,
} from "@/data/storage";
import AppRouter from "@/router/AppRouter";
import StorageProvider from "./StorageProvider";

export default class StorageManager {
  static readonly deviceId = "DEVICE_ID";
  static readonly token = "TOKEN";
  static readonly store = "STORE";
  static readonly userInfo = "USER_INFO";
  static readonly cardTypes = "CARD_TYPES";
  static readonly payTypes = "PAY_TYPES";
  static readonly webMenu = "WEB_MENU";
  static readonly projects = "PROJECTS";
  static readonly employees = "EMPLOYEES";
  static readonly mainTabs = "MAIN_TABS";
  static readonly printer = "PRINTER";
  static readonly phone = "PHONE";
  static readonly securePay = "SECUREPAY";

  static provider?: StorageProvider;

  static getString(key: string): string {
    return this.provider?.getItem(key) ?? "";
  }
  static setString(key: string, value: string): void {
    console.log(`key:${key} \r\n ${value}`)
    this.provider?.setItem(key, value);
  }
  static remove(key: string): void {
    this.provider?.removeItem(key);
  }
  static getObject<T>(key: string): T | undefined {
    const stringValue = this.getString(key);
    if (stringValue) {
      return JSON.parse(stringValue) as T;
    } else {
      return undefined;
    }
  }
  static setObject<T>(key: string, value: T): void {
    this.setString(key, JSON.stringify(value));
  }

  static async loadCardTypes(): Promise<boolean> {
    const response = await OrderService.GetLisfOfCardTypes({});
    if (
      response &&
      response.status === 200 &&
      response.data &&
      response.data.items
    ) {
      const cardTypes = new Array<CardType>();
      for (const item of response.data.items) {
        const cardType = new CardType();
        cardType.id = item.id;
        cardType.name = item.name;
        cardType.discountType = item.discountType;
        cardType.amount = item.amount;
        cardTypes.push(cardType);
      }
      StorageManager.setObject(StorageManager.cardTypes, cardTypes);
    }
    return true;
  }
  static async loadPayTypes(): Promise<boolean> {
    const response = await OrderService.GetLisfOfPayTypes({});
    if (
      response &&
      response.status === 200 &&
      response.data &&
      response.data.items
    ) {
      const payTypes = new Array<PayType>();
      for (const item of response.data.items) {
        const payType = new PayType();
        payType.id = item.id ?? 0;
        payType.name = item.name;
        payType.type = item.type;
        payTypes.push(payType);
      }
      StorageManager.setObject(StorageManager.payTypes, payTypes);
    }
    return true;
  }
  static async loadProjects(): Promise<boolean> {
    const response = await OrderService.GetListOfItems({});
    if (
      response &&
      response.status === 200 &&
      response.data &&
      response.data.items
    ) {
      const list = response.data.items.map(
        (a) => new ProjectItem(a.id, a.name, a.price, a.vipPrice)
      );
      StorageManager.setObject(StorageManager.projects, list);
    }
    return true;
  }
  static async loadEmployees(): Promise<boolean> {
    const response = await OrderService.GetListOfEmployees({});
    if (
      response &&
      response.status === 200 &&
      response.data &&
      response.data.regular
    ) {
      const list = response.data.regular.map(
        (a) =>
          new Employee(a.userId, a.name, a.phone, a.sex, a.position, a.basePay)
      );
      StorageManager.setObject(StorageManager.employees, list);
    }
    return true;
  }
  static async loadSecurePay(): Promise<boolean> {
    const response = await OrderService.GetWeixinConfiguration({});
    if (
      response &&
      response.status === 200 &&
      response.data &&
      response.data.securePayEnabled
    ) {
      StorageManager.setObject(StorageManager.securePay, response.data.securePayEnabled);
    }
    return true;
  }
  static async loadPrinter(): Promise<boolean> {
    const response = await OrderService.GetPrinterConfiguration({});
    if (response && response.status === 200) {
      const printer: Printer = {
        addCard: response.data?.enableAddCard ?? false,
        payBill: response.data?.enablePayBill ?? false,
        topUp: response?.data?.enableTopUp ?? false,
        silent: response?.data?.enableSilent ?? false,
        papers: response?.data?.printPapers ?? 2,
      };
      StorageManager.setObject(StorageManager.printer, printer);
    }
    return true;
  }
  static async loadMenu(): Promise<boolean> {
    const response = await OrderService.GetEmployeeWebMenu({});
    if (response == undefined || response.status != 200) {
      return false;
    }
    if (response.data && response.data.items) {
      const menuItems = new Array<MenuItem>();
      for (const item of response.data.items) {
        const menuItem = this.buildMenuItem(item);
        if (menuItem) {
          menuItems.push(menuItem);
        }
      }
      StorageManager.setObject(StorageManager.webMenu, menuItems);
    }
    return true;
  }
  private static buildMenuItem(
    responseItem: GetEmployeeWebMenuResponseMenuItem
  ): MenuItem | undefined {
    const menuItem = new MenuItem();
    menuItem.id = responseItem.id;
    menuItem.name = responseItem.name;
    menuItem.icon = responseItem.icon;
    menuItem.indexPath = responseItem.indexPath;
    if (responseItem.route) {
      menuItem.route = new MenuRoute();
      menuItem.route.name = responseItem.route.name;
      menuItem.route.params = responseItem.route.params;
    }
    if (menuItem.route?.name) {
      if (!AppRouter.instance.hasRoute(menuItem.route.name)) {
        return undefined;
      }
    }
    if (responseItem.items) {
      menuItem.items = new Array<MenuItem>();
      for (const item of responseItem.items) {
        const child = this.buildMenuItem(item);
        if (child) {
          menuItem.items.push(child);
        }
      }
    }
    return menuItem;
  }
}
