
import {
  GetListOfEmployeePermissionsResponseItem,
  GetListOfEmployeesResponseItem,
} from "@/api/contracts/employee";
import OrderService from "@/api/OrderService";
import { TabVue } from "@/libs/TabVue";
import Validator from "@/libs/Validator";
import { ElMessageBox } from "element-plus";
import { Options } from "vue-class-component";
import ShopQrcodeDialog from "@/components/ShopQrcodeDialog.vue";

@Options({
  components: {
    ShopQrcodeDialog,
  },
})
export default class EmployeeConfigurationPage extends TabVue {
  loading = true;
  statusOptions = [
    {
      label: "正常在职",
      value: "Regular",
    },
    {
      label: "待审核",
      value: "Pending",
    },
    {
      label: "已驳回",
      value: "Rejected",
    },
    {
      label: "已离职",
      value: "Resigned",
    },
  ];
  selectedStatus = "Regular";
  tableData: EmployeeInfoTableRow<GetListOfEmployeesResponseItem>[] = [];
  regular: GetListOfEmployeesResponseItem[] = [];
  pending: GetListOfEmployeesResponseItem[] = [];
  rejected: GetListOfEmployeesResponseItem[] = [];
  resigned: GetListOfEmployeesResponseItem[] = [];
  editDialogVisible = false;
  infoFormData: EmployeeFormData<GetListOfEmployeesResponseItem> = {
    source: new GetListOfEmployeesResponseItem(),
  };
  infoSaving = false;
  permissionDialogVisible = false;
  permissionsFormData: PermissionsFormData<
    GetListOfEmployeesResponseItem,
    GetListOfEmployeePermissionsResponseItem
  > = {
    rows: [],
    tableRow: {
      source: new GetListOfEmployeesResponseItem(),
    },
  };
  permissionSaving = false;
  shopQrcodeDialogVisible = false;
  async refresh(): Promise<void> {
    this.loading = true;
    const response = await OrderService.GetListOfEmployees({});
    this.loading = false;
    if (!response || response.status != 200) {
      this.handleApiError(response);
      return;
    }
    if (response.data?.regular) {
      this.regular = response.data.regular;
    } else {
      this.regular = [];
    }
    if (response.data?.pending) {
      this.pending = response.data.pending;
    } else {
      this.pending = [];
    }
    if (response.data?.rejected) {
      this.rejected = response.data.rejected;
    } else {
      this.rejected = [];
    }
    if (response.data?.resigned) {
      this.resigned = response.data.resigned;
    } else {
      this.resigned = [];
    }
    this.loadTableData(this.selectedStatus);
  }
  loadTableData(status: string): void {
    let items: GetListOfEmployeesResponseItem[];
    if (status == "Regular") {
      items = this.regular;
    } else if (status == "Pending") {
      items = this.pending;
    } else if (status == "Rejected") {
      items = this.rejected;
    } else if (status == "Resigned") {
      items = this.resigned;
    } else {
      return;
    }
    this.tableData = [];
    items.forEach((item) => {
      this.tableData.push({
        source: item,
        name: item.name,
        gender: item.sex == "M" ? "男" : "女",
        phone: item.phone,
        status:
          status == "Regular"
            ? "正常在职"
            : status == "Pending"
            ? "待审核"
            : status == "Rejected"
            ? "已驳回"
            : status == "Resigned"
            ? "已离职"
            : "",
        position:
          status == "Regular"
            ? item.position == "Master"
              ? "大工"
              : item.position == "Slave"
              ? "小工"
              : "NA"
            : "NA",
        basePay:
          status == "Regular"
            ? (item.basePay ?? 0) > 0
              ? (item.basePay ?? 0).toString()
              : "NA"
            : "NA",
        showEditButton: item.showEditButton,
        showOnboardingButton: item.showOnboardingButton,
        showResignButton: item.showResignButton,
        showRejectButton: item.showRejectButton,
        showPermissionButton: item.showPermissionButton,
      });
    });
  }
  handleEdit(
    index: number,
    row: EmployeeInfoTableRow<GetListOfEmployeesResponseItem>
  ): void {
    console.log(index, row);
    this.infoFormData = {
      source: row.source,
      name: row.source.name,
      gender: row.source.sex,
      phone: row.source.phone,
      position: row.source.position,
      basePay: (row.source.basePay ?? 0).toString(),
    };
    this.editDialogVisible = true;
  }
  async handlePermission(
    index: number,
    row: EmployeeInfoTableRow<GetListOfEmployeesResponseItem>
  ): Promise<void> {
    console.log(index, row);
    const response = await OrderService.GetListOfEmployeePermissions({
      data: {
        userId: row.source.userId,
      },
    });
    if (!response || response.status != 200) {
      this.handleApiError(response);
      return;
    }
    this.permissionsFormData = {
      rows: [],
      tableRow: row,
    };
    response.data?.items?.filter(x => !x.locked)?.forEach((x) =>
      this.permissionsFormData.rows.push({
        source: x,
        label: x.label,
        enabled: x.owned,
      })
    );
    this.permissionDialogVisible = true;
  }
  handleOnboarding(
    _: number,
    row: EmployeeInfoTableRow<GetListOfEmployeesResponseItem>
  ): void {
    ElMessageBox.confirm("确认员工入职, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        this.loading = true;
        const response = await OrderService.OnboardingEmployee({
          data: {
            userId: row.source.userId ?? 0,
          },
        });
        this.loading = false;
        if (!response || response.status != 200) {
          this.handleApiError(response);
          return;
        }
        await this.refresh();
      })
      .catch(() => {});
  }
  handleResign(
    _: number,
    row: EmployeeInfoTableRow<GetListOfEmployeesResponseItem>
  ): void {
    ElMessageBox.confirm("确认员工离职, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        this.loading = true;
        const response = await OrderService.ResignEmployee({
          data: {
            userId: row.source.userId ?? 0,
          },
        });
        this.loading = false;
        if (!response || response.status != 200) {
          this.handleApiError(response);
          return;
        }
        await this.refresh();
      })
      .catch(() => {});
  }
  handleReject(
    _: number,
    row: EmployeeInfoTableRow<GetListOfEmployeesResponseItem>
  ): void {
    ElMessageBox.confirm("确认员工驳回, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        this.loading = true;
        const response = await OrderService.RejectEmployee({
          data: {
            userId: row.source.userId ?? 0,
          },
        });
        this.loading = false;
        if (!response || response.status != 200) {
          this.handleApiError(response);
          return;
        }
        await this.refresh();
      })
      .catch(() => {});
  }
  async saveInfo(): Promise<void> {
    if (!Validator.isNotBlankString(this.infoFormData.name)) {
      this.showErrorToast("员工姓名不能为空");
      return;
    }
    if (!Validator.isNonnegativeNumber(this.infoFormData.basePay)) {
      this.showErrorToast("工资必须输入数字(非负值)");
      return;
    }
    this.infoSaving = true;
    const response = await OrderService.UpdateEmployeeInfo({
      data: {
        userId: this.infoFormData.source.userId,
        position: this.infoFormData.position,
        basePay: Number(this.infoFormData.basePay),
        name: this.infoFormData.name,
        gender: this.infoFormData.gender,
      },
    });
    this.infoSaving = false;
    if (!response || response.status != 200) {
      this.handleApiError(response);
      return;
    }
    this.showSuccessToast("保存成功");
    this.editDialogVisible = false;
    await this.refresh();
  }
  async savePermissions(): Promise<void> {
    this.permissionSaving = true;
    const response = await OrderService.UpdateEmployeePermissions({
      data: {
        userId: this.permissionsFormData.tableRow.source.userId,
        codes: this.permissionsFormData.rows
          .filter((x) => x.enabled == true)
          .map((x) => x.source.code ?? ""),
      },
    });
    this.permissionSaving = false;
    if (!response || response.status != 200) {
      this.handleApiError(response);
      return;
    }
    this.showSuccessToast("更新权限成功");
    this.permissionDialogVisible = false;
  }
  statusChanged(status: string): void {
    console.log(status);
    this.loadTableData(status);
  }
}

interface EmployeeInfoTableRow<T> {
  source: T;
  name?: string;
  gender?: string;
  phone?: string;
  status?: string;
  position?: string;
  basePay?: string;
  showEditButton?: boolean;
  showOnboardingButton?: boolean;
  showResignButton?: boolean;
  showRejectButton?: boolean;
  showPermissionButton?: boolean;
}

interface EmployeeFormData<T> {
  source: T;
  name?: string;
  gender?: string;
  phone?: string;
  position?: string;
  basePay?: string;
}

interface PermissionsFormData<ET, PT> {
  rows: PermissionRow<PT>[];
  tableRow: EmployeeInfoTableRow<ET>;
}

interface PermissionRow<T> {
  source: T;
  label?: string;
  enabled?: boolean;
}
