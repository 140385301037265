import axios, { AxiosResponse } from "axios";
import { RequestBase, ResponseBase } from "@/api/contracts/base";
import Debug from "./Debug";

export default class ApiClient {
  private readonly baseUrl: string;
  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  static defaultBaseUrl = "";
  static beforeRequest?: <TRequest extends RequestBase>(
    client: ApiClient,
    request: TRequest
  ) => boolean;
  static afterResponse?: <
    TRequest extends RequestBase,
    TResponse extends ResponseBase
  >(
    client: ApiClient,
    request: TRequest,
    response: TResponse
  ) => boolean;

  post<TRequest extends RequestBase, TResponse extends ResponseBase>(
    url: string,
    request: TRequest,
    callback: (response: TResponse | undefined) => void
  ): void {
    if (ApiClient.beforeRequest && !ApiClient.beforeRequest(this, request)) {
      return;
    }
    const vm = this;
    axios
      .post(
        this.baseUrl.length > 0
          ? this.baseUrl + url
          : ApiClient.defaultBaseUrl.length > 0
          ? ApiClient.defaultBaseUrl + url
          : url,
        request,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function(response: AxiosResponse<TResponse>) {
        if (response.status === 200) {
          if (
            ApiClient.afterResponse &&
            !ApiClient.afterResponse(vm, request, response)
          ) {
            return;
          }
          callback(response.data);
        } else {
          console.log("http post failed: " + response.statusText);
          callback(undefined);
        }
      });
  }

  async postAsync<TRequest extends RequestBase, TResponse extends ResponseBase>(
    url: string,
    request: TRequest
  ): Promise<TResponse | undefined> {
    if (ApiClient.beforeRequest && !ApiClient.beforeRequest(this, request)) {
      return;
    }
    Debug.info("api started: " + url);
    try {
      const response: AxiosResponse<TResponse> = await axios.post(
        this.baseUrl.length > 0
          ? this.baseUrl + url
          : ApiClient.defaultBaseUrl.length > 0
          ? ApiClient.defaultBaseUrl + url
          : url,
        request,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      Debug.info("api ended: " + url);
      if (response.status === 200) {
        if (
          ApiClient.afterResponse &&
          !ApiClient.afterResponse(this, request, response)
        ) {
          return;
        }
        return response.data;
      } else {
        Debug.error("http post failed: " + response.statusText);
        return undefined;
      }
    }
    catch (e: any) {
      Debug.error(e)
      return undefined
    }
  }
}
