export default abstract class Timer {
  constructor(ms: number) {
    this.ms = ms;
  }
  id?: unknown;
  ms: number;
  start(): void {
    if (!this.id) {
      this.stop();
    }
    this.id = setInterval(() => {
      this.elapse();
    }, this.ms);
  }
  stop(): void {
    if (this.id) {
      clearInterval(this.id as number);
      this.id = undefined;
    }
  }
  abstract elapse(): void;
}
