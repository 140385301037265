import { RequestBase, ResponseBase } from "./base";

export class GetSubscriptionPackagesRequestData {
  shopId?: number;
}

export class GetSubscriptionPackagesRequest extends RequestBase {
  data?: GetSubscriptionPackagesRequestData;
}

export class GetSubscriptionPackagesResponsePackage {
  packageId?: number;
  title?: string;
  platforms?: string[];
  months?: number;
  price?: number;
  period?: string;
  remark?: string;
}

export class GetSubscriptionPackagesResponseUpgrade {
  upgradeable?: boolean;
  editionCode?: string;
  editionName?: string;
}

export class GetSubscriptionPackagesResponseData {
  packages?: GetSubscriptionPackagesResponsePackage[];
  upgrade?: GetSubscriptionPackagesResponseUpgrade;
}

export class GetSubscriptionPackagesResponse extends ResponseBase {
  data?: GetSubscriptionPackagesResponseData;
}

export class QuerySubscriptionInfoRequest extends RequestBase {}

export class QuerySubscriptionInfoResponseSubscriptionInfo {
  status?: string;
  prompt?: string;
  expireTime?: string;
}

export class QuerySubscriptionInfoResponseData {
  app?: QuerySubscriptionInfoResponseSubscriptionInfo;
  pc?: QuerySubscriptionInfoResponseSubscriptionInfo;
  editionName?:string;

}

export class QuerySubscriptionInfoResponse extends ResponseBase {
  data?: QuerySubscriptionInfoResponseData;
}

export class QuerySubscriptionUpgradeRequest extends RequestBase {}

export class QuerySubscriptionUpgradeResponseData {
  amount?: number;
  remark?: number;
  editionCode?: string;
  editionName?: string;
  transactionId?: string;
  link?: string;
  validSeconds?: number;
}

export class QuerySubscriptionUpgradeResponse extends ResponseBase {
  data?: QuerySubscriptionUpgradeResponseData;
}
