
import { AddVipCardRequest, AddVipCardRequestData, GetSystemBillNoRequest,TopUpVipCardRequest,TopUpVipCardRequestData } from "@/api/contracts/vip";
import OrderService from "@/api/OrderService";
import { CardType,ProjectItem,Employee, PayType, Printer} from "@/data/storage";
import { BaseVue } from "@/libs/BaseVue";
import StorageManager from "@/libs/StorageManager";
import { Options } from "vue-class-component";
import { ElMessage } from 'element-plus';
import { CalculateBonusRequest,CalculateBonusRequestData } from "@/api/contracts/bonus";
import BillPrintDialog from "@/components/BillPrintDialog.vue"
@Options({
  components: {
    BillPrintDialog,
  }
})
export default class AddVipCardPage extends BaseVue {
  formData: FormData = {
    userName: "",
    sex: "F",
    cardType:undefined,
    phone: "",
    remark:"",
    birthDay:"",
    cardNo:"",
    chargeType:1,
    projects:[],
    activeTab:'cz',
    tclist:[],

    systemBillNo:'',
    manualBillNo:'',
    inputMoney:'',
    payId:2

  }
  formRules = {
    userName: [{ required: true, message: "请输入会员姓名", trigger: "blur" }],
    phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
    cardType: [{ required: true, message: "请选择卡种", trigger: "blur" }],
  }
  projectOptions:ProjectOption [] =[ ];
  employeeOptions:EmployeeOption [] =[];
  cardTypeOptions: CardTypeOption[] = [];
  payTypeOptions:PayTypeOption[] = [];
  errors: string[] = [];
  billPrintId = 0;

 
  
   async init(): Promise<void> {
     await this.getBillNo()
    const cardTypes: CardType[] | undefined = StorageManager.getObject(
      StorageManager.cardTypes
    );
     const projects: ProjectItem[] | undefined = StorageManager.getObject(
      StorageManager.projects
    );
     const employees: Employee[] | undefined = StorageManager.getObject(
      StorageManager.employees
    );
     const payTypes: PayType[] | undefined = StorageManager.getObject(
      StorageManager.payTypes
    );
    if (cardTypes) {
      for (const cardType of cardTypes) {
        this.cardTypeOptions.push({
          label: cardType.name ?? "",
          value: cardType.id,
        });
      }
    }
    if(employees){
      this.employeeOptions  = employees.map(a=> { 
         return {label:a.name,value:a.userId,position:a.position}
      })
    }
    if(projects){
      this.projectOptions  = projects.map(a=> { 
         return {label:a.name,value:a.id,price:a.price}
      })
    }
    if(payTypes){ 
      this.payTypeOptions=payTypes.filter(a=>a.type==='CASH').map(a=>{
        return { label:a.name?a.name:'',value:a.id }
      })
    }
  }

   async created(): Promise<void> {
    await this.init()
  }

   async activated(): Promise<void> {
    await this.init()
  }

  async mounted():Promise<void>{
    this.addStaff()
  }
   changeChargeType(val:number){
      if(val===2 && this.formData.projects.length===0){
        this.addProject()
      }
      if(val===1){
        this.formData.projects.splice(0,this.formData.projects.length)
      }
   }
  async getBillNo():Promise<void>{
     const response = await OrderService.GetSystemBillNo(new GetSystemBillNoRequest());
    
    if (response && response.status === 200) {
       if(response.data?.systemBillNo){
           this.formData.systemBillNo= response.data?.systemBillNo
       }
    } else {
      this.handleApiError(response);
    }
  }

  async save(): Promise<void> {
    let formRef:any=this.$refs.baseForm
    formRef.validate((valid:boolean) => {
          if (valid) {
            this.saveAddMember()
          }
        }) 
  }

  async saveAddMember(){
   if (!new RegExp("^\\d{11}$").test(this.formData.phone)) {
      ElMessage.error('手机号格式无效，请重新输入');
      return
     }
      if (this.formData.cardNo && !/^\w*$/.test(this.formData.cardNo)) {
      ElMessage.error('卡号格式无效，只能是英文字母或数字！');
      return
     }
     
    let reg =/(^[0-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{2}$)/;
    if(this.formData.chargeType===1){
      
       if(!this.formData.inputMoney){
        ElMessage.error('请填写充值金额！');
        return
       }

     
  
      if(!reg.test(this.formData.inputMoney)){
          ElMessage.error('充值金额填写不正确，只能为大于等于0的数字，请检查！');
          return
      }
     
     }

    

      if(this.formData.chargeType===2 ){

        let validProjects= this.formData.projects.filter(a=>a.itemId)
        if(validProjects.length===0){
           ElMessage.error('请填写次数项目！');
           return
        }


        let projectIds=validProjects.map(a=>a.itemId)
        let s=new Set(projectIds)
        if(s.size<projectIds.length ){
            ElMessage.error('充值项目：有重复的项目，请检查！');
            return
        }

        let badPrice=validProjects.filter(a=> !reg.test(a.realPrice.toString()) )
        if(badPrice.length ){
            ElMessage.error('充值项目：项目实收价填写不正确，请检查！');
            return
        }
        var  regExp= /^[1-9]\d*$/;//大于0的整数
        let badCount=validProjects.filter(a=> !regExp.test(a.count.toString()))
         if(badCount.length ){
            ElMessage.error('充值项目：项目数量填写不正确，必须为大于0的整数，请检查！');
            return
        }
     
       
     }
     

     let validTc=this.formData.tclist.filter(a=>a.userId)
    if(validTc.length>0){
        let users = validTc.map(a=>{
          return a.userId
        })
        let s=new Set(users)
        if(s.size<users.length ){
            ElMessage.error('员工提成：不能有重复员工，请检查！');
            return
        }
     
        let moneys = validTc.map(a=>{
          return a.money
        })
        let bads = moneys.filter(a=> isNaN(a) || Number(a)===0 )
        if(bads.length){
            ElMessage.error('员工提成：提成金额必须大于0，请检查！');
            return
        }
    }


    this.showLoading()
    const request = new AddVipCardRequest();
    request.data = new AddVipCardRequestData();
    request.data.name = this.formData.userName;
    request.data.phone = this.formData.phone;
    request.data.gender = this.formData.sex;
    request.data.cardTypeId = this.formData.cardType ?? 0;
    request.data.birthday=this.formData.birthDay;
    request.data.cardNo=this.formData.cardNo;
    const response = await OrderService.AddVipCard(request);
    if (response && response.status === 200) {
      let vipId=response.data?.vipId
      if(vipId &&  ( Number(this.formData.inputMoney)>0 || this.formData.projects.length) ) {
        await this.saveCharge(vipId)
      }else{
        this.hideLoading()
        // this.showSuccessToast("办卡成功！");
        this.showAlert("办卡成功！")
        this.clear();
      }
    } else {
      this.hideLoading()
      this.handleApiError(response);
    }
  }
  async saveCharge(vipId:number){

    const request = new TopUpVipCardRequest();
    request.data = new TopUpVipCardRequestData();
    request.data.category =this.formData.chargeType===1? 'TOP_UP_AMOUNT':'TOP_UP_COUNT';
    
    request.data.vipId = vipId;
    request.data.payId = this.formData.payId;
    request.data.systemBillNo = this.formData.systemBillNo;
    request.data.manualBillNo = this.formData.manualBillNo;
    request.data.discountAmount = 0;
    request.data.remark = this.formData.remark;

    if(this.formData.chargeType===1){
       //充值金额
       request.data.topUpAmount = this.formData.inputMoney;
        request.data.topUpItems=[];
    }else{
      //充值次数
       var timesList = this.formData.projects.map(a => {
          return { itemId: a.itemId, count: a.count,price: a.realPrice }
        })

      request.data.topUpAmount = "0";
      request.data.topUpItems=timesList;

    }
    let validTc=this.formData.tclist.filter(a=>a.userId)
    if(validTc.length>0){
        request.data.employeeBonuses=validTc.map(a=>{
         return {
          source:0,
          amount:a.money,
          userId:a.userId
        }
        })
    }
    
    const response = await OrderService.TopUpVipCard(request);
    this.hideLoading()
    if (response && response.status === 200) {
      // this.showSuccessToast("办卡成功！");
      this.showAlert("办卡成功！")
      this.clear();
      const printer: Printer | undefined = StorageManager.getObject(StorageManager.printer)
      if (printer && printer.addCard) {
        this.billPrintId = response?.data?.billId ?? 0
      }
    } else {
      this.handleApiError(response);
    }
  }
  clear(): void {
    this.formData = {
      userName: "",
      sex: "F",
      phone: "",
      cardType:undefined,
      cardNo:"",
      remark:"",
      birthDay:"",
      projects:[],
      chargeType:1,
      activeTab:"cz",
      tclist:[],
      systemBillNo:'',
      manualBillNo:'',
      inputMoney:'',
      payId:2
      
    };
    this.errors = [];

    this.getBillNo()
    this.addStaff()
  }

  addProject():void{
    this.formData.projects.push({
    // itemId:null,
    itemName:'',
    realPrice:0,
    count:1,
    price:0
    })
  }
  removeProject(index:number):void{
    this.formData.projects.splice(index,1)
  }
  addStaff():void{
    this.formData.tclist.push({
       userId:null,
       userName:'',
       money:''
    })
  }
  removeStaff(index:number):void{
    this.formData.tclist.splice(index,1)
  }

  onProjectSelect(val:number,row:Project,index:number){
     //  edit row 
     // val 选中项目ID
     //row 编辑的那行
     // index 编辑行的序号
     let project = this.formData.projects[index] //
     let selected =this.projectOptions.find(a=>a.value===val)
     if(!selected){
       return
     }
     project.realPrice= selected.price
     project.price= selected.price
  }
   async onStaffSelect(val:number,row:any,index:number){
     //  edit row 
     let tc = this.formData.tclist[index] //
     let selected =this.employeeOptions.find(a=>a.value===val)
     if(!selected){
       return
     }
     
    let bonus= await this.calculateBonus(val)

     tc.money=  bonus
      
  }

    async calculateBonus(userId:number):Promise<any>{

     let request=new CalculateBonusRequest()
     request.data=new CalculateBonusRequestData()
     
     let amount=''
     if(this.formData.chargeType===1){
       amount=this.formData.inputMoney
     }else{

      let sum = 0
      this.formData.projects.forEach(a=>{
        sum= Number(sum)  + Number(a.realPrice * a.count) 
      }) 
      amount=sum.toString()

     }
     request.data.topUp=[{
        type:2,
        amount:amount
      }]
     request.data.employees=[{userId:userId}]
     
     const response = await OrderService.CalculateBonus(request);
    
    if (response && response.status === 200) {
       if(response.data?.employees){
          let bonus=  response.data?.employees[0].bonus
          return bonus
       }
    } else {
      this.handleApiError(response);
    }
  }
}

interface CardTypeOption {
  label: string;
  value: number;
}


interface PayTypeOption {
  label: string;
  value: number;
}


interface ProjectOption {
  label: string;
  value: number;
  price:number;
}

interface EmployeeOption {
  label: string;
  value: number;
  position:string;
}


interface Project{
    itemId?:number;
    itemName:string;
    realPrice:number;
    price:number;
    count:number;

}

interface FormData {
  userName: string;
  sex: string;
  phone: string;
  cardType?: number;
  remark:string;
  cardNo:string;
  birthDay:string;
  chargeType:number;
  projects:Array<Project>;
  activeTab:string;
  tclist:Array<any>;
  systemBillNo:string;
  manualBillNo:string;
  inputMoney:string;
  payId:number;
}
