
import OrderService from "@/api/OrderService";
import { TabVue } from "@/libs/TabVue";
import Validator from "@/libs/Validator";
import { Options } from "vue-class-component";
import QRCodeVue3 from "qrcode-vue3";
import Timer from "@/libs/Timer";
import { GetPageOfBalanceLogsResponseItem } from "@/api/contracts/wallet";
import { DateTime } from "@/libs/DateTime";

@Options({
  components: {
    QRCodeVue3,
  },
})
export default class AccountBalancePage extends TabVue {
  loading = true;
  balance = "";
  topUpAmount = "";
  qrcode = "";
  dialogVisible = false;
  gettingLink = false;
  transactionId?: string;
  linkStatusChecker?: LinkStatusChecker;
  dateRange: string[] = [];
  pageNumber = 1;
  pageSize = 10;
  totalCount = 0;
  tableData: BalanceLogTableRow<GetPageOfBalanceLogsResponseItem>[] = [];
  queryingLogs = false;
  async refresh(): Promise<void> {
    await this.loadData();
  }
  beforeUnmount(): void {
    this.linkStatusChecker?.stop();
  }
  async loadData(): Promise<void> {
    this.dateRange.push(
      DateTime.now()
        .addDays(-7)
        .toString("yyyy-MM-dd")
    );
    this.dateRange.push(DateTime.now().toString("yyyy-MM-dd"));
    this.loading = true;
    const response = await OrderService.GetBalance({});
    this.loading = false;
    if (response && response.status === 200) {
      if (response.data) {
        this.balance = response.data.balance?.toString() ?? "0";
      }
    } else {
      this.handleApiError(response);
    }
  }
  async getLink(): Promise<void> {
    if (!Validator.isPositiveNumber(this.topUpAmount)) {
      this.showErrorToast("充值金额必须大于0");
      return;
    }
    this.gettingLink = true;
    const response = await OrderService.GetLinkOfTopUpBalance({
      data: {
        amount: Number(this.topUpAmount),
      },
    });
    this.gettingLink = false;
    if (!response || response.status != 200) {
      this.handleApiError(response);
      return;
    }
    this.dialogVisible = true;
    this.qrcode = response.data?.link ?? "";
    this.transactionId = response.data?.transactionId;
    this.linkStatusChecker = new LinkStatusChecker(
      response.data?.validSeconds ?? 60,
      async (secondsLeft) => {
        if (secondsLeft < 0) {
          this.showSuccessToast("支付链接已过期，请重新点击充值");
          this.dialogVisible = false;
          return;
        }
        if (this.transactionId) {
          const response = await OrderService.GetInfoByTransactionId({
            data: {
              transactionId: this.transactionId,
            },
          });
          if (
            response &&
            response.status == 200 &&
            response.data?.paid == true
          ) {
            this.showSuccessToast("充值成功");
            this.dialogVisible = false;
            await this.loadData();
          }
        }
      }
    );
  }
  dialogClose(): void {
    this.linkStatusChecker?.stop();
  }
  async handleCurrentPageChange(_: number): Promise<void> {
    await this.queryBalanceLogs();
  }
  async queryBalanceLogs(): Promise<void> {
    if (!this.dateRange) {
      this.showErrorToast("请先选择查询起始和结束日期");
      return;
    }
    const startDate = this.dateRange[0];
    const endDate = this.dateRange[1];
    if (!startDate || !endDate) {
      this.showErrorToast("请先选择查询起始和结束日期");
      return;
    }
    this.tableData = [];
    this.queryingLogs = true;
    const response = await OrderService.GetPageOfBalanceLogs({
      data: {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        startDate: startDate,
        endDate: endDate,
      },
    });
    this.queryingLogs = false;
    if (response && response.status === 200) {
      this.totalCount = response.data?.totalCount ?? 0;
      response.data?.items?.forEach((x) =>
        this.tableData.push({
          source: x,
          time: x.time,
          name: x.name,
          amount: (x.amount ?? 0).toString(),
          remark: x.description,
        })
      );
    } else {
      this.handleApiError(response);
    }
  }
}

class LinkStatusChecker extends Timer {
  constructor(
    secondsLeft: number,
    backgroundTask: (secondsLeft: number) => Promise<void>
  ) {
    super(3000);
    this.secondsLeft = secondsLeft;
    this.backgroundTask = backgroundTask;
    this.start();
  }
  private secondsLeft = -1;
  private backgroundTask: (secondsLeft: number) => Promise<void>;
  elapse(): void {
    if (this.secondsLeft <= 0) {
      this.stop();
    }
    this.secondsLeft -= 3;
    this.backgroundTask(this.secondsLeft);
  }
}

interface BalanceLogTableRow<T> {
  source: T;
  time?: string;
  name?: string;
  amount?: string;
  remark?: string;
}
