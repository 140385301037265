
import OrderService from "@/api/OrderService";
import { BaseVue } from "@/libs/BaseVue";
import { Options } from "vue-class-component";
import StorageManager from "@/libs/StorageManager";
import { CardType } from "@/data/storage";
  import { ElMessageBox,ElMessage } from 'element-plus';

import { DeleteVipCardRequest, DeleteVipCardRequestData, GetPageOfVipRequest ,GetPageOfVipRequestData,UpdateVipInfoRequest,UpdateVipInfoRequestData} from "@/api/contracts/vip";


@Options({})
export default class VipListPage extends BaseVue {
  formData: FormData = {
    name: "",
    gender: "F",
    cardTypeId:0,
    phone: "",
    remark:"",
    birthDay:"",
    cardNo:"",
    vipId:0

  };
  queryText=""

  pageNumber = 1;
  pageSize = 10;
  totalCount = 0;

  tableData:Vip []=[];
  loading = false;
  dialogFormVisible=false
  cardTypeOptions: CardTypeOption[] = [];
  formRules = {
    name: [{ required: true, message: "请输入会员姓名", trigger: "blur" }],
    phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
    cardTypeId: [{ required: true, message: "请选择卡种", trigger: "blur" }],
  }
  async created(): Promise<void> {

     const cardTypes: CardType[] | undefined = StorageManager.getObject(
      StorageManager.cardTypes
    );
     if (cardTypes) {
      for (const cardType of cardTypes) {
        this.cardTypeOptions.push({
          label: cardType.name ?? "",
          value: cardType.id,
        });
      }
    }

     await this.query()

     
  }

  getCardTypeName(id:number){
    let node = this.cardTypeOptions.find(a=>a.value===id)
    return node?.label
  }

  editUser(user:Vip){
      this.dialogFormVisible=true
      this.formData.name=user.name;
      this.formData.gender=user.gender;
      this.formData.phone=user.phone;
      this.formData.cardTypeId=user.cardTypeId;
      this.formData.vipId=user.vipId;

      this.formData.birthDay=user.birthday;
      this.formData.cardNo=user.cardNo;
  }

  deleteUser(user:Vip){
      ElMessageBox.confirm(`您正在删除会员：${user.name}, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
            this.saveDelete(user)
        }).catch(() => {
        });
  }

  async saveDelete(user:Vip){
     let request= new DeleteVipCardRequest()
    request.data=new DeleteVipCardRequestData()
    request.data.vipId=user.vipId;
 
     let response=await OrderService.DeleteVipCard(request)
    if (response && response.status === 200) {
      ElMessage.success(`删除${user.name}成功！`);
        this.query()
    } else {
      this.handleApiError(response);
    }
  }

  async saveUpdateInfo(){
      if (!new RegExp("^\\d{11}$").test(this.formData.phone)) {
      ElMessage.error('手机号格式无效，请重新输入');
      return
     }
       let formRef:any=this.$refs.baseForm
       formRef.validate((valid:boolean) => {
          if (valid) {
            this.saveUpdate()
          }
        }) 

   }
   async saveUpdate(){ 
    let request= new UpdateVipInfoRequest()
    request.data=new UpdateVipInfoRequestData()
    request.data.vipId=this.formData.vipId;

    request.data.name=this.formData.name;
    request.data.phone=this.formData.phone;
    request.data.gender=this.formData.gender;
    request.data.cardTypeId=this.formData.cardTypeId;
    request.data.birthday=this.formData.birthDay;
    request.data.cardNo=this.formData.cardNo;

     let response=await OrderService.UpdateVipInfo(request)
    if (response && response.status === 200) {
       this.dialogFormVisible = false
       this.query()
    } else {
      this.handleApiError(response);
    }

   }
  async query(): Promise<void> {
   
    this.tableData = [];
    this.loading = true;
    let request=new GetPageOfVipRequest()
    request.data=new GetPageOfVipRequestData()
    request.data.keyword=this.queryText
    request.data.pageSize=10
    request.data.pageNumber = this.pageNumber
    const response = await OrderService.GetPageOfVip(request);
    this.loading = false;
    if (response && response.status === 200) { 
       this.totalCount = response.data?.totalCount ?? 0;
      if(!response.data){
        return
      }
      this.tableData=response.data.items.map(a=>{
        return {
          balance: a.balance,
          cardTypeId:a.cardTypeId,
          gender: a.gender,
          name: a.name,
          phone: a.phone,
          vipId: a.vipId,
          birthday:a.birthday,
          cardNo:a.cardNo
        }
      })
    } else {
      this.handleApiError(response);
    }
  }

  async handleCurrentPageChange(_: number): Promise<void> {
    await this.query();
  }


  
 
} 


 interface Vip{
  vipId:number
  name:string;
  gender:string;
  cardTypeId:number;
  phone:string;
  balance:number;
  cardNo:string;
  birthday:string;
   
}

interface CardTypeOption {
  label: string;
  value: number;
}

interface FormData {
  name: string;
  gender: string;
  phone: string;
  cardTypeId: number;
  remark:string;
  cardNo:string;
  birthDay:string;
  vipId:number;
}
