
import { GetPageOfPayBillOrdersResponseItem } from "@/api/contracts/item";

import OrderService from "@/api/OrderService";
import { Options } from "vue-class-component";
import { ElMessageBox } from "element-plus";
import { BillTypeConstants } from "@/data/constants";
import { PayTypeConfiguration } from "@/data/configurations";
import BillDetailDialog from "@/components/BillDetailDialog.vue";
import BillPrintDialog from "@/components/BillPrintDialog.vue";
import { TabVue } from "@/libs/TabVue";

@Options({
  components: {
    BillDetailDialog,
    BillPrintDialog,
  },
})
export default class QueryPayBillPage extends TabVue {
  queryFilter = "";
  pageNumber = 1;
  pageSize = 10;
  totalCount = 0;
  tableData: BillTableRow<GetPageOfPayBillOrdersResponseItem>[] = [];
  loading = false;
  billDetailId = 0;
  billPrintId = 0;
  async refresh(): Promise<void> {
    this.queryFilter = "";
    this.pageNumber = 1;
    this.query();
  }
  openDetail(row: BillTableRow<GetPageOfPayBillOrdersResponseItem>): void {
    this.billDetailId = row.source.billId;
  }
  print(row: BillTableRow<GetPageOfPayBillOrdersResponseItem>): void {
    this.billPrintId = row.source.billId;
  }
  async deleteItem(row: BillTableRow<GetPageOfPayBillOrdersResponseItem>) {
    const message =
      row.source.billType === 0
        ? `您正在删除订单，是否继续?`
        : `您正在删除订单，删除后，扣除客户的金额将回退，是否继续?`;
    ElMessageBox.confirm(message, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        this.saveDelete(row);
      })
      .catch(() => {});
  }

  async saveDelete(row: BillTableRow<GetPageOfPayBillOrdersResponseItem>) {
    const response = await OrderService.VoidPayBill({
      data: {
        billId: row.source.billId,
      },
    });
    if (response && response.status === 200) {
      this.query();
    } else {
      this.handleApiError(response);
    }
  }
  async query(): Promise<void> {
    this.tableData = [];
    this.loading = true;
    const response = await OrderService.GetPageOfPayBillOrders({
      data: {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        keyword: this.queryFilter,
      },
    });
    this.loading = false;
    if (response && response.status === 200) {
      this.totalCount = response.data?.totalCount ?? 0;
      response.data?.items?.forEach((x) => {
        this.tableData.push({
          source: x,
          time: x.time,
          billTypeLabel: BillTypeConstants.valueOf(x.billType),
          phone: x.phone,
          name: x.vipName,
          payTypeLabel: PayTypeConfiguration.nameOf(x.payId),
          payAmount: x.payAmount.toString(),
        });
      });
    } else {
      this.handleApiError(response);
    }
  }
  async handleCurrentPageChange(_: number): Promise<void> {
    await this.query();
  }
}

interface BillTableRow<T> {
  source: T;
  time?: string;
  billTypeLabel?: string;
  phone?: string;
  name?: string;
  payTypeLabel?: string;
  payAmount?: string;
}
