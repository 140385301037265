
import OrderService from "@/api/OrderService";
import { BaseVue } from "@/libs/BaseVue";
import { Options } from "vue-class-component";

import {
  SecurePayRequest,
  SecurePayRequestData
} from "@/api/contracts/securePay";
@Options({
  emits: {
    close: () => true,
  },
  props: {
    vipId: {
      type: Number,
      default: 0,
    },
  },
})
export default class SecureEnsureDialog extends BaseVue {

  visible = false;
  verifyCode="";
  vipId = 0;
  created(): void {
   
  }

  openDialog():void{
    this.visible = true
  }

  closeButtonClicked(): void {
    this.visible=false
    this.$emit("close");
  }
  okButtonClicked(): void {
    this.visible=false
    this.$emit("ok",this.verifyCode);
  }
   
  count = 120 
  sendCodeClick():void{
    const request = new SecurePayRequest()
    request.data= new SecurePayRequestData()
    request.data.vipId = this.vipId
    OrderService.GetSecurePayCode(request)
     const clock = setInterval(()=>{
       this.count--
       if(this.count===0){
           clearInterval(clock)
           this.count=120
       }
     },1000)
  }
}
