import { DateTime } from "./DateTime";

export default class Debug {
  static info(text: string): void {
    console.log(DateTime.now().toString("yyyy-MM-dd HH:mm:ss.fff") + "|INFO |" + text);
  }
  static error(text: string, error?: unknown): void {
    let info = DateTime.now().toString("yyyy-MM-dd HH:mm:ss.fff") + "|ERROR|" + text
    if (error) {
      info += "\n" + error
    }
    console.log(info);
  }
}