import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"float":"left"} }
const _hoisted_2 = { style: {"float":"right","color":"#8492a6","font-size":"13px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createBlock(_component_el_select, {
    modelValue: _ctx.selectedVipId,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedVipId) = $event)),
    filterable: "",
    remote: "",
    "reserve-keyword": "",
    placeholder: "手机号、姓名、卡号",
    "remote-method": _ctx.search,
    onChange: _ctx.onVipSelected,
    onClear: _ctx.onVipCleared,
    clearable: "",
    loading: _ctx.searching
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.vipSelectionItems, (item) => {
        return (_openBlock(), _createBlock(_component_el_option, {
          key: item.id,
          label: item.name,
          value: item.id
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_1, _toDisplayString(item.name), 1),
            _createElementVNode("span", _hoisted_2, _toDisplayString(item.phone), 1)
          ]),
          _: 2
        }, 1032, ["label", "value"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["modelValue", "remote-method", "onChange", "onClear", "loading"]))
}