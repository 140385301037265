
import OrderService from "@/api/OrderService";
import { TabVue } from "@/libs/TabVue";
import { ElMessageBox } from "element-plus";
import { Options } from "vue-class-component";

@Options({})
export default class AddShopConfigurationPage extends TabVue {
  loading = false;
  saving = false;
  disabledCreateCompany = false;
  formData: CreateShopFormData = {
    belong: "CURRENT",
    name: "",
  };
  async refresh(): Promise<void> {
    this.loading = true;
    const response = await OrderService.CheckIfCreatedCompany({});
    this.loading = false;
    if (!response || response.status != 200) {
      this.handleApiError(response);
      return;
    }
    this.disabledCreateCompany = response.data?.companyCreated ?? false;
  }
  save(): void {
    ElMessageBox.confirm("确认要创建新店铺, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        this.saving = true;
        if (this.formData.belong == "CURRENT") {
          const response = await OrderService.CreateShop({
            data: {
              shopName: this.formData.name,
            },
          });
          if (!response || response.status != 200) {
            this.handleApiError(response);
            return;
          }
        } else if (this.formData.belong == "NEW") {
          const response = await OrderService.CreateCompany({
            data: {
              companyName: this.formData.name,
              shopName: this.formData.name,
            },
          });
          if (!response || response.status != 200) {
            this.handleApiError(response);
            return;
          }
        }
        this.saving = false;
        this.showSuccessToast("创建店铺成功");
        this.formData = {
          belong: "CURRENT",
          name: "",
        };
        this.changeStore();
      })
      .catch(() => {});
  }
}

interface CreateShopFormData {
  belong?: string;
  name?: string;
}
