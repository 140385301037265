import { RequestBase, ResponseBase } from "./base";

export class GetListOfShopRequest extends RequestBase {}

export class GetListOfShopResponseSubscription {
  status?: string;
  prompt?: string;
}

export class GetListOfShopResponseShop {
  shopId = 0;
  companyId = 0;
  name?: string;
  mobilePhone1?: string;
  mobilePhone2?: string;
  address?: string;
  wechat?: string;
  joinPassword?: string;
  subscription?: GetListOfShopResponseSubscription;
}

export class GetListOfShopResponseData {
  shops?: GetListOfShopResponseShop[];
}

export class GetListOfShopResponse extends ResponseBase {
  data?: GetListOfShopResponseData;
}

export class GetListOfShopsV2Request extends RequestBase {}

export class GetListOfShopsV2ResponseSubscription {
  status?: string;
  prompt?: string;
}

export class GetListOfShopsV2ResponseShop {
  shopId = 0;
  shopNo?: string;
  name?: string;
  mobilePhone1?: string;
  mobilePhone2?: string;
  address?: string;
  wechat?: string;
  joinPassword?: string;
}

export class GetListOfShopsV2ResponseCompany {
  companyId?: number;
  name?: string;
  subscription?: GetListOfShopsV2ResponseSubscription;
  shops?: GetListOfShopsV2ResponseShop[];
}

export class GetListOfShopsV2ResponseData {
  companies?: GetListOfShopsV2ResponseCompany[];
}

export class GetListOfShopsV2Response extends ResponseBase {
  data?: GetListOfShopsV2ResponseData;
}

export class GetUserInfoRequest extends RequestBase {}

export class GetUserInfoResponseData {
  userId = 0;
  name?: string;
  phone?: string;
  gender?: string;
  hasPassword?: boolean;
}

export class GetUserInfoResponse extends ResponseBase {
  data?: GetUserInfoResponseData;
}

export class UpdateUserInfoRequestData {
  name?: string;
  gender?: string;
}

export class UpdateUserInfoRequest extends RequestBase {
  data?: UpdateUserInfoRequestData;
}

export class UpdateUserInfoResponse extends ResponseBase {}
