
import OrderService from "@/api/OrderService";
import { Printer } from "@/data/storage";
import StorageManager from "@/libs/StorageManager";
import { TabVue } from "@/libs/TabVue";
import { Options } from "vue-class-component";

@Options({})
export default class PrinterConfigurationPage extends TabVue {
  loading = true;
  formData: PrinterFormData = {
    enableAddCard: false,
    enablePayBill: false,
    enableTopUp: false,
    printMode: "SILENT",
    printPapers: 1,
  };
  enableAddCardLoading = false;
  enablePayBillLoading = false;
  enableTopUpLoading = false;
  async refresh(): Promise<void> {
    this.loading = true;
    const response = await OrderService.GetPrinterConfiguration({});
    this.loading = false;
    if (response && response.status === 200) {
      if (response.data) {
        this.formData.enableAddCard = response.data.enableAddCard ?? false;
        this.formData.enablePayBill = response.data.enablePayBill ?? false;
        this.formData.enableTopUp = response.data.enableTopUp ?? false;
        this.formData.printMode = response.data.enableSilent
          ? "SILENT"
          : "PREVIEW";
      }
    } else {
      this.handleApiError(response);
    }
  }
  async toggleEnableAddCard(): Promise<boolean> {
    this.enableAddCardLoading = true;
    const response = await OrderService.UpdatePrinterConfiguration({
      data: {
        enableAddCard: this.formData.enableAddCard == true ? "OFF" : "ON",
      },
    });
    if (!response || response.status != 200) {
      this.enableAddCardLoading = false;
      this.handleApiError(response);
      return false;
    }
    // 更新本地数据
    await this.loadPrinter();
    this.enableAddCardLoading = false;
    this.showSuccessToast("保存成功");
    return true;
  }
  async toggleEnablePayBill(): Promise<boolean> {
    this.enablePayBillLoading = true;
    const response = await OrderService.UpdatePrinterConfiguration({
      data: {
        enablePayBill: this.formData.enablePayBill == true ? "OFF" : "ON",
      },
    });
    if (!response || response.status != 200) {
      this.enablePayBillLoading = false;
      this.handleApiError(response);
      return false;
    }
    // 更新本地数据
    await this.loadPrinter();
    this.enablePayBillLoading = false;
    this.showSuccessToast("保存成功");
    return true;
  }
  async toggleEnableTopUp(): Promise<boolean> {
    this.enableTopUpLoading = true;
    const response = await OrderService.UpdatePrinterConfiguration({
      data: {
        enableTopUp: this.formData.enableTopUp == true ? "OFF" : "ON",
      },
    });
    if (!response || response.status != 200) {
      this.enableTopUpLoading = false;
      this.handleApiError(response);
      return false;
    }
    // 更新本地数据
    await this.loadPrinter();
    this.enableTopUpLoading = false;
    this.showSuccessToast("保存成功");
    return true;
  }
  async printModeChanged(label: string): Promise<void> {
    const response = await OrderService.UpdatePrinterConfiguration({
      data: {
        enableSilent: label == "SILENT" ? "ON" : "OFF",
      },
    });
    if (!response || response.status != 200) {
      this.handleApiError(response);
      return;
    }
    // 更新本地数据
    await this.loadPrinter();
    this.showSuccessToast("保存成功");
  }
  async printPapersChanged(value: number): Promise<void> {
    const response = await OrderService.UpdatePrinterConfiguration({
      data: {
        printPapers: value,
      },
    });
    if (!response || response.status != 200) {
      this.handleApiError(response);
      return;
    }
    // 更新本地数据
    await this.loadPrinter();
    this.showSuccessToast("保存成功");
  }
  async loadPrinter(): Promise<boolean> {
    const response = await OrderService.GetPrinterConfiguration({});
    if (response && response.status === 200) {
      const printer: Printer = {
        addCard: response.data?.enableAddCard ?? false,
        payBill: response.data?.enablePayBill ?? false,
        topUp: response?.data?.enableTopUp ?? false,
        silent: response?.data?.enableSilent ?? false,
        papers: response?.data?.printPapers ?? 2,
      };
      StorageManager.setObject(StorageManager.printer, printer);
    }
    return true;
  }
}

interface PrinterFormData {
  enableAddCard: boolean;
  enablePayBill: boolean;
  enableTopUp: boolean;
  printMode: string;
  printPapers: number;
}
