
import OrderService from "@/api/OrderService";
import { BaseVue } from "@/libs/BaseVue";
import { Options } from "vue-class-component";
import QRCodeVue3 from "qrcode-vue3";

@Options({
  props: {
    modelValue: Boolean,
  },
  components: {
    QRCodeVue3,
  },
})
export default class ShopQrcodeDialog extends BaseVue {
  modelValue = false;
  public get visible(): boolean {
    return this.modelValue;
  }
  public set visible(value: boolean) {
    this.$emit("update:modelValue", value);
  }
  qrcode = "";
  loading = false;
  created(): void {
    this.refresh();
  }
  async refresh(): Promise<void> {
    this.loading = true;
    const response = await OrderService.GetShopLink({});
    this.loading = false;
    if (!response || response.status != 200) {
      this.handleApiError(response);
      return;
    }
    this.qrcode = response.data?.link ?? "";
  }
}
