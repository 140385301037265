export class UserInfo {
  userId = 0;
  name?: string;
  gender?: string;
  phone?: string;
}

export class CardType {
  id = 0;
  name?: string;
  discountType?: string;
  amount = 0;
}

export class PayType {
  id = 0;
  name?: string;
  type?: string;
}

export class MenuRoute {
  name?: string;
  params?: { [key: string]: string };
}

export class MenuItem {
  id?: string;
  name?: string;
  icon?: string;
  indexPath?: string[];
  route?: MenuRoute;
  items?: MenuItem[];
}

export interface TabItem<T> {
  id: string;
  selected: boolean;
  value: T;
  timestamp: number;
}

export class ProjectItem {
  constructor(id = 0, name = "", price = 0, vipPrice = 0) {
    this.id = id;
    this.name = name;
    this.price = price;
    this.vipPrice = vipPrice;
  }

  id = 0;
  name = "";
  price = 0;
  vipPrice = 0;
}

export class Employee {
  constructor(
    userId = 0,
    name = "",
    phone = "",
    sex = "",
    position = "",
    basePay = 0
  ) {
    this.userId = userId;
    this.name = name;
    this.phone = phone;
    this.sex = sex;
    this.position = position;
    this.basePay = basePay;
  }

  basePay = 0;
  name = "";
  phone = "";
  position = "Master";
  sex = "";
  userId = 0;
}

export interface Store {
  id: number;
  name: string;
  mobilePhone1?:string;
  mobilePhone2?:string;
  address?:string;
  wechat?:string;
  joinPassword?:string;
}

export interface Printer {
  addCard: boolean;
  payBill: boolean;
  topUp: boolean;
  silent: boolean;
  papers: number;
}
