import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
  Router,
  RouteRecordRaw,
} from "vue-router";

import LaunchPage from "@/pages/launch/LaunchPage.vue";

import AuthWindow from "@/pages/auth/AuthWindow.vue";
import LoginByPasswordPage from "@/pages/auth/login-password/LoginByPasswordPage.vue";
import LoginByCaptchaPage from "@/pages/auth/login-captcha/LoginByCaptchaPage.vue";
import FindPasswordPage from "@/pages/auth/find-password/FindPasswordPage.vue";

import MainWindow from "@/pages/main/MainWindow.vue";
import HomePage from "@/pages/main/home/HomePage.vue";
import AddVipCardPage from "@/pages/main/vip/AddVipCardPage.vue";
import PayBillPage from "@/pages/main/pay/PayBillPage.vue";
import QueryPayBillPage from "@/pages/main/pay/QueryPayBillPage.vue";

import TopUpVipCardPage from "@/pages/main/vip/TopUpVipCardPage.vue";
import ReduceVipCardPage from "@/pages/main/vip/ReduceVipCardPage.vue";
import VipRecordPage from "@/pages/main/vip/VipRecordPage.vue";
import MoneyProjectsGift from "@/pages/main/vip/MoneyProjectsGift.vue";


import PointGiftPage from "@/pages/main/vip/PointGiftPage.vue";
import CouponGiftPage from "@/pages/main/vip/CouponGiftPage.vue";
import VipListPage from "@/pages/main/vip/VipListPage.vue";
import EmployeeConfigurationPage from "@/pages/main/configurations/EmployeeConfigurationPage.vue";
import ItemConfigurationPage from "@/pages/main/configurations/ItemConfigurationPage.vue";
import CardTypeConfigurationPage from "@/pages/main/configurations/CardTypeConfigurationPage.vue";
import PayTypeConfigurationPage from "@/pages/main/configurations/PayTypeConfigurationPage.vue";
import PointConfigurationPage from "@/pages/main/configurations/PointConfigurationPage.vue";
import CouponConfigurationPage from "@/pages/main/configurations/CouponConfigurationPage.vue";
import ItemBonusConfigurationPage from "@/pages/main/configurations/ItemBonusConfigurationPage.vue";
import ShopConfigurationPage from "@/pages/main/configurations/ShopConfigurationPage.vue";
import RevenueReportPage from "@/pages/main/reports/RevenueReportPage.vue";
import EmployeeBonusReportPage from "@/pages/main/reports/EmployeeBonusReportPage.vue";
import EmployeeSalaryReportPage from "@/pages/main/reports/EmployeeSalaryReportPage.vue";
import VipCardReportPage from "@/pages/main/reports/VipCardReportPage.vue";
import WeixinConfigurationPage from "@/pages/main/configurations/WeixinConfigurationPage.vue";
import AddShopConfigurationPage from "@/pages/main/configurations/AddShopConfigurationPage.vue";
import PrinterConfigurationPage from "@/pages/main/configurations/PrinterConfigurationPage.vue";
import EmployeeSalesReportPage from "@/pages/main/reports/EmployeeSalesReportPage.vue";
import TopUpBonusConfigurationPage from "@/pages/main/configurations/TopUpBonusConfigurationPage.vue";
import AccountBalancePage from "@/pages/main/account/AccountBalancePage.vue";
import SmsConfigurationPage from "@/pages/main/configurations/SmsConfigurationPage.vue";
import AccountSubscriptionPage from "@/pages/main/account/AccountSubscriptionPage.vue";
import SubscriptionPlansPage from "@/pages/common/SubscriptionPlansPage.vue";
import CheckUpdatePage from "@/pages/launch/CheckUpdatePage.vue";

import Debug from "@/libs/Debug";

export default class AppRouter {
  static readonly instance = new AppRouter();

  static readonly launch = "launch";
  static readonly loginByPassword = "login-by-password";
  static readonly loginByCaptcha = "login-by-captcha";
  static readonly findPassword = "find-password";
  static readonly home = "home";
  static readonly addVipCard = "add-vip-card";
  static readonly topUpVipCard = "top-up-vip-card";
  static readonly subscriptionPlans = "subscription-plans";

  // 配置路由规则
  private routes: RouteRecordRaw[] = [
    {
      path: "/launch",
      name: "launch",
      component: LaunchPage,
    },
    {
      path: "/auth",
      name: "auth-window",
      component: AuthWindow,
      children: [
        {
          path: "",
          component: LoginByPasswordPage,
          meta: {
            keepAlive: true,
          },
        },
        {
          path: "login-by-password",
          name: "login-by-password",
          component: LoginByPasswordPage,
          meta: {
            keepAlive: true,
          },
        },
        {
          path: "login-by-captcha",
          name: "login-by-captcha",
          component: LoginByCaptchaPage,
          meta: {
            keepAlive: true,
          },
        },
        {
          path: "find-password",
          name: "find-password",
          component: FindPasswordPage,
          meta: {
            keepAlive: true,
          },
        },
      ],
    },
    {
      path: "/main",
      name: "main-window",
      component: MainWindow,
      children: [
        {
          path: "",
          component: HomePage,
          meta: {
            keepAlive: true,
          },
        },
        {
          path: "home",
          name: "home",
          component: HomePage,
          meta: {
            keepAlive: true,
          },
        },
        {
          path: "vip/add-vip-card",
          name: "add-vip-card",
          component: AddVipCardPage,
          meta: {
            keepAlive: true,
          },
        },
        {
          path: "vip/top-up-vip-card",
          name: "top-up-vip-card",
          component: TopUpVipCardPage,
          meta: {
            keepAlive: true,
          },
        },
        {
          path: "vip/reduce-vip-card",
          name: "reduce-vip-card",
          component: ReduceVipCardPage,
          meta: {
            keepAlive: true,
          },
        },
        {
          path: "vip/point-gift",
          name: "point-gift",
          component: PointGiftPage,
          meta: {
            keepAlive: true,
          },
        },
        {
          path: "vip/coupn-gift",
          name: "coupn-gift",
          component: CouponGiftPage,
          meta: {
            keepAlive: true,
          },
        },
        {
          path: "vip/money-projects-gift",
          name: "money-projects-gift",
          component: MoneyProjectsGift,
          meta: {
            keepAlive: true,
          },
        },
        
        {
          path: "pay/pay-bill",
          name: "pay-bill",
          component: PayBillPage,
          meta: {
            keepAlive: true,
          },
        },
        {
          path: "pay/query-bill-info",
          name: "query-bill-info",
          component: QueryPayBillPage,
          meta: {
            keepAlive: true,
          },
        },
        {
          path: "vip/query-vip-card-info",
          name: "query-vip-card-info",
          component: VipListPage,
          meta: {
            keepAlive: true,
          },
        },
        {
          path: "vip/query-vip-card-record",
          name: "query-vip-card-record",
          component: VipRecordPage,
          meta: {
            keepAlive: true,
          },
        },

        {
          path: "configurations/employees",
          name: "employee-configuration",
          component: EmployeeConfigurationPage,
          meta: {
            keepAlive: true,
          },
        },
        {
          path: "configurations/items",
          name: "item-configuration",
          component: ItemConfigurationPage,
          meta: {
            keepAlive: true,
          },
        },
        {
          path: "configurations/card-types",
          name: "card-type-configuration",
          component: CardTypeConfigurationPage,
          meta: {
            keepAlive: true,
          },
        },
        {
          path: "configurations/pay-types",
          name: "pay-type-configuration",
          component: PayTypeConfigurationPage,
          meta: {
            keepAlive: true,
          },
        },
        {
          path: "configurations/points",
          name: "point-configuration",
          component: PointConfigurationPage,
          meta: {
            keepAlive: true,
          },
        },
        {
          path: "configurations/coupons",
          name: "coupon-configuration",
          component: CouponConfigurationPage,
          meta: {
            keepAlive: true,
          },
        },
        {
          path: "configurations/item-bonus",
          name: "item-bonus-configuration",
          component: ItemBonusConfigurationPage,
          meta: {
            keepAlive: true,
          },
        },
        {
          path: "configurations/top-up-bonus",
          name: "top-up-bonus-configuration",
          component: TopUpBonusConfigurationPage,
          meta: {
            keepAlive: true,
          },
        },
        {
          path: "configurations/shop",
          name: "shop-configuration",
          component: ShopConfigurationPage,
          meta: {
            keepAlive: true,
          },
        },
        {
          path: "reports/revenue",
          name: "revenue-report",
          component: RevenueReportPage,
          meta: {
            keepAlive: true,
          },
        },
        {
          path: "reports/employee-bonus",
          name: "employee-bonus-report",
          component: EmployeeBonusReportPage,
          meta: {
            keepAlive: true,
          },
        },
        {
          path: "reports/employee-salary",
          name: "employee-salary-report",
          component: EmployeeSalaryReportPage,
          meta: {
            keepAlive: true,
          },
        },
        {
          path: "reports/vip-card",
          name: "vip-card-report",
          component: VipCardReportPage,
          meta: {
            keepAlive: true,
          },
        },
        {
          path: "configurations/weixin",
          name: "weixin-configuration",
          component: WeixinConfigurationPage,
          meta: {
            keepAlive: true,
          },
        },
        {
          path: "configurations/add-shop",
          name: "add-shop-configuration",
          component: AddShopConfigurationPage,
          meta: {
            keepAlive: true,
          },
        },
        {
          path: "configurations/printer-configuration",
          name: "printer-configuration",
          component: PrinterConfigurationPage,
          meta: {
            keepAlive: true,
          },
        },
        {
          path: "reports/employee-sales",
          name: "employee-sales-report",
          component: EmployeeSalesReportPage,
          meta: {
            keepAlive: true,
          },
        },
        {
          path: "account/account-balance",
          name: "account-balance",
          component: AccountBalancePage,
          meta: {
            keepAlive: true,
          },
        },
        {
          path: "configurations/sms",
          name: "sms-configuration",
          component: SmsConfigurationPage,
          meta: {
            keepAlive: true,
          },
        },
        {
          path: "account/account-subscription",
          name: "account-subscription",
          component: AccountSubscriptionPage,
          meta: {
            keepAlive: true,
          },
        },
      ],
    },
    {
      path: "/common/subscription-plans",
      name: "subscription-plans",
      component: SubscriptionPlansPage,
    },
    {
      path: "/check-update",
      name: "check-update",
      component: CheckUpdatePage,
    },
    {
      path: "/:pathMatch(.*)*",
      name: "check-update",
      component: CheckUpdatePage,
    },
  ];

  private router?: Router;

  hasRoute(name: string): boolean {
    for (const route of this.routes) {
      if (this.checkIfHasRoute(route, name)) {
        return true;
      }
    }
    return false;
  }

  private checkIfHasRoute(route: RouteRecordRaw, name: string): boolean {
    if (route.name == name) {
      return true;
    }
    if (route.children && route.children.length > 0) {
      for (const child of route.children) {
        if (this.checkIfHasRoute(child, name)) {
          return true;
        }
      }
    }
    return false;
  }

  getVueRouter(base?: string): Router {
    let r = this.router;
    if (r) {
      return r;
    }
    r = createRouter({
      history: process.env.VUE_APP_WEB==='web'? createWebHashHistory() : createWebHistory(base),
      routes: this.routes,
    });
    r.beforeEach((to, from) => {
      Debug.info(
        `router navigate from ${from.name as string} to ${to.name as string}`
      );
    });
    this.router = r;
    return r;
  }

  async goTo(
    name: string,
    params?: { [key: string]: string },
    query?: { [key: string]: string }
  ): Promise<boolean> {
    try {
      const failure = await this.getVueRouter().push({
        name: name,
        params: params,
        query: query,
      });
      if (failure) {
        return false;
      }
      return true;
    } catch (e) {
      Debug.error("failed to navigate to: " + name, JSON.stringify(e));
      return false;
    }
  }
}
