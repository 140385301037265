
import { GetListOfItemBonusResponseItem } from "@/api/contracts/bonus";
import OrderService from "@/api/OrderService";
import { TabVue } from "@/libs/TabVue";
import Validator from "@/libs/Validator";
import { Options } from "vue-class-component";

@Options({})
export default class ItemBonusConfigurationPage extends TabVue {
  calculateTypeOptions = [
    {
      label: "比例金额",
      value: "PERCENTAGE",
    },
    {
      label: "固定金额",
      value: "FIXED_AMOUNT",
    },
  ];
  calculateBaseOptions = [
    {
      label: "项目原价",
      value: "ITEM_PRICE",
    },
    {
      label: "实收价格",
      value: "SALE_PRICE",
    },
  ];
  loading = true;
  tableData: BonusTableRow<GetListOfItemBonusResponseItem>[] = [];
  dialogVisible = false;
  formData: BonusFormData<GetListOfItemBonusResponseItem> = {
    source: new GetListOfItemBonusResponseItem(),
  };
  saving = false;
  pageNumber = 1;
  totalCount = 0;
  readonly pageSize = 10;
  async refresh(): Promise<void> {
    this.pageNumber = 1;
    this.totalCount = 0;
    await this.loadTableData();
  }
  async loadTableData(): Promise<void> {
    this.tableData = [];
    this.loading = true;
    const response = await OrderService.GetListOfItemBonus({
      data: {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      },
    });
    this.loading = false;
    if (response && response.status === 200) {
      this.totalCount = response.data?.totalCount ?? 0;
      response.data?.items?.forEach((item) => {
        this.tableData.push({
          source: item,
          name: item.name ?? "",
          price: (item.price ?? 0).toString(),
          vipPrice: (item.vipPrice ?? 0).toString(),
          calculateBase:
            item.bonus?.calculateBase == "ITEM_PRICE"
              ? "项目原价"
              : item.bonus?.calculateBase == "SALE_PRICE"
              ? "实收价格"
              : "",
          calculateTypeOfMaster:
            item.bonus?.calculateTypeOfMaster == "PERCENTAGE"
              ? "比例金额"
              : item.bonus?.calculateTypeOfMaster == "FIXED_AMOUNT"
              ? "固定金额"
              : "",
          calculateTypeOfSlave:
            item.bonus?.calculateTypeOfSlave == "PERCENTAGE"
              ? "比例金额"
              : item.bonus?.calculateTypeOfSlave == "FIXED_AMOUNT"
              ? "固定金额"
              : "",
          valueOfMasterAmount:
            item.bonus?.calculateTypeOfMaster == "PERCENTAGE"
              ? (item.bonus?.valueOfMasterAmount ?? 0).toString() + "%"
              : item.bonus?.calculateTypeOfMaster == "FIXED_AMOUNT"
              ? (item.bonus?.valueOfMasterAmount ?? 0).toString() + "元"
              : "",
          valueOfSlaveAmount:
            item.bonus?.calculateTypeOfSlave == "PERCENTAGE"
              ? (item.bonus?.valueOfSlaveAmount ?? 0).toString() + "%"
              : item.bonus?.calculateTypeOfSlave == "FIXED_AMOUNT"
              ? (item.bonus?.valueOfSlaveAmount ?? 0).toString() + "元"
              : "",
        });
      });
    } else {
      this.handleApiError(response);
    }
  }
  async handleCurrentPageChange(_: number): Promise<void> {
    await this.loadTableData();
  }
  handleEdit(
    index: number,
    row: BonusTableRow<GetListOfItemBonusResponseItem>
  ): void {
    console.log(index, row);
    this.formData = {
      source: row.source,
      calculateBase: row.source.bonus?.calculateBase ?? "",
      calculateTypeOfMaster: row.source?.bonus?.calculateTypeOfMaster,
      calculateTypeOfSlave: row.source?.bonus?.calculateTypeOfSlave,
      valueOfMasterAmount: row.source.bonus?.valueOfMasterAmount ?? 0,
      valueOfSlaveAmount: row.source.bonus?.valueOfSlaveAmount ?? 0,
    };
    this.dialogVisible = true;
  }
  async save(): Promise<void> {
    if (
      !Validator.isPositiveNumber(this.formData.valueOfMasterAmount) ||
      !Validator.isPositiveNumber(this.formData.valueOfSlaveAmount)
    ) {
      this.showErrorToast("提成配置必须大于0");
      return;
    }
    if (this.formData.calculateTypeOfMaster == "PERCENTAGE") {
      if (Number(this.formData.valueOfMasterAmount) > 100) {
        this.showErrorToast("提成配置必须小于等于100");
        return;
      }
    }
    if (this.formData.calculateTypeOfSlave == "PERCENTAGE") {
      if (Number(this.formData.valueOfSlaveAmount) > 100) {
        this.showErrorToast("提成配置必须小于等于100");
        return;
      }
    }
    this.saving = true;
    const response = await OrderService.UpdateItemBonus({
      data: {
        itemId: this.formData.source.id,
        calculateTypeOfMaster: this.formData.calculateTypeOfMaster,
        calculateTypeOfSlave: this.formData.calculateTypeOfSlave,
        calculateBase: this.formData.calculateBase,
        valueOfMasterAmount: this.formData.valueOfMasterAmount,
        valueOfSlaveAmount: this.formData.valueOfSlaveAmount,
      },
    });
    this.saving = false;
    if (!response || response.status != 200) {
      this.handleApiError(response);
      return;
    }
    this.showSuccessToast("保存成功");
    this.dialogVisible = false;
    this.loadTableData();
  }
}

interface BonusTableRow<T> {
  source: T;
  name?: string;
  price?: string;
  vipPrice?: string;
  calculateBase?: string;
  calculateTypeOfMaster?: string;
  calculateTypeOfSlave?: string;
  valueOfMasterAmount?: string;
  valueOfSlaveAmount?: string;
}

interface BonusFormData<T> {
  source: T;
  calculateBase?: string;
  calculateTypeOfMaster?: string;
  calculateTypeOfSlave?: string;
  valueOfMasterAmount?: number;
  valueOfSlaveAmount?: number;
}
