import { BaseVue } from "./BaseVue";
import StorageManager from "./StorageManager";
import { MenuItem, TabItem } from "@/data/storage";

export abstract class TabVue extends BaseVue {
  public get tabId(): string | undefined {
    return this.$route.name as string;
  }
  private timestamp = 0;
  async created(): Promise<void> {
    const mainTabs: TabItem<MenuItem>[] | undefined = StorageManager.getObject(
      StorageManager.mainTabs
    );
    const tab = mainTabs?.find((x) => x.value.route?.name == this.tabId);
    if (tab) {
      this.timestamp = tab.timestamp;
    }
    await this.refresh();
  }
  async activated(): Promise<void> {
    const mainTabs: TabItem<MenuItem>[] | undefined = StorageManager.getObject(
      StorageManager.mainTabs
    );
    const tab = mainTabs?.find((x) => x.value.route?.name == this.tabId);
    if (tab && this.timestamp != tab.timestamp) {
      this.timestamp = tab.timestamp;
      await this.refresh();
    }
  }
  abstract refresh(): Promise<void>;
}
