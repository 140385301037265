
import OrderService from "@/api/OrderService";
import { BaseVue } from "@/libs/BaseVue";
import { Options } from "vue-class-component";
import { watch } from "vue";
import RendererMessageManager from "@/electron/RendererMessageManager";
import { PrintCommandMessage } from "@/electron/messages";
import StorageManager from "@/libs/StorageManager";
import { Printer, Store, UserInfo } from "@/data/storage";
import Task from "@/libs/Task";
import { BillTypeConstants } from "@/data/constants";
import { PayTypeConfiguration } from "@/data/configurations";
import { DateTime } from "@/libs/DateTime";
@Options({
  emits: {
    close: () => true,
  },
  props: {
    billId: {
      type: Number,
      default: 0,
    },
  },
})
export default class BillPrintDialog extends BaseVue {
  private mode(): string {
    return StorageManager.getObject<Printer>(StorageManager.printer)?.silent ??
      true
      ? "SILENT"
      : "PREVIEW";
  }
  private papers(): number {
    return (
      StorageManager.getObject<Printer>(StorageManager.printer)?.papers ?? 2
    );
  }
  visible = false;
  preview = false;
  billId = 0;
  billDetail: BillDetail = {};
  billItems: BillItem[] = [];
  loading = false;
  userName = "";
  storePhone = "";
  storeAddress = "";
  printContentWidth = "166px";
  created(): void {
    const userInfo = StorageManager.getObject<UserInfo>(
      StorageManager.userInfo
    );
    if (!userInfo) {
      return;
    }
    this.userName = userInfo.name ?? "";

    let shop = StorageManager.getObject<Store | undefined>(
      StorageManager.store
    );
    this.storePhone = shop?.mobilePhone1 ?? "";
    this.storeAddress = shop?.address ?? "";

    watch(
      () => this.billId,
      (newValue, oldValue) => {
        if (newValue != oldValue) {
          if (newValue > 0) {
            this.visible = true;
            this.preview = this.mode() === "PREVIEW";
            this.query();
          } else {
            this.visible = false;
            this.billDetail = {};
            this.billItems = [];
          }
        }
      }
    );
  }
  closeButtonClicked(): void {
    this.$emit("close");
  }
  printButtonClicked(): void {
    const message = new PrintCommandMessage({
      body: document.getElementById("print-content")?.innerHTML,
    });
    RendererMessageManager.instance.sendDataMessage(message);
    this.$emit("close");
  }
  getPayTypeName(payId: number) {
    return PayTypeConfiguration.nameOf(payId ?? 0) ?? "无";
  }
  getSysTime() {
    return DateTime.now().toString("yyyy-MM-dd HH:mm:ss");
  }
  async query(): Promise<void> {
    this.loading = true;
    const response = await OrderService.GetOrderDetail({
      data: {
        billId: this.billId,
      },
    });
    if (this.preview) {
      this.loading = false;
    }
    if (!response || response.status != 200) {
      this.handleApiError(response);
      this.$emit("close");
      return;
    }
    const store: Store | undefined = StorageManager.getObject(
      StorageManager.store
    );
    this.billDetail = {
      shopName: store?.name ?? "",
      time: response.data?.time ?? "",
      systemBillNo: response.data?.systemBillNo ?? "",
      type: BillTypeConstants.billTypes[response.data?.billType ?? 0],
      discountAmount: (
        (response.data?.couponAmount ?? 0) +
        (response.data?.discountAmount ?? 0)
      ).toString(),
      payAmount: (response.data?.payAmount ?? 0).toString(),
      payId: response.data?.payId,
      vipName: response.data?.vipName,
    };
    if (response.data?.billType == 0 || response.data?.billType == 1) {
      if (response.data?.items && response.data?.items.length > 0) {
        response.data?.items.forEach((value, _) => {
          this.billItems.push({
            name: value.itemName ?? "",
            price: value.price.toString(),
            count: value.count.toString(),
            totalPrice: (value.price * value.count).toString(),
          });
        });
      }
    } else if (response.data?.billType == 2) {
      this.billItems.push({
        name: BillTypeConstants.billTypes[response.data?.billType ?? 0],
        price: response.data?.payAmount.toString(),
        count: "1",
        totalPrice: response.data?.payAmount.toString(),
      });
    } else if (response.data?.billType == 3) {
      if (response.data?.items && response.data?.items.length > 0) {
        response.data?.items.forEach((value, _) => {
          this.billItems.push({
            name: value.itemName ?? "",
            price: value.price.toString(),
            count: value.count.toString(),
            totalPrice: (value.price * value.count).toString(),
          });
        });
      }
    } else if (response.data?.billType == 4) {
      this.billItems.push({
        name: BillTypeConstants.billTypes[response.data?.billType ?? 0],
        price: response.data?.payAmount.toString(),
        count: "1",
        totalPrice: response.data?.payAmount.toString(),
      });
    } else if (response.data?.billType == 5) {
      if (response.data?.items && response.data?.items.length > 0) {
        response.data?.items.forEach((value, _) => {
          this.billItems.push({
            name: value.itemName ?? "",
            price: value.price.toString(),
            count: value.count.toString(),
            totalPrice: (value.price * value.count).toString(),
          });
        });
      }
    }
    if (this.mode() === "SILENT") {
      await Task.delay(1000);
      console.log(this.papers());
      const message = new PrintCommandMessage({
        body: document.getElementById("print-content")?.innerHTML,
        copy: this.papers(),
      });
      RendererMessageManager.instance.sendDataMessage(message);
      this.$emit("close");
    }
  }
}

interface BillDetail {
  shopName?: string;
  systemBillNo?: string;
  time?: string;
  type?: string;
  discountAmount?: string;
  payAmount?: string;
  payId?: number;
  vipName?: string;
}

interface BillItem {
  name?: string;
  price?: string;
  count?: string;
  totalPrice?: string;
}
