export default class Global {
  static get<T>(key: string): T | undefined {
    return (window as any)[key] as T;
  }
  static set<T>(key: string, value: T): void {
    (window as any)[key] = value;
  }
  static isElectronApp(): boolean {
    return this.get<boolean>("isElectronApp") ?? false;
  }
}
