
import AccountService from "@/api/AccountService";
import { BaseVue } from "@/libs/BaseVue";
import StorageManager from "@/libs/StorageManager";
import Validator from "@/libs/Validator";
import AppRouter from "@/router/AppRouter";
import { Options } from "vue-class-component";
import Timer from "@/libs/Timer"
import { LocalStorageProvider } from "@/libs/StorageProvider";

@Options({})
export default class LoginByCaptchaPage extends BaseVue {
  captchaButtonText = "获取验证码";
  captchaButtonDisabled = false;
  loading = false;
  logining = false;
  formData: LoginFormData = {
    phone: "",
    captcha: "",
  };
  countdown?: CaptchaCountdown;
  beforeUnmount(): void {
    this.countdown?.stop();
  }
  created(): void {
    const phone = new LocalStorageProvider().getItem(StorageManager.phone);
    if (phone.length > 0) {
      this.formData.phone = phone;
    }
  }
  async getCode(): Promise<void> {
    if (!Validator.isPhoneNumber(this.formData.phone)) {
      this.showErrorToast("非法手机号");
      return;
    }
    this.loading = true;
    this.captchaButtonText = "获取中"
    const response = await AccountService.getCode({
      data: {
        phoneNumber: this.formData.phone,
        scope: "LOG_IN",
      },
    });
    this.captchaButtonText = "获取验证码"
    this.loading = false;
    if (!response || response.status != 200) {
      this.handleApiError(response);
      return;
    }
    const seconds = response.data?.resendSeconds ?? 60;
    this.captchaButtonDisabled = true;
    this.countdown = new CaptchaCountdown(seconds, (secondsLeft) => {
      if (secondsLeft >= 0) {
        this.captchaButtonText = secondsLeft + "秒后重发";
      } else {
        this.captchaButtonText = "获取验证码";
        this.captchaButtonDisabled = false;
      }
    });
  }
  async login(): Promise<void> {
    if (!Validator.isPhoneNumber(this.formData.phone)) {
      this.showErrorToast("非法手机号");
      return;
    }
    if (!Validator.isNotBlankString(this.formData.captcha)) {
      this.showErrorToast("验证码不能为空");
      return;
    }
    this.logining = true;
    const response = await AccountService.loginByCode({
      data: {
        phoneNumber: this.formData.phone,
        code: this.formData.captcha,
      },
    });
    this.logining = false;
    if (
      response &&
      response.status == 200 &&
      response.data &&
      response.data.token
    ) {
      StorageManager.setString(StorageManager.token, response.data.token);
      new LocalStorageProvider().setItem(StorageManager.phone, this.formData.phone ?? "");
      AppRouter.instance.goTo(AppRouter.launch, {});
    } else {
      this.handleApiError(response);
    }
  }
}

interface LoginFormData {
  phone?: string;
  captcha?: string;
}

class CaptchaCountdown extends Timer {
  constructor(secondsLeft: number, updateUI: (secondsLeft: number) => void) {
    super(1000);
    this.secondsLeft = secondsLeft;
    this.updateUI = updateUI;
    this.updateUI(this.secondsLeft);
    this.start();
  }
  private secondsLeft = -1;
  private updateUI: (secondsLeft: number) => void;
  elapse(): void {
    if (this.secondsLeft <= 0) {
      this.stop();
    }
    this.secondsLeft -= 1;
    this.updateUI(this.secondsLeft);
  }
}
