
import OrderService from "@/api/OrderService";
import { TabVue } from "@/libs/TabVue";
import { DateTime } from "@/libs/DateTime";
import { Options } from "vue-class-component";

@Options({})
export default class VipCardReportPage extends TabVue {
  loading = false;
  expiration = "";
  selectionItems: SelectionItem[] = [];
  selectedShopId = -1;
  selectedItem?: SelectionItem;
  async refresh(): Promise<void> {
    this.loading = true;
    const response = await OrderService.GetVipAmountReport({});
    this.loading = false;
    if (response && response.status === 200) {
      this.selectionItems.push({
        id: 0,
        name: "全部门店",
        totalCount: response.data?.totalCount ?? 0,
        totalAmount: response.data?.totalAmount ?? 0,
        totalTopUpAmount: response.data?.totalTopUpAmount ?? 0,
        totalGiftAmount: response.data?.totalGiftAmount ?? 0,
      });
      for (const shop of response.data?.shops ?? []) {
        this.selectionItems.push({
          id: shop.id ?? 0,
          name: shop.name ?? "",
          totalCount: shop?.totalCount ?? 0,
          totalAmount: shop?.totalAmount ?? 0,
          totalTopUpAmount: shop?.totalTopUpAmount ?? 0,
          totalGiftAmount: shop?.totalGiftAmount ?? 0,
        });
      }
      this.expiration = DateTime.now().toString("截止yyyy/MM/dd HH:mm:ss");
      this.selectedShopId = 0;
      this.selectedItem = this.selectionItems.find(
        (x) => x.id == this.selectedShopId
      );
    } else {
      this.handleApiError(response);
    }
  }
  onSelectionChanged(shopId: number) {
    this.selectedItem = this.selectionItems.find((x) => x.id == shopId);
  }
}

interface SelectionItem {
  id: number;
  name: string;
  totalCount: number;
  totalAmount: number;
  totalTopUpAmount: number;
  totalGiftAmount: number;
}
