import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_drawer = _resolveComponent("el-drawer")!

  return (_openBlock(), _createBlock(_component_el_drawer, {
    size: _ctx.actualWidth,
    modelValue: _ctx.visible,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visible) = $event)),
    direction: "rtl",
    "show-close": false,
    withHeader: false
  }, {
    default: _withCtx(() => [
      _createElementVNode("iframe", {
        src: _ctx.source,
        style: {"width":"100%","height":"100%","border":"none"}
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["size", "modelValue"]))
}