export abstract class Message {
  constructor(channel: string) {
    this.channel = channel;
  }
  channel: string;
}

export abstract class EmptyMessage extends Message {}

export abstract class DataMessage<T> extends Message {
  constructor(channel: string, data: T) {
    super(channel);
    this.data = data;
  }
  data: T;
}

export interface UpdateDownloadedMessageData {
  version?: string;
  releaseNotes?: string;
  releaseDate?: string;
}

export class UpdateDownloadedMessage extends DataMessage<
  UpdateDownloadedMessageData
> {
  constructor(data: UpdateDownloadedMessageData) {
    super(UpdateDownloadedMessage.channelValue, data);
  }
  static channelValue = "update-downloaded";
}

export class InstallUpdateMessage extends EmptyMessage {
  constructor() {
    super(InstallUpdateMessage.channelValue);
  }
  static channelValue = "install-update";
}

export class CheckUpdateMessage extends EmptyMessage {
  constructor() {
    super(CheckUpdateMessage.channelValue);
  }
  static channelValue = "check-update";
}

export class UpdateAvailableMessageData {
  version?: string;
  releaseNotes?: string;
  releaseDate?: string;
  forceUpdate?: boolean;
}

export class UpdateAvailableMessage extends DataMessage<
  UpdateAvailableMessageData
> {
  constructor(data: UpdateAvailableMessageData) {
    super(UpdateAvailableMessage.channelValue, data);
  }
  static channelValue = "update-available";
}

export class DownloadUpdateMessage extends EmptyMessage {
  constructor() {
    super(DownloadUpdateMessage.channelValue);
  }
  static channelValue = "download-update";
}

export class UpdateNotAvailableMessage extends EmptyMessage {
  constructor() {
    super(UpdateNotAvailableMessage.channelValue);
  }
  static channelValue = "update-not-available";
}

export class UpdateErrorMessage extends EmptyMessage {
  constructor() {
    super(UpdateErrorMessage.channelValue);
  }
  static channelValue = "update-error";
}

export class UpdateDownloadProgressMessageData {
  percent?: number;
  bytesPerSecond?: number;
  transferred?: number;
  total?: number;
}

export class UpdateDownloadProgressMessage extends DataMessage<
  UpdateDownloadProgressMessageData
> {
  constructor(data: UpdateDownloadProgressMessageData) {
    super(UpdateDownloadProgressMessage.channelValue, data);
  }
  static channelValue = "update-download-progress";
}

export class CheckHotUpdateMessage extends EmptyMessage {
  constructor() {
    super(CheckHotUpdateMessage.channelValue);
  }
  static channelValue = "check-hot-update";
}

export class HotUpdateNotAvailableMessage extends EmptyMessage {
  constructor() {
    super(HotUpdateNotAvailableMessage.channelValue);
  }
  static channelValue = "hot-update-not-available";
}

export class HotUpdateErrorMessage extends EmptyMessage {
  constructor() {
    super(HotUpdateErrorMessage.channelValue);
  }
  static channelValue = "hot-update-error";
}

export class HotUpdateDownloadProgressMessageData {
  percent?: number;
  bytesPerSecond?: number;
  transferred?: number;
  total?: number;
}

export class HotUpdateDownloadProgressMessage extends DataMessage<
  HotUpdateDownloadProgressMessageData
> {
  constructor(data: HotUpdateDownloadProgressMessageData) {
    super(HotUpdateDownloadProgressMessage.channelValue, data);
  }
  static channelValue = "hot-update-download-progress";
}

export class HotUpdateInstallErrorMessage extends EmptyMessage {
  constructor() {
    super(HotUpdateInstallErrorMessage.channelValue);
  }
  static channelValue = "hot-update-install-error";
}

export class HotUpdateAvailableMessageData {
  version?: string;
}

export class HotUpdateAvailableMessage extends DataMessage<
  HotUpdateAvailableMessageData
> {
  constructor(data: HotUpdateAvailableMessageData) {
    super(HotUpdateAvailableMessage.channelValue, data);
  }
  static channelValue = "hot-update-available";
}

export class PrintCommandMessageData {
  body?: string;
  copy?: number;
}

export class PrintCommandMessage extends DataMessage<PrintCommandMessageData> {
  constructor(data: PrintCommandMessageData) {
    super(PrintCommandMessage.channelValue, data);
  }
  static channelValue = "print-command";
}

export class PrintFailureMessageData {
  errorCode?: string;
  errorMessage?: string;
}

export class PrintFailureMessage extends DataMessage<PrintFailureMessageData> {
  constructor(data: PrintFailureMessageData) {
    super(PrintFailureMessage.channelValue, data);
  }
  static channelValue = "print-failure";
}

export class PrintSuccessMessage extends EmptyMessage {
  constructor() {
    super(PrintSuccessMessage.channelValue);
  }
  static channelValue = "print-success";
}
