
import OrderService from "@/api/OrderService";
import { TabVue } from "@/libs/TabVue";
import Validator from "@/libs/Validator";
import { Options } from "vue-class-component";
import ShopQrcodeDialog from "@/components/ShopQrcodeDialog.vue";

@Options({
  components: {
    ShopQrcodeDialog,
  },
})
export default class ShopConfigurationPage extends TabVue {
  loading = true;
  saving = false;
  formData: ShopFormData = {
    name: "",
    mobilePhone1: "",
    mobilePhone2: "",
    address: "",
  };
  shopQrcodeDialogVisible = false;
  async refresh(): Promise<void> {
    await this.loadData();
  }
  async loadData(): Promise<void> {
    this.loading = true;
    const response = await OrderService.GetShopInfo({});
    this.loading = false;
    if (response && response.status === 200) {
      if (response.data) {
        this.formData.name = response.data.name ?? "";
        this.formData.mobilePhone1 = response.data.mobilePhone1 ?? "";
        this.formData.mobilePhone2 = response.data.mobilePhone2 ?? "";
        this.formData.address = response.data.address ?? "";
      }
    } else {
      this.handleApiError(response);
    }
  }
  async save(): Promise<void> {
    if (!Validator.isNotBlankString(this.formData.name)) {
      this.showErrorToast("店铺名称不能为空");
      return;
    }
    this.saving = true;
    const response = await OrderService.UpdateShopInfo({
      data: {
        name: this.formData.name,
        mobilePhone1: this.formData.mobilePhone1,
        mobilePhone2: this.formData.mobilePhone2,
        address: this.formData.address,
      },
    });
    this.saving = false;
    if (!response || response.status != 200) {
      this.handleApiError(response);
      return;
    }
    this.showSuccessToast("保存成功");
    await this.loadData();
  }
}

interface ShopFormData {
  name?: string;
  mobilePhone1?: string;
  mobilePhone2?: string;
  address?: string;
}
