import { RequestBase, ResponseBase } from "./base";

export class GetListOfItemBonusRequestData {
  pageNumber?: number;
  pageSize?: number;
}

export class GetListOfItemBonusRequest extends RequestBase {
  data?: GetListOfItemBonusRequestData;
}

export class GetListOfItemBonusResponseBonus {
  calculateType?: string;
  calculateBase?: string;
  calculateTypeOfMaster?: string;
  calculateTypeOfSlave?: string;
  valueOfMasterAmount?: number;
  valueOfSlaveAmount?: number;
}

export class GetListOfItemBonusResponseItem {
  id?: number;
  name?: string;
  price?: number;
  vipPrice?: number;
  bonus?: GetListOfItemBonusResponseBonus;
}

export class GetListOfItemBonusResponseData {
  items?: GetListOfItemBonusResponseItem[];
  totalCount?: number;
}

export class GetListOfItemBonusResponse extends ResponseBase {
  data?: GetListOfItemBonusResponseData;
}

export class GetEmployeeBonusRequestData {
  pageNumber?: number;
  pageSize?: number;
  startDate?: string;
  endDate?: string;
  userId?: number;
}

export class GetEmployeeBonusRequest extends RequestBase {
  data?: GetEmployeeBonusRequestData;
}

export class GetEmployeeBonusResponseBonus {
  time?: string;
  category?: number;
  billId?: number;
  type?: string;
  name?: string;
  amount?: string;
  bonus?: number;
}

export class GetEmployeeBonusResponseData {
  bonuses?: GetEmployeeBonusResponseBonus[];
  totalCount?: number;
}

export class GetEmployeeBonusResponse extends ResponseBase {
  data?: GetEmployeeBonusResponseData;
}

export class GetListOfEmployeeBonusRequestData {
  startDate?: string;
  endDate?: string;
  userId?: number;
}

export class GetListOfEmployeeBonusRequest extends RequestBase {
  data?: GetListOfEmployeeBonusRequestData;
}

export class GetListOfEmployeeBonusResponseSalary {
  userId?: number;
  name?: string;
  basePay?: number;
  bonus?: number;
  totalPay?: number;
}

export class GetListOfEmployeeBonusResponseData {
  salaries?: GetListOfEmployeeBonusResponseSalary[];
  totalBonus?: number;
  totalPay?: number;
}

export class GetListOfEmployeeBonusResponse extends ResponseBase {
  data?: GetListOfEmployeeBonusResponseData;
}

export class CalculateBonusRequestData {
  vipId?: number
  items = [] as any[];
  topUp = [] as any[];
  employees = [] as any[];
}

export class CalculateBonusRequest extends RequestBase {
  data?: CalculateBonusRequestData;
}

export class CalculateBonusResponseData {
  employees = [] as any[];
}

export class CalculateBonusResponse extends ResponseBase {
  data?: CalculateBonusResponseData;
}

export class UpdateItemBonusRequestData {
  itemId?: number;
  calculateType?: string;
  calculateBase?: string;
  calculateTypeOfMaster?: string;
  calculateTypeOfSlave?: string;
  valueOfMasterAmount?: number;
  valueOfSlaveAmount?: number;
  appliesToAllItems?: boolean;
}

export class UpdateItemBonusRequest extends RequestBase {
  data?: UpdateItemBonusRequestData;
}

export class UpdateItemBonusResponse extends ResponseBase {}

export class GetTopUpBonusRequest extends RequestBase {}

export class GetTopUpBonusResponseData {
  calculateType?: string;
  valueOfAmount?: number;
}

export class GetTopUpBonusResponse extends ResponseBase {
  data?: GetTopUpBonusResponseData;
}

export class UpdateTopUpBonusRequestData {
  calculateType?: string;
  valueOfAmount?: number;
}

export class UpdateTopUpBonusRequest extends RequestBase {
  data?: UpdateTopUpBonusRequestData;
}

export class UpdateTopUpBonusResponse extends ResponseBase {}
