
import OrderService from "@/api/OrderService";
import { TabVue } from "@/libs/TabVue";
import Validator from "@/libs/Validator";
import { Options } from "vue-class-component";

@Options({})
export default class TopUpBonusConfigurationPage extends TabVue {
  calculateTypeOptions = [
    {
      label: "比例金额",
      value: "PERCENTAGE",
    },
    {
      label: "固定金额",
      value: "FIXED_AMOUNT",
    },
  ];
  loading = false;
  saving = false;
  formData: BonusFormData = {};
  async refresh(): Promise<void> {
    this.loading = true;
    const response = await OrderService.GetTopUpBonus({});
    this.loading = false;
    if (!response || response.status != 200) {
      this.handleApiError(response);
      return;
    }
    this.formData = {
      calculateType: response.data?.calculateType,
      valueOfAmount: response.data?.valueOfAmount,
    };
  }
  async save(): Promise<void> {
    if (!Validator.isPositiveNumber(this.formData.valueOfAmount)) {
      this.showErrorToast("提成配置必须大于0");
      return;
    }
    if (this.formData.calculateType == "PERCENTAGE") {
      if (Number(this.formData.valueOfAmount) > 100) {
        this.showErrorToast("提成配置必须小于等于100");
        return;
      }
    }
    this.saving = true;
    const response = await OrderService.UpdateTopUpBonus({
      data: {
        calculateType: this.formData.calculateType,
        valueOfAmount: this.formData.valueOfAmount,
      },
    });
    this.saving = false;
    if (!response || response.status != 200) {
      this.handleApiError(response);
      return;
    }
    this.showSuccessToast("保存成功");
  }
}

interface BonusFormData {
  calculateType?: string;
  valueOfAmount?: number;
}
