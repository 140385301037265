import moment from "moment";

export class DateTime {
  static parse(dateString: string, format: string): DateTime {
    return new DateTime(moment(dateString, DateTime.convertFormat(format)));
  }
  static now(): DateTime {
    return new DateTime(moment());
  }
  static build(
    year: number,
    month: number,
    day: number,
    hour?: number,
    minute?: number,
    second?: number
  ): DateTime {
    return new DateTime(
      moment()
        .year(year)
        .month(month)
        .day(day)
        .hour(hour ?? 0)
        .minute(minute ?? 0)
        .second(second ?? 0)
        .milliseconds(0)
    );
  }

  private moment: moment.Moment;

  constructor(moment: moment.Moment) {
    this.moment = moment;
  }

  toString(format?: string): string {
    if (format) {
      return this.moment.format(DateTime.convertFormat(format)) ?? "";
    } else {
      return (
        this.moment.format(DateTime.convertFormat("yyyy-MM-dd HH:mm:ss")) ?? ""
      );
    }
  }

  get timestamp(): number {
    return this.moment.valueOf();
  }

  get year(): number {
    return this.moment.year();
  }
  get month(): number {
    return this.moment.month();
  }
  get day(): number {
    return this.moment.day();
  }
  get hour(): number {
    return this.moment.hour();
  }
  get minute(): number {
    return this.moment.minute();
  }
  get second(): number {
    return this.moment.second();
  }
  get milliseconds(): number {
    return this.moment.milliseconds();
  }
  get dayOfWeek(): number {
    return this.moment.weekday();
  }

  addYears(years: number): DateTime {
    return new DateTime(this.moment.add(years, "y"));
  }
  addMonths(months: number): DateTime {
    return new DateTime(this.moment.add(months, "M"));
  }
  addDays(days: number): DateTime {
    return new DateTime(this.moment.add(days, "d"));
  }
  addHours(hours: number): DateTime {
    return new DateTime(this.moment.add(hours, "h"));
  }
  addMinutes(minutes: number): DateTime {
    return new DateTime(this.moment.add(minutes, "m"));
  }
  addSeconds(seconds: number): DateTime {
    return new DateTime(this.moment.add(seconds, "s"));
  }
  addMilliseconds(milliseconds: number): DateTime {
    return new DateTime(this.moment.add(milliseconds, "ms"));
  }

  equals(another: DateTime): boolean {
    return this.moment.valueOf() == another.moment.valueOf();
  }
  compareTo(another: DateTime): number {
    return this.moment.valueOf() > another.moment.valueOf()
      ? 1
      : this.moment.valueOf() < another.moment.valueOf()
      ? -1
      : 0;
  }

  // yyyy-MM-dd HH:mm:ss.fff to YYYY-MM-DD HH:mm:ss.SSS
  private static convertFormat(format: string): string {
    return format
      .replaceAll("yyyy", "YYYY")
      .replaceAll("dd", "DD")
      .replaceAll("fff", "SSS");
  }
}
