export class BillTypeConstants {
  static billTypes: { [key: number]: string } = {
    0: "散客消费",
    1: "会员消费",
    2: "充值金额",
    3: "充值次数",
    4: "赠送金额",
    5: "赠送次数",
    6: "反冲",
  };
  static valueOf(key: number): string {
    return this.billTypes[key];
  }
}

export class PurchaseTypeConstants {
  static purchaseTypes: { [key: number]: string } = {
    0: "金额项目",
    1: "次数项目",
  };
  static valueOf(key: number): string {
    return this.purchaseTypes[key];
  }
}
