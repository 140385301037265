
import { BaseVue } from "@/libs/BaseVue";
import Debug from "@/libs/Debug";
import { watch } from "vue";
import { Options } from "vue-class-component";

@Options({
  props: {
    modelValue: Boolean,
    source: String,
    width: {
      type: String,
      default: "50%",
    },
  },
})
export default class WebDrawer extends BaseVue {
  modelValue = false;
  actualWidth = "50%";
  width = "50%";
  source = "";
  created() {
    watch(
      () => this.source,
      (newValue, oldValue) => {
        if (newValue != oldValue) {
          Debug.info(`source changed to: ${newValue}`);
          const index = newValue.indexOf("?");
          if (index < 0) {
            this.actualWidth = this.width;
            return;
          }
          const query = newValue.substring(index + 1).split("&");
          if (query.length == 0) {
            this.actualWidth = this.width;
            return;
          }
          let found = false;
          for (const item of query) {
            const fields = item.split("=");
            if (fields.length != 2) {
              continue;
            }
            if (fields[0] == "x-width") {
              this.actualWidth = fields[1];
              found = true;
              break;
            }
          }
          if (!found) {
            this.actualWidth = this.width;
            return;
          }
        }
      }
    );
  }
  public get visible(): boolean {
    return this.modelValue;
  }
  public set visible(value: boolean) {
    this.$emit("update:modelValue", value);
  }
}
