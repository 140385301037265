import { RequestBase, ResponseBase } from "./base";

export class GetListOfEmployeesRequestData {
  regular = false;
}

export class GetListOfEmployeesRequest extends RequestBase {
  data?: GetListOfEmployeesRequestData;
}

export class GetListOfEmployeesResponseItem {
  userId?: number;
  name?: string;
  sex?: string;
  phone?: string;
  position?: string;
  basePay?: number;
  showEditButton?: boolean;
  showResignButton?: boolean;
  showOnboardingButton?: boolean;
  showRejectButton?: boolean;
  showPermissionButton?: boolean;
}

export class GetListOfEmployeesResponseData {
  regular?: GetListOfEmployeesResponseItem[];
  pending?: GetListOfEmployeesResponseItem[];
  rejected?: GetListOfEmployeesResponseItem[];
  resigned?: GetListOfEmployeesResponseItem[];
}

export class GetListOfEmployeesResponse extends ResponseBase {
  data?: GetListOfEmployeesResponseData;
}

export class OnboardingEmployeeRequestData {
  userId?: number;
  position?: string;
  basePay?: number;
}

export class OnboardingEmployeeRequest extends RequestBase {
  data?: OnboardingEmployeeRequestData;
}

export class OnboardingEmployeeResponse extends ResponseBase {}

export class ResignEmployeeRequestData {
  userId?: number;
}

export class ResignEmployeeRequest extends RequestBase {
  data?: ResignEmployeeRequestData;
}

export class ResignEmployeeResponse extends ResponseBase {}

export class RejectEmployeeRequestData {
  userId?: number;
}

export class RejectEmployeeRequest extends RequestBase {
  data?: RejectEmployeeRequestData;
}

export class RejectEmployeeResponse extends ResponseBase {}

export class UpdateEmployeeInfoRequestData {
  userId?: number;
  position?: string;
  basePay?: number;
  name?: string;
  gender?: string;
}

export class UpdateEmployeeInfoRequest extends RequestBase {
  data?: UpdateEmployeeInfoRequestData;
}

export class UpdateEmployeeInfoResponse extends ResponseBase {}

export class GetListOfEmployeePermissionsRequestData {
  userId?: number;
}

export class GetListOfEmployeePermissionsRequest extends RequestBase {
  data?: GetListOfEmployeePermissionsRequestData;
}

export class GetListOfEmployeePermissionsResponseItem {
  code?: string;
  label?: string;
  owned?: boolean;
  locked?: boolean;
}

export class GetListOfEmployeePermissionsResponseData {
  items?: GetListOfEmployeePermissionsResponseItem[];
}

export class GetListOfEmployeePermissionsResponse extends ResponseBase {
  data?: GetListOfEmployeePermissionsResponseData;
}

export class UpdateEmployeePermissionsRequestData {
  userId?: number;
  codes?: string[];
}

export class UpdateEmployeePermissionsRequest extends RequestBase {
  data?: UpdateEmployeePermissionsRequestData;
}

export class UpdateEmployeePermissionsResponse extends ResponseBase {}
