
import { GetSystemBillNoRequest,
TopUpVipCardRequest,
TopUpVipCardRequestData ,
GetPageOfVipRequestData,
GetPageOfVipRequest,
GetVipInfoRequest,
GetVipInfoRequestData
} from "@/api/contracts/vip";
import OrderService from "@/api/OrderService";
import { CardType,ProjectItem,Employee, PayType, Printer} from "@/data/storage";
import { BaseVue } from "@/libs/BaseVue";
import StorageManager from "@/libs/StorageManager";
import { Options } from "vue-class-component";
import { ElMessage } from 'element-plus';
import { CalculateBonusRequest,CalculateBonusRequestData } from "@/api/contracts/bonus";
import BillPrintDialog from "@/components/BillPrintDialog.vue"
@Options({
  components: {
    BillPrintDialog,
  }
})
export default class TopUpVipCardPage extends BaseVue {
  formData: FormData = {
    userName: "",
    
    sex: "F",
    cardType:undefined,
    phone: "",
    remark:"",
    birthDay:"",
    cardNo:"",
    chargeType:1,
    projects:[],
    activeTab:'cz',
    tclist:[],

    systemBillNo:'',
    manualBillNo:'',
    inputMoney:'',
    payId:2

  }
  loading=false
  formRules = {
    userName: [{ required: true, message: "请输入会员姓名", trigger: "blur" }],
    phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
    cardType: [{ required: true, message: "请选择卡种", trigger: "blur" }],
  }
  projectOptions:ProjectOption [] =[];
  employeeOptions:EmployeeOption [] =[];
  cardTypeOptions: CardTypeOption[] = [];
  payTypeOptions:PayTypeOption[] = [];
  vipOptions:VipOption [] =[];
  vipInfo: Vip={
    vipId:0,
    name:"",
    gender:"",
    cardTypeId:0,
    phone:"",
    balance:0,
    discountValue:0,
    remark:"",
    points:0,
    timeBalance :[]  
  }

  errors: string[] = [];

  billPrintId = 0;

 

 async init(): Promise<void> {
     await this.getBillNo()
    const cardTypes: CardType[] | undefined = StorageManager.getObject(
      StorageManager.cardTypes
    );
     const projects: ProjectItem[] | undefined = StorageManager.getObject(
      StorageManager.projects
    );
     const employees: Employee[] | undefined = StorageManager.getObject(
      StorageManager.employees
    );
     const payTypes: PayType[] | undefined = StorageManager.getObject(
      StorageManager.payTypes
    );
    if (cardTypes) {
      for (const cardType of cardTypes) {
        this.cardTypeOptions.push({
          label: cardType.name ?? "",
          value: cardType.id,
        });
      }
    }
    if(employees){
      this.employeeOptions  = employees.map(a=> { 
         return {label:a.name,value:a.userId,position:a.position}
      })
    }
    if(projects){
      this.projectOptions  = projects.map(a=> { 
         return {label:a.name,value:a.id,price:a.price}
      })
    }
    if(payTypes){ 
      this.payTypeOptions=payTypes.filter(a=>a.type==='CASH').map(a=>{
        return { label:a.name?a.name:'',value:a.id }
      })
    }
  }
   async created(): Promise<void> {
    await this.init()
  }

   async activated(): Promise<void> {
    await this.init()
  }


  async mounted(): Promise<void> {
    // this.addStaff()
  }
  getCardTypeName(id:number){
    let node = this.cardTypeOptions.find(a=>a.value===id)
    return node?.label
  }
  changeChargeType(val:number){
      if(val===2 && this.formData.projects.length===0){
        this.addProject()
      }
      if(val===1){
        this.formData.projects.splice(0,this.formData.projects.length)
      }
   }
  async getBillNo():Promise<void>{
     const response = await OrderService.GetSystemBillNo(new GetSystemBillNoRequest());
    
    if (response && response.status === 200) {
       if(response.data?.systemBillNo){
           this.formData.systemBillNo= response.data?.systemBillNo
       }
    } else {
      this.handleApiError(response);
    }
  }

  async remoteMethod(query:string){
     if (query !== '') {
          this.loading = true;
          // ajax  fetch data ,then fill this.vipOptions
          let request=new GetPageOfVipRequest()
          request.data=new GetPageOfVipRequestData()
          request.data.keyword=query
          const response = await OrderService.GetPageOfVip(request);
          this.loading = false;
          if (response && response.status === 200 && response.data) {
            this.vipOptions=response.data.items.map(a=>{
              return {
                userId:a.vipId,
                name:a.name,
                phone:a.phone
              }
            })  
          } else {
            this.handleApiError(response);
          }
        } else {
          this.vipOptions = [];
        }
  }

   
  async save(){
   if (!this.vipInfo.vipId) {
      ElMessage.error('请先选择会员！');
      return
     }

     if(this.formData.chargeType===1){

         var  regExp=/(^[1-9]\d*$)|(^[1-9]\d*\.\d*|0\.\d*[1-9]\d*$)/
         if(!regExp.test(this.formData.inputMoney)){
          ElMessage.error('赠送金额填写不正确，必须大于0，请检查！');
          return
        }
       
     }
      if(this.formData.chargeType===2){

        let validProjects=this.formData.projects.filter(a=>a.itemId)
        if(validProjects.length===0){
           ElMessage.error('请填写次数项目！');
           return
        }
        let ids=validProjects.map(a=>a.itemId)
        let s=new Set(ids)
        if(s.size<ids.length){
           ElMessage.error('赠送项目：有重复的项目，请检查!');
           return
        }
        let reg =/(^[0-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{2}$)/;//价格：大于等于0的数字
        let bads=validProjects.filter(a=> !reg.test(a.realPrice.toString()) )
        if(bads.length){
          ElMessage.error('充值项目：实收价填写不正确，必须为大于等于0的数字，请检查!');
          return
        }

        var  countExp= /^[1-9]\d*$/;//大于0的整数
        let badCount=validProjects.filter(a=> !countExp.test(a.count.toString()) )
        if(badCount.length){
          ElMessage.error('赠送项目：项目数量不正确，必须为大于0的数字，请检查!');
          return
        }
     }

 

    this.showLoading()
    await this.saveCharge(this.vipInfo.vipId)    
  }
  async saveCharge(vipId:number){

    const request = new TopUpVipCardRequest();
    request.data = new TopUpVipCardRequestData();
    request.data.category =this.formData.chargeType===1? 'GIFT_AMOUNT':'GIFT_COUNT';
    
    request.data.vipId = vipId;
    // request.data.payId = this.formData.payId;
    request.data.systemBillNo = this.formData.systemBillNo;
    request.data.manualBillNo = this.formData.manualBillNo;
    request.data.discountAmount = 0;
    request.data.remark = this.formData.remark;

    if(this.formData.chargeType===1){
       //赠送金额
       request.data.topUpAmount = this.formData.inputMoney;
        request.data.topUpItems=[];
    }else{
      //赠送次数
       var timesList = this.formData.projects.filter(a=>a.itemId).map(a => {
          return { itemId: a.itemId, count: a.count,price: a.realPrice }
        })

      request.data.topUpAmount = "0";
      request.data.topUpItems=timesList;

    }
    // let validUsers=this.formData.tclist.filter(a=>a.userId)
    // if(validUsers.length>0){ 
    //     request.data.employeeBonuses=validUsers.map(a=>{
    //      return {
    //       source:0,
    //       amount:a.money,
    //       userId:a.userId
    //     }
    //     })
    // }
    
    const response = await OrderService.TopUpVipCard(request);
    this.hideLoading()
    if (response && response.status === 200) {
      this.showAlert("赠送成功！")
      this.clear();
      // const printer: Printer | undefined = StorageManager.getObject(StorageManager.printer)
      // if (printer && printer.topUp) {
      //   this.billPrintId = response?.data?.billId ?? 0
      // }
    } else {
      this.handleApiError(response);
    }
  }
  clear(): void {
    this.formData = {
      userName: "",
      sex: "F",
      phone: "",
      cardType:undefined,
      cardNo:"",
      remark:"",
      birthDay:"",
      projects:[],
      chargeType:1,
      activeTab:"cz",
      tclist:[],
      systemBillNo:'',
      manualBillNo:'',
      inputMoney:'',
      payId:2
      
    };
    this.errors = [];
    this.vipInfo={
      vipId:0,
      name:"",
      gender:"",
      cardTypeId:0,
      phone:"",
      balance:0,
      discountValue:0,
      remark:"",
      points:0,
      timeBalance :[]  
    };
    this.getBillNo()

    // this.addStaff()
  }

  addProject():void{
    this.formData.projects.push({
    itemName:'',
    realPrice:0,
    count:1,
    price:0
    })
  }
  removeProject(index:number):void{
    this.formData.projects.splice(index,1)
  }
  // addStaff():void{
  //   this.formData.tclist.push({
  //      userId:null,
  //      userName:'',
  //      money:''
  //   })
  // }
  removeStaff(index:number):void{
    this.formData.tclist.splice(index,1)
  }
  async onVipSelect(val:number){

          let request=new GetVipInfoRequest()
          request.data=new GetVipInfoRequestData()
          let node = this.vipOptions.find( a=> a.userId===val)
          if(!node) return
          request.data.phone=node.phone
          const response = await OrderService.GetVipInfo(request);
          if (response && response.status === 200) { 
            this.vipInfo=response.data 
          } else {
            this.handleApiError(response);
          }
  }
  onProjectSelect(val:number,row:Project,index:number){
     //  edit row 
     // val 选中项目ID
     //row 编辑的那行
     // index 编辑行的序号
     let project = this.formData.projects[index] //
     let selected =this.projectOptions.find(a=>a.value===val)
     if(!selected){
       return
     }
     project.realPrice= selected.price
     project.price= selected.price
  }

}

interface CardTypeOption {
  label: string;
  value: number;
}


interface PayTypeOption {
  label: string;
  value: number;
}

interface VipOption{
  // label:string;
  // value:number;
  name:string;
  phone:string;
  userId:number;
}

interface Vip{
  vipId:number
  name:string;
  gender:string;
  cardTypeId:number;
  phone:string;
  balance:number;
  discountValue:number;
  remark:string;
  points:number;
  timeBalance :Array<VipInfoTimeBalance>
}

interface VipInfoTimeBalance{
  itemId:number;
  count:number;
  name:string;
}

interface ProjectOption {
  label: string;
  value: number;
  price:number;
}

interface EmployeeOption {
  label: string;
  value: number;
  position:string;
}


interface Project{
    itemId?:number;
    itemName:string;
    realPrice:number;
    price:number;
    count:number;

}

interface FormData {
  userName: string;
  sex: string;
  phone: string;
  cardType?: number;
  remark:string;
  cardNo:string;
  birthDay:string;
  chargeType:number;
  projects:Array<Project>;
  activeTab:string;
  tclist:Array<any>;
  systemBillNo:string;
  manualBillNo:string;
  inputMoney:string;
  payId:number;
}
