
import { GetCouponStrategiesResponseStrategy } from "@/api/contracts/coupon";
import MarketingService from "@/api/MarketingService";
import { TabVue } from "@/libs/TabVue";
import Validator from "@/libs/Validator";
import { ElMessageBox } from "element-plus";
import { Options } from "vue-class-component";

@Options({})
export default class PayTypeConfigurationPage extends TabVue {
  loading = true;
  tableData: CouponTableRow<GetCouponStrategiesResponseStrategy>[] = [];
  dialogVisible = false;
  formData: CouponFormData<GetCouponStrategiesResponseStrategy> = {
    source: new GetCouponStrategiesResponseStrategy(),
  };
  pageNumber = 1;
  readonly pageSize = 10;
  totalCount = 0;
  saving = false;
  async refresh(): Promise<void> {
    this.pageNumber = 1;
    this.totalCount = 0;
    await this.loadTableData();
  }
  async loadTableData(): Promise<void> {
    this.tableData = [];
    this.loading = true;
    const response = await MarketingService.GetCouponStrategies({
      data: {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      },
    });
    this.loading = false;
    if (response && response.status == 200) {
      this.totalCount = response.data?.totalCount ?? 0;
      response.data?.strategies?.forEach((item) => {
        if (!item.limits || item.limits.length == 0) {
          return;
        }
        this.tableData.push({
          source: item,
          name: item.name ?? "",
          title: item.title ?? "",
          totalStock: (item.totalStock ?? 0).toString() + "张",
          multiple: item.multiple ?? false ? "允许" : "不允许",
          validDays: (item.validDays ?? 0).toString() + "天",
          enabled: item.enabled ?? false ? "启用" : "禁用",
          remark: item.remark ?? "",
          reducedAmount: (item.limits[0].amount ?? 0).toString() + "元",
          lowestAmount: (item.limits[0].lower ?? 0).toString() + "元",
        });
      });
    } else {
      this.handleApiError(response);
    }
  }
  async handleCurrentPageChange(val: number): Promise<void> {
    await this.loadTableData();
  }
  handleDelete(
    _: number,
    row: CouponTableRow<GetCouponStrategiesResponseStrategy>
  ): void {
    ElMessageBox.confirm("确认删除优惠券策略, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        this.loading = true;
        const response = await MarketingService.DeleteCouponStrategy({
          data: {
            strategyId: row.source.strategyId,
          },
        });
        this.loading = false;
        if (!response || response.status != 200) {
          this.handleApiError(response);
          return;
        }
        await this.loadTableData();
      })
      .catch(() => {});
  }
  handleEnable(
    _: number,
    row: CouponTableRow<GetCouponStrategiesResponseStrategy>
  ): void {
    ElMessageBox.confirm("确认开启优惠券策略, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        this.loading = true;
        const response = await MarketingService.EnableCouponStrategy({
          data: {
            strategyId: row.source.strategyId,
          },
        });
        this.loading = false;
        if (!response || response.status != 200) {
          this.handleApiError(response);
          return;
        }
        await this.loadTableData();
      })
      .catch(() => {});
  }
  handleDisable(
    _: number,
    row: CouponTableRow<GetCouponStrategiesResponseStrategy>
  ): void {
    ElMessageBox.confirm("确认禁用优惠券策略, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        this.loading = true;
        const response = await MarketingService.DisableCouponStrategy({
          data: {
            strategyId: row.source.strategyId,
          },
        });
        this.loading = false;
        if (!response || response.status != 200) {
          this.handleApiError(response);
          return;
        }
        await this.loadTableData();
      })
      .catch(() => {});
  }
  handleCreate(): void {
    this.formData = {
      source: new GetCouponStrategiesResponseStrategy(),
      name: "",
      title: "",
      totalStock: 1000,
      multiple: false,
      validDays: 30,
      enabled: true,
      remark: "",
      reducedAmount: 0,
      lowestAmount: 0,
    };
    this.dialogVisible = true;
  }
  async save(): Promise<void> {
    if (!Validator.isNotBlankString(this.formData.name)) {
      this.showErrorToast("优惠券方案名称不能为空");
      return;
    }
    if (!Validator.isNotBlankString(this.formData.title)) {
      this.showErrorToast("优惠券标题不能为空");
      return;
    }
    if (!Validator.isPositiveInteger(this.formData.totalStock)) {
      this.showErrorToast("优惠券最大张数必须为正整数");
      return;
    }
    if (!Validator.isPositiveInteger(this.formData.validDays)) {
      this.showErrorToast("优惠券有效期必须为正整数");
      return;
    }
    if (!Validator.isPositiveNumber(this.formData.reducedAmount)) {
      this.showErrorToast("优惠券面额必须为大于0的值");
      return;
    }
    if (!Validator.isNonnegativeNumber(this.formData.lowestAmount)) {
      this.showErrorToast("优惠券消费门槛为大于等于0的值");
      return;
    }
    this.saving = true;
    const response = await MarketingService.AddCouponStrategy({
      data: {
        name: this.formData.name,
        title: this.formData.title,
        stock: this.formData.totalStock,
        multiple: this.formData.multiple,
        validDays: this.formData.validDays,
        remark: this.formData.remark,
        limits: [
          {
            lower: this.formData.lowestAmount,
            upper: 999999,
            amount: this.formData.reducedAmount,
          },
        ],
      },
    });
    this.saving = false;
    if (!response || response.status != 200) {
      this.handleApiError(response);
      return;
    }
    this.showSuccessToast("保存成功");
    this.dialogVisible = false;
    this.loadTableData();
  }
}

interface CouponTableRow<T> {
  source: T;
  name?: string;
  title?: string;
  totalStock?: string;
  multiple?: string;
  validDays?: string;
  enabled?: string;
  remark?: string;
  reducedAmount?: string;
  lowestAmount?: string;
}

interface CouponFormData<T> {
  source: T;
  name?: string;
  title?: string;
  totalStock?: number;
  multiple?: boolean;
  validDays?: number;
  enabled?: boolean;
  remark?: string;
  reducedAmount?: number;
  lowestAmount?: number;
}
