export class RequestHead {
  cid?: string;
  token?: string;
  extensions?: { [key: string]: string };
}

export class RequestBase {
  head?: RequestHead;
}

export class ResponseBase {
  status = 0;
  message?: string;
}
