
import { GetHomePageResponseAd } from "@/api/contracts/home";
import OrderService from "@/api/OrderService";
import StorageManager from "@/libs/StorageManager";
import { Store } from "@/data/storage";
import { Options, Vue } from "vue-class-component";
import WebDrawer from "@/components/WebDrawer.vue"
import { BaseVue } from "@/libs/BaseVue";

@Options({
  components: {
    WebDrawer,
  }
})
export default class HomePage extends BaseVue {
  shopName = "";
  carouselAds: GetHomePageResponseAd[] = [];
  listAds: GetHomePageResponseAd[] = [];
  tutorialAds: GetHomePageResponseAd[] = [];
  webDrawerVisible = false;
  webDrawerSource = "";
  async created(): Promise<void> {
    this.shopName =
      StorageManager.getObject<Store>(StorageManager.store)?.name ?? "";
    const response = await OrderService.GetHomePage({});
    if (response && response.status === 200) {
      this.carouselAds = response.data?.carouselAds ?? [];
      this.listAds = response.data?.listAds ?? [];
      this.tutorialAds = response.data?.tutorialAds ?? [];
    }else{
      this.handleApiError(response)
    }
  }
  clickItem(item: GetHomePageResponseAd): void {
    this.webDrawerSource = item.link ?? "";
    this.webDrawerVisible = true;
  }
}
