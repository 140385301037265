
import { GetListOfEmployeesResponseItem } from "@/api/contracts/employee";
import { GetPageOfEmployeeSalesResponseItem } from "@/api/contracts/report";
import OrderService from "@/api/OrderService";
import { BaseVue } from "@/libs/BaseVue";
import { Options } from "vue-class-component";
import BillDetailDialog from "@/components/BillDetailDialog.vue";

@Options({
  components: {
    BillDetailDialog,
  },
})
export default class EmployeeSalesReportPage extends BaseVue {
  dateRange: string[] = [];
  selectedUserId: number | null = null;
  employeeOptions: EmployeeOption<GetListOfEmployeesResponseItem>[] = [];
  pageNumber = 1;
  pageSize = 10;
  totalCount = 0;
  tableData: EmployeeBonusTableRow<GetPageOfEmployeeSalesResponseItem>[] = [];
  loading = false;
  billDetailId = 0;
  async created(): Promise<void> {
    const response = await OrderService.GetListOfEmployees({
      data: {
        regular: true,
      },
    });
    if (response && response.status === 200) {
      response.data?.regular?.forEach((x) =>
        this.employeeOptions.push({
          label: x.name ?? "",
          value: x,
        })
      );
    } else {
      this.handleApiError(response);
    }
  }
  async query(): Promise<void> {
    if (!this.selectedUserId) {
      this.showErrorToast("请先指定员工");
      return;
    }
    if (!this.dateRange) {
      this.showErrorToast("请先选择查询起始和结束日期");
      return;
    }
    const startDate = this.dateRange[0];
    const endDate = this.dateRange[1];
    if (!startDate || !endDate) {
      this.showErrorToast("请先选择查询起始和结束日期");
      return;
    }
    this.tableData = [];
    this.loading = true;
    const response = await OrderService.GetPageOfEmployeeSales({
      data: {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        startDate: startDate,
        endDate: endDate,
        userId: this.selectedUserId,
      },
    });
    this.loading = false;
    if (response && response.status === 200) {
      this.totalCount = response.data?.totalCount ?? 0;
      response.data?.items?.forEach((x) =>
        this.tableData.push({
          source: x,
          time: x.time,
          type: x.type,
          billId: (x.billId ?? 0).toString(),
          amount: (x.amount ?? 0).toString(),
        })
      );
    } else {
      this.handleApiError(response);
    }
  }
  handleView(
    _: number,
    row: EmployeeBonusTableRow<GetPageOfEmployeeSalesResponseItem>
  ): void {
    this.billDetailId = row.source.billId ?? 0
  }
  async handleCurrentPageChange(_: number): Promise<void> {
    await this.query();
  }
}

interface EmployeeOption<T> {
  label: string;
  value: T;
}

interface EmployeeBonusTableRow<T> {
  source: T;
  time?: string;
  type?: string;
  billId?: string;
  amount?: string;
}
