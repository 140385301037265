
import { Options, Vue } from "vue-class-component";
import zhCn from "element-plus/lib/locale/lang/zh-cn";

@Options({})
export default class App extends Vue {

   locale=zhCn

}
