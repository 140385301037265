
import { GetListOfEmployeeBonusResponseSalary } from "@/api/contracts/bonus";
import { GetListOfEmployeesResponseItem } from "@/api/contracts/employee";
import OrderService from "@/api/OrderService";
import { BaseVue } from "@/libs/BaseVue";
import { Options } from "vue-class-component";

@Options({})
export default class EmployeeSalaryReportPage extends BaseVue {
  dateRange: string[] = [];
  selectedUserId = 0
  employeeOptions: EmployeeOption<GetListOfEmployeesResponseItem>[] = [
      {
          label: "全部",
          value: {
              userId: 0,
          }
      }
  ];
  pageNumber = 1;
  tableData: EmployeeSalaryTableRow<
    GetListOfEmployeeBonusResponseSalary
  >[] = [];
  loading = false;
  async created(): Promise<void> {
    const response = await OrderService.GetListOfEmployees({
      data: {
        regular: true,
      },
    });
    if (response && response.status === 200) {
      response.data?.regular?.forEach((x) =>
        this.employeeOptions.push({
          label: x.name ?? "",
          value: x,
        })
      );
    } else {
      this.handleApiError(response);
    }
  }
  async query(): Promise<void> {
    if (!this.dateRange) {
      this.showErrorToast("请先选择查询起始和结束日期");
      return;
    }
    const startDate = this.dateRange[0];
    const endDate = this.dateRange[1];
    if (!startDate || !endDate) {
      this.showErrorToast("请先选择查询起始和结束日期");
      return;
    }
    this.tableData = [];
    this.loading = true;
    const response = await OrderService.GetListOfEmployeeBonus({
      data: {
        startDate: startDate,
        endDate: endDate,
        userId: this.selectedUserId == null ? 0 : this.selectedUserId,
      },
    });
    this.loading = false;
    if (response && response.status === 200) {
      response.data?.salaries?.forEach((x) =>
        this.tableData.push({
          source: x,
          name: x.name ?? "",
          basePay: x.basePay ?? 0,
          bonus: x.bonus ?? 0,
          totalPay: x.totalPay ?? 0,
        })
      );
    } else {
      this.handleApiError(response);
    }
  }
}

interface EmployeeOption<T> {
  label: string;
  value: T;
}

interface EmployeeSalaryTableRow<T> {
  source: T;
  name: string;
  basePay: number;
  bonus: number;
  totalPay: number;
}
