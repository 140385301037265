
import { BaseVue } from "@/libs/BaseVue";
import AppRouter from "@/router/AppRouter";
import { Options } from "vue-class-component";
import RendererMessageManager from "@/electron/RendererMessageManager";
import {
  CheckHotUpdateMessage,
  CheckUpdateMessage,
  DownloadUpdateMessage,
  HotUpdateAvailableMessage,
  HotUpdateDownloadProgressMessage,
  HotUpdateErrorMessage,
  HotUpdateInstallErrorMessage,
  HotUpdateNotAvailableMessage,
  InstallUpdateMessage,
  UpdateAvailableMessage,
  UpdateDownloadedMessage,
  UpdateDownloadProgressMessage,
  UpdateErrorMessage,
  UpdateNotAvailableMessage,
} from "@/electron/messages";
import Debug from "@/libs/Debug";
import Global from "@/libs/Global";

@Options({})
export default class CheckUpdatePage extends BaseVue {
  // updates
  updateIsChecking = false;
  // app update properties
  appUpdateAvailable = false;
  appUpdateRequiredUpdate = false;
  appUpdateIsUpdating = false;
  appUpdateDownloadProgress = 0;
  appUpdateAppVersion = "";
  appUpdateReleaseNotes = "";
  // hot update properties
  hotUpdateAvailable = false;
  hotUpdateDownloadProgress = 0;

  async created(): Promise<void> {
    if (
      Global.isElectronApp() &&
      RendererMessageManager.instance.rendererLoaded()
    ) {
      this.checkAppUpdate();
    } else {
      // route to launch page if this is not electron app
      AppRouter.instance.goTo(AppRouter.launch);
    }
  }

  /**
   * start to check app update
   */
  checkAppUpdate(): void {
    RendererMessageManager.instance.listenEmptyMessage(
      UpdateErrorMessage.channelValue,
      (_) => {
        Debug.info("checking app update error.");
        // stop and hide app update and start to check hot update
        this.appUpdateAvailable = false;
        this.appUpdateIsUpdating = false;
        this.checkHotUpdate();
      }
    );
    RendererMessageManager.instance.listenEmptyMessage(
      UpdateNotAvailableMessage.channelValue,
      (_) => {
        Debug.info("available app update not found.");
        // hide app update and start to check hot update
        this.appUpdateAvailable = false;
        this.checkHotUpdate();
      }
    );
    RendererMessageManager.instance.listenDataMessage(
      UpdateAvailableMessage.channelValue,
      (message: UpdateAvailableMessage) => {
        Debug.info(
          "available app update found.\nversion:" +
            (message.data.version ?? "") +
            "\nrelease notes: " +
            (message.data.releaseNotes ?? "")
        );
        // stop update checking and show app update and update information
        this.updateIsChecking = false;
        this.appUpdateAvailable = true;
        this.appUpdateAppVersion = "发现新版本: V" + message.data.version ?? "";
        this.appUpdateReleaseNotes = message.data.releaseNotes ?? "";
        this.appUpdateRequiredUpdate = message.data.forceUpdate ?? false;
      }
    );
    RendererMessageManager.instance.listenDataMessage(
      UpdateDownloadProgressMessage.channelValue,
      (message: UpdateDownloadProgressMessage) => {
        const percentage = Math.ceil(message.data.percent ?? 0);
        Debug.info("app update download progress: " + percentage + "%");
        // refresh app update download progress
        this.appUpdateDownloadProgress = percentage;
      }
    );
    RendererMessageManager.instance.listenDataMessage(
      UpdateDownloadedMessage.channelValue,
      (_: UpdateDownloadedMessage) => {
        Debug.info("app update downloaded, it will install automatically");
        // install app update automatically
        RendererMessageManager.instance.sendEmptyMessage(
          new InstallUpdateMessage()
        );
      }
    );
    // start to check app update
    RendererMessageManager.instance.sendEmptyMessage(new CheckUpdateMessage());
    this.updateIsChecking = true;
  }

  /**
   * click 'update now' button and start to download app update
   */
  updateNow(): void {
    RendererMessageManager.instance.sendEmptyMessage(
      new DownloadUpdateMessage()
    );
    this.appUpdateIsUpdating = true;
  }

  /**
   * click 'update later' button and hide app update
   * then, app will route to launch page
   */
  updateLater(): void {
    this.appUpdateAvailable = false;
    AppRouter.instance.goTo(AppRouter.launch);
  }

  /**
   * start to check hot update
   */
  checkHotUpdate(): void {
    RendererMessageManager.instance.listenEmptyMessage(
      HotUpdateErrorMessage.channelValue,
      (_) => {
        Debug.info("checking hot update error.");
        // stop and hide hot update and route to lanuch page
        this.hotUpdateAvailable = false;
        AppRouter.instance.goTo(AppRouter.launch);
      }
    );
    RendererMessageManager.instance.listenEmptyMessage(
      HotUpdateNotAvailableMessage.channelValue,
      (_) => {
        Debug.info("available hot update not found.");
        // stop update checking and route to lanuch page
        this.updateIsChecking = false;
        AppRouter.instance.goTo(AppRouter.launch);
      }
    );
    RendererMessageManager.instance.listenDataMessage(
      HotUpdateAvailableMessage.channelValue,
      (message: HotUpdateAvailableMessage) => {
        Debug.info(
          "available hot update found.\nversion:" + (message.data.version ?? "")
        );
        // stop update checking and show hot update
        this.updateIsChecking = false;
        this.hotUpdateAvailable = true;
      }
    );
    RendererMessageManager.instance.listenDataMessage(
      HotUpdateDownloadProgressMessage.channelValue,
      (message: HotUpdateDownloadProgressMessage) => {
        const percentage = Math.ceil(message.data.percent ?? 0);
        Debug.info("hot update download progress: " + percentage + "%");
        // refresh app update download progress
        this.hotUpdateDownloadProgress = percentage;
      }
    );
    RendererMessageManager.instance.listenEmptyMessage(
      HotUpdateInstallErrorMessage.channelValue,
      (_) => {
        Debug.info("hot update install error.");
        // hide hot update and route to lanuch page
        this.showErrorToast("安装更新失败");
        this.hotUpdateAvailable = false;
        AppRouter.instance.goTo(AppRouter.launch);
      }
    );
    RendererMessageManager.instance.sendEmptyMessage(
      new CheckHotUpdateMessage()
    );
    this.updateIsChecking = true;
  }
}
