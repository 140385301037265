import { RequestBase, ResponseBase } from "./base";

export class GetCardTypesRequest extends RequestBase {}

export class GetCardTypesResponseItem {
  id = 0;
  name?: string;
  discountType?: string;
  amount = 0;
  deletable = false;
  editable = false;
  oneShopOnly = false;
}

export class GetCardTypesResponseData {
  items?: GetCardTypesResponseItem[];
}

export class GetCardTypesResponse extends ResponseBase {
  data?: GetCardTypesResponseData;
}

export class CreateCardTypeRequestData {
  name?: string;
  discountValue?: number;
  oneShopOnly?: boolean;
}

export class CreateCardTypeRequest extends RequestBase {
  data?: CreateCardTypeRequestData;
}

export class CreateCardTypeResponse extends ResponseBase {}

export class DeleteCardTypeRequestData {
  cardTypeId?: number;
}

export class DeleteCardTypeRequest extends RequestBase {
  data?: DeleteCardTypeRequestData;
}

export class DeleteCardTypeResponse extends ResponseBase {}

export class UpdateCardTypeRequestData {
  id?: number;
  name?: string;
  discountValue?: number;
  oneShopOnly?: boolean;
}

export class UpdateCardTypeRequest extends RequestBase {
  data?: UpdateCardTypeRequestData;
}

export class UpdateCardTypeResponse extends ResponseBase {}
