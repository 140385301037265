import { RequestBase, ResponseBase } from "./base";

export class GetPointConfigurationsRequest extends RequestBase {}

export class GetPointConfigurationsResponseData {
  enableGiftForTopUp?: boolean;
  enableGiftForPayBill?: boolean;
  enableRedeemForPayBill?: boolean;
  pointRatio?: number;
}

export class GetPointConfigurationsResponse extends ResponseBase {
  data?: GetPointConfigurationsResponseData;
}


export class AddVipPointsRequest extends RequestBase {
  data?: AddVipPointsRequestData;
}
export class AddVipPointsRequestData {
  vipId=0
  points=""
  remark=""

}

export class AddVipPointsResponse extends ResponseBase {
}

export class UpdatePointConfigurationRequestData {
  enableGiftForTopUp?: string;
  enableGiftForPayBill?: string;
  enableRedeemForPayBill?: string;
  pointRatio?: string;
}

export class UpdatePointConfigurationRequest extends RequestBase {
  data?: UpdatePointConfigurationRequestData;
}

export class UpdatePointConfigurationResponse extends ResponseBase {}
