import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-24cc5c37"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_page_header = _resolveComponent("el-page-header")!
  const _component_subscription_packages = _resolveComponent("subscription-packages")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_page_header, {
      title: "返回",
      content: "软件续费",
      onBack: _ctx.goBack,
      style: {"margin":"16px 0px"}
    }, null, 8, ["onBack"]),
    _createVNode(_component_subscription_packages, {
      onSubscribed: _ctx.subscribed,
      shopId: _ctx.shopId
    }, null, 8, ["onSubscribed", "shopId"])
  ]))
}