import StorageManager from "@/libs/StorageManager";
import { PayType } from "./storage";

export class PayTypeConfiguration {
  static nameOf(id: number): string | undefined {
    const payTypes: PayType[] =
      StorageManager.getObject(StorageManager.payTypes) ?? [];
    return payTypes.find((x) => x.id == id)?.name;
  }
}
