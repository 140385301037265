
import AccountService from "@/api/AccountService";
import { BaseVue } from "@/libs/BaseVue";
import Validator from "@/libs/Validator";
import AppRouter from "@/router/AppRouter";
import { Options } from "vue-class-component";
import Timer from "@/libs/Timer";
import Md5 from "@/libs/Md5";

@Options({})
export default class FindPasswordPage extends BaseVue {
  captchaButtonText = "获取验证码";
  captchaButtonDisabled = false;
  loading = false;
  reseting = false;
  formData: FindPasswordFormData = {
    phone: "",
    captcha: "",
    password: "",
  };
  countdown?: CaptchaCountdown;
  beforeUnmount(): void {
    this.countdown?.stop();
  }
  async getCode(): Promise<void> {
    if (!Validator.isPhoneNumber(this.formData.phone)) {
      this.showErrorToast("非法手机号");
      return;
    }
    this.loading = true;
    this.captchaButtonText = "获取中";
    const response = await AccountService.getCode({
      data: {
        phoneNumber: this.formData.phone,
        scope: "FIND_PWD",
      },
    });
    this.captchaButtonText = "获取验证码";
    this.loading = false;
    if (!response || response.status != 200) {
      this.handleApiError(response);
      return;
    }
    const seconds = response.data?.resendSeconds ?? 60;
    this.captchaButtonDisabled = true;
    this.countdown = new CaptchaCountdown(seconds, (secondsLeft) => {
      if (secondsLeft >= 0) {
        this.captchaButtonText = secondsLeft + "秒后重发";
      } else {
        this.captchaButtonText = "获取验证码";
        this.captchaButtonDisabled = false;
      }
    });
  }
  async reset(): Promise<void> {
    if (!Validator.isPhoneNumber(this.formData.phone)) {
      this.showErrorToast("非法手机号");
      return;
    }
    if (!Validator.isNotBlankString(this.formData.captcha)) {
      this.showErrorToast("验证码不能为空");
      return;
    }
    if (!Validator.isNotBlankString(this.formData.password)) {
      this.showErrorToast("新密码不能为空");
      return;
    }
    if (this.formData.password && this.formData.password.length < 6) {
      this.showErrorToast("新密码长度不能小于6");
      return;
    }
    this.reseting = true;
    const response = await AccountService.findPassword({
      data: {
        phoneNumber: this.formData.phone,
        verificationCode: this.formData.captcha,
        newPwd: Md5.encode(this.formData.password ?? ""),
      },
    });
    this.reseting = false;
    if (!response || response.status != 200) {
      this.handleApiError(response);
      return;
    }
    this.showSuccessToast("重置密码成功，请重新登陆");
    AppRouter.instance.goTo(AppRouter.loginByPassword, {});
  }
}

interface FindPasswordFormData {
  phone?: string;
  captcha?: string;
  password?: string;
}

class CaptchaCountdown extends Timer {
  constructor(secondsLeft: number, updateUI: (secondsLeft: number) => void) {
    super(1000);
    this.secondsLeft = secondsLeft;
    this.updateUI = updateUI;
    this.updateUI(this.secondsLeft);
    this.start();
  }
  private secondsLeft = -1;
  private updateUI: (secondsLeft: number) => void;
  elapse(): void {
    console.log("timeout");
    if (this.secondsLeft <= 0) {
      this.stop();
    }
    this.secondsLeft -= 1;
    this.updateUI(this.secondsLeft);
  }
}
