import { Options, Vue } from "vue-class-component";
import { ElMessage,ElLoading,ElMessageBox } from "element-plus";
import { ResponseBase } from "@/api/contracts/base";
import AppRouter from "@/router/AppRouter";
import StorageManager from "./StorageManager";
import { ILoadingInstance } from "element-plus/lib/el-loading/src/loading.type";
import OrderService from "@/api/OrderService";
import { UserInfo } from "@/data/storage";

@Options({
  components: {
    ElMessage,
  },
})
export class BaseVue extends Vue {

  loadingInstance : ILoadingInstance= null as any;

  handleApiError(response: ResponseBase | undefined): void {
    if (response && response.status === 200) {
      return;
    }
    if (response && response.status === 401) {
      this.logout();
    } else if (response && response.status === 400) {
      ElMessage({ showClose: true, message: "输入数据无效", type: "error" });
    } else if (response && response.message) {
      ElMessage({ showClose: true, message: response.message, type: "error" });
    } else if (response === undefined) {
      ElMessage({
        showClose: true,
        message: "网络异常，请重试",
        type: "error",
      });
    } else {
      ElMessage({ showClose: true, message: "系统开小差了", type: "error" });
    }
  }
  showErrorToast(message: string): void {
    ElMessage({ showClose: true, message: message, type: "error" });
  }
  showSuccessToast(message: string): void {
    ElMessage({
      showClose: true,
      message: message,
      type: "success",
    });
  }
  showAlert(message: string, closing?: () => void): void {
    ElMessageBox.alert(message, '提示', {
      confirmButtonText: '确定',
      beforeClose: (action, instance, done) => {
        if (closing) {
          closing();
        }
        done();
      }
    })
  }
  showLoading(message?: string): void {

     this.loadingInstance = ElLoading.service({customClass:'cus-loading', background:'rgba(0,0,0,0.8)',text:message?message:'正在提交数据，请稍后……',spinner: 'el-icon-loading'});

  }
  hideLoading(){
    this.loadingInstance.close()
  }
  logout(): void {
    StorageManager.remove(StorageManager.token);
    StorageManager.remove(StorageManager.userInfo);
    StorageManager.remove(StorageManager.store);
    StorageManager.remove(StorageManager.cardTypes);
    StorageManager.remove(StorageManager.payTypes);
    StorageManager.remove(StorageManager.webMenu);
    StorageManager.remove(StorageManager.projects);
    StorageManager.remove(StorageManager.employees);
    StorageManager.remove(StorageManager.mainTabs);
    AppRouter.instance.goTo(AppRouter.loginByPassword, {});
  }
  changeStore(): void {
    StorageManager.remove(StorageManager.store);
    StorageManager.remove(StorageManager.cardTypes);
    StorageManager.remove(StorageManager.payTypes);
    StorageManager.remove(StorageManager.webMenu);
    StorageManager.remove(StorageManager.projects);
    StorageManager.remove(StorageManager.employees);
    StorageManager.remove(StorageManager.mainTabs);
    AppRouter.instance.goTo(AppRouter.launch, {});
  }
  async loadProfile(): Promise<boolean> {
    const response = await OrderService.getUserInfo({});
    if (response && response.status === 200 && response.data) {
      const userInfo = new UserInfo();
      userInfo.userId = response.data.userId;
      userInfo.name = response.data.name;
      userInfo.gender = response.data.gender;
      userInfo.phone = response.data.phone;
      StorageManager.setObject(StorageManager.userInfo, userInfo);
      return true;
    } else {
      this.handleApiError(response);
      return false;
    }
  }
}
