
import { Options } from "vue-class-component";
import ElMenu from "element-plus/lib/el-menu";
import AppRouter from "@/router/AppRouter";
import AccountService from "@/api/AccountService";
import { LogoutRequest } from "@/api/contracts/account";
import StorageManager from "@/libs/StorageManager";
import { BaseVue } from "@/libs/BaseVue";
import { MenuItem, Store, TabItem, UserInfo } from "@/data/storage";
import Debug from "@/libs/Debug";
import Validator from "@/libs/Validator";
import OrderService from "@/api/OrderService";
import { GetUserInfoResponseData } from "@/api/contracts/user";
import Md5 from "@/libs/Md5";
import Timer from "@/libs/Timer";
import RendererMessageManager from "@/electron/RendererMessageManager";
import { PrintFailureMessage, PrintSuccessMessage } from "@/electron/messages";
import { DateTime } from "@/libs/DateTime";
import WebDrawer from "@/components/WebDrawer.vue"

@Options({
  components: {
    ElMenu,
    WebDrawer
  },
})
export default class MainWindow extends BaseVue {
  loading = false;
  name = "未设置";
  gender = "";
  storeName = "";
  profileSaving = false;
  profileDrawerVisible = false;
  profileFormData: ProfileFormData<UserInfo> = {
    source: new UserInfo(),
  };
  resetPasswordLoading = false;
  resetPasswordDialogVisible = false;
  resetPasswordFormData: ResetPasswordFormData<GetUserInfoResponseData> = {
    source: new GetUserInfoResponseData(),
  };
  passwordResetting = false;
  changePhoneLoading = false;
  changePhoneDialogVisible = false;
  changePhoneFormData: ChangePhoneFormData<GetUserInfoResponseData> = {
    source: new GetUserInfoResponseData(),
  };
  phoneChanging = false;
  getCodeLoading = false;
  captchaButtonText = "获取验证码";
  captchaButtonDisabled = false;
  countdown?: CaptchaCountdown;
  menuCollapsed = false;
  menuTabContainer: MenuTabContainer = new MenuTabContainer();

  webDrawerSource=''
  webDrawerVisible=false

  created(): void {
    this.refreshHeader();
    this.menuTabContainer.initialize();
    RendererMessageManager.instance.listenDataMessage(
      PrintFailureMessage.channelValue,
      (message: PrintFailureMessage) => {
        this.showErrorToast(message.data.errorMessage ?? "打印失败");
      }
    );
    RendererMessageManager.instance.listenEmptyMessage(
      PrintSuccessMessage.channelValue,
      () => {
        this.showSuccessToast("打印成功");
      }
    );
  }
  contactKf():void{
    this.webDrawerSource='https://cms.hdkj.cc/mobile/Views/content.html?id=52'
    this.webDrawerVisible=true
  }
  toggleMenu(): void {
    this.menuCollapsed = !this.menuCollapsed;
  }
  selectMenu(_: string, indexPath: string[]): void {
    this.menuTabContainer.selectNode(indexPath);
  }
  selectTab(tabItem: TabItem<MenuItem>): void {
    this.menuTabContainer.selectTab(tabItem);
  }
  closeTab(tabItem: TabItem<MenuItem>): void {
    this.menuTabContainer.closeTab(tabItem);
  }
  goToHomePage(): void {
    this.menuTabContainer.resetTabs();
    this.menuTabContainer.resetNodes();
    AppRouter.instance.goTo(AppRouter.home, {});
  }
  async handleProfileItem(command: string): Promise<void> {
    if (command === "logout") {
      this.loading = true;
      const response = await AccountService.logout(new LogoutRequest());
      this.loading = false;
      if (response && response.status === 200) {
        this.logout();
      } else {
        this.handleApiError(response);
      }
    } else if (command == "changeStore") {
      this.changeStore();
    } else if (command == "profile") {
      const userInfo = StorageManager.getObject<UserInfo>(
        StorageManager.userInfo
      );
      if (!userInfo) {
        return;
      }
      this.profileFormData = {
        source: userInfo,
        name: userInfo.name,
        gender: userInfo.gender,
        phone: userInfo.phone,
        changed: false,
      };
      this.profileDrawerVisible = true;
    }
  }
  refreshHeader(): void {
    this.storeName =
      StorageManager.getObject<Store | undefined>(StorageManager.store)?.name ??
      "";
    const userInfo: UserInfo | undefined = StorageManager.getObject(
      StorageManager.userInfo
    );
    if (userInfo && userInfo.name) {
      this.name = userInfo.name;
      this.gender = userInfo.gender ?? "";
    }
  }
  profileChange(): void {
    this.profileFormData.changed =
      this.profileFormData.name != this.profileFormData.source.name ||
      this.profileFormData.gender != this.profileFormData.source.gender;
  }
  async saveProfile(): Promise<void> {
    if (!Validator.isNotBlankString(this.profileFormData.name)) {
      this.showErrorToast("姓名不能为空");
      return;
    }
    this.profileSaving = true;
    const response = await OrderService.UpdateUserInfo({
      data: {
        name: this.profileFormData.name,
        gender: this.profileFormData.gender,
      },
    });
    this.profileSaving = false;
    this.profileDrawerVisible = false;
    if (!response || response.status != 200) {
      this.handleApiError(response);
      return;
    }
    if (await this.loadProfile()) {
      this.refreshHeader();
    }
  }
  async readyToResetPassword(): Promise<void> {
    this.resetPasswordLoading = true;
    const response = await OrderService.getUserInfo({});
    this.resetPasswordLoading = false;
    if (!response || response.status != 200) {
      this.handleApiError(response);
      return;
    }
    this.resetPasswordFormData = {
      source: response.data ?? new GetUserInfoResponseData(),
    };
    this.profileDrawerVisible = false;
    this.resetPasswordDialogVisible = true;
  }
  async resetPassword(): Promise<void> {
    if (this.resetPasswordFormData.source.hasPassword) {
      if (!Validator.isNotBlankString(this.resetPasswordFormData.oldPassword)) {
        this.showErrorToast("原密码不能为空");
        return;
      }
    }
    if (!Validator.isNotBlankString(this.resetPasswordFormData.newPassword)) {
      this.showErrorToast("新密码不能为空");
      return;
    }
    if (
      this.resetPasswordFormData.newPassword &&
      this.resetPasswordFormData.newPassword.length < 6
    ) {
      this.showErrorToast("新密码长度不能小于6个字符");
      return;
    }
    this.passwordResetting = true;
    const response = await AccountService.resetPassword({
      data: {
        oldPwd: Md5.encode(this.resetPasswordFormData.oldPassword ?? ""),
        newPwd: Md5.encode(this.resetPasswordFormData.newPassword ?? ""),
      },
    });
    this.passwordResetting = false;
    if (!response || response.status != 200) {
      this.handleApiError(response);
      return;
    }
    this.showSuccessToast("修改密码成功，请使用新密码重新登录");
    this.resetPasswordDialogVisible = false;
    this.logout();
  }
  async getCode(): Promise<void> {
    if (!Validator.isPhoneNumber(this.changePhoneFormData.newPhoneNumber)) {
      this.showErrorToast("非法手机号");
      return;
    }
    this.getCodeLoading = true;
    this.captchaButtonText = "获取中";
    const response = await AccountService.getCode({
      data: {
        phoneNumber: this.changePhoneFormData.newPhoneNumber,
        scope: "CHG_PHONE",
      },
    });
    this.captchaButtonText = "获取验证码";
    this.getCodeLoading = false;
    if (!response || response.status != 200) {
      this.handleApiError(response);
      return;
    }
    const seconds = response.data?.resendSeconds ?? 60;
    this.captchaButtonDisabled = true;
    this.countdown = new CaptchaCountdown(seconds, (secondsLeft) => {
      if (secondsLeft >= 0) {
        this.captchaButtonText = secondsLeft + "秒后重发";
      } else {
        this.captchaButtonText = "获取验证码";
        this.captchaButtonDisabled = false;
      }
    });
  }
  async readyToChangePhone(): Promise<void> {
    this.changePhoneLoading = true;
    const response = await OrderService.getUserInfo({});
    this.changePhoneLoading = false;
    if (!response || response.status != 200) {
      this.handleApiError(response);
      return;
    }
    this.changePhoneFormData = {
      source: response.data ?? new GetUserInfoResponseData(),
    };
    this.profileDrawerVisible = false;
    this.changePhoneDialogVisible = true;
  }
  async changePhone(): Promise<void> {
    if (this.changePhoneFormData.source.hasPassword) {
      if (!Validator.isNotBlankString(this.changePhoneFormData.oldPassword)) {
        this.showErrorToast("原密码不能为空");
        return;
      }
    }
    if (!Validator.isPhoneNumber(this.changePhoneFormData.newPhoneNumber)) {
      this.showErrorToast("新手机号为非法值");
      return;
    }
    if (!Validator.isNotBlankString(this.changePhoneFormData.captcha)) {
      this.showErrorToast("验证码不能为空");
      return;
    }
    this.phoneChanging = true;
    const response = await AccountService.changePhone({
      data: {
        password: Md5.encode(this.changePhoneFormData.oldPassword ?? ""),
        verificationCode: this.changePhoneFormData.captcha,
        newPhoneNumber: this.changePhoneFormData.newPhoneNumber,
      },
    });
    this.phoneChanging = false;
    if (!response || response.status != 200) {
      this.handleApiError(response);
      return;
    }
    this.showSuccessToast("改绑手机号成功，请使用新手机号重新登录");
    this.changePhoneDialogVisible = false;
    this.logout();
  }
}

interface ProfileFormData<T> {
  source: T;
  name?: string;
  gender?: string;
  phone?: string;
  changed?: boolean;
}

interface ResetPasswordFormData<T> {
  source: T;
  oldPassword?: string;
  newPassword?: string;
}

interface ChangePhoneFormData<T> {
  source: T;
  oldPassword?: string;
  newPhoneNumber?: string;
  captcha?: string;
}

class CaptchaCountdown extends Timer {
  constructor(secondsLeft: number, updateUI: (secondsLeft: number) => void) {
    super(1000);
    this.secondsLeft = secondsLeft;
    this.updateUI = updateUI;
    this.updateUI(this.secondsLeft);
    this.start();
  }
  private secondsLeft = -1;
  private updateUI: (secondsLeft: number) => void;
  elapse(): void {
    if (this.secondsLeft <= 0) {
      this.stop();
    }
    this.secondsLeft -= 1;
    this.updateUI(this.secondsLeft);
  }
}

interface TreeNode<T> {
  index: string;
  items: TreeNode<T>[];
  value: T;
}

interface Tree<T> {
  nodes: TreeNode<T>[];
  selectedIndex: string;
  selectedNodes: TreeNode<T>[];
  selectNode(indexPath: string[]): void;
  resetNodes(): void;
}

interface TabContainer<T> {
  items: TabItem<T>[];
  selectTab(tabItem: TabItem<T>): void;
  closeTab(tabItem: TabItem<T>): void;
  resetTabs(): void;
}

class MenuTabContainer implements TabContainer<MenuItem>, Tree<MenuItem> {
  initialize(): void {
    // 加载菜单栏
    const menuItems: MenuItem[] =
      StorageManager.getObject(StorageManager.webMenu) ?? [];
    menuItems.forEach((x) => {
      const node: TreeNode<MenuItem> = {
        index: x.id ?? "",
        value: x,
        items: [],
      };
      if (x.items) {
        x.items.forEach((y) =>
          node.items.push({
            index: y.id ?? "",
            value: y,
            items: [],
          })
        );
      }
      this.nodes.push(node);
    });
    // 加载Tabs
    const mainTabs: TabItem<MenuItem>[] | undefined = StorageManager.getObject(
      StorageManager.mainTabs
    );
    if (mainTabs) {
      mainTabs.forEach((x) => {
        const nodes = this.findNode(x.value.indexPath ?? []);
        if (nodes.length > 0) {
          const node = nodes[nodes.length - 1];
          this.items.push({
            id: node.value.id ?? "",
            selected: x.selected,
            value: node.value,
            timestamp: DateTime.now().timestamp,
          });
        }
      });
      const selectedTab = this.items.find((x) => x.selected);
      if (selectedTab) {
        this.selectTab(selectedTab);
      }
    }
  }
  // tree implementation
  nodes: TreeNode<MenuItem>[] = [];
  selectedIndex = "";
  selectedNodes: TreeNode<MenuItem>[] = [];
  selectNode(indexPath: string[]): void {
    if (indexPath.length == 0) {
      this.resetNodes();
      AppRouter.instance.goTo(AppRouter.home, {});
      return;
    }
    const nodes = this.findNode(indexPath);
    if (nodes.length == 0) {
      this.resetNodes();
      Debug.error("menu not found: " + indexPath);
      return;
    }
    this.selectedNodes = nodes;
    const node = nodes[nodes.length - 1];
    if (node.value.route && node.value.route.name) {
      this.selectedIndex = node.index;
      this.selectOrAddTab({
        id: node.value.id ?? "",
        selected: false,
        value: node.value,
        timestamp: DateTime.now().timestamp,
      });
      Debug.info("navigate to page: " + node.value.route.name);
      AppRouter.instance.goTo(
        node.value.route.name,
        node.value.route?.params ?? {}
      );
    } else {
      this.selectedIndex = "";
      Debug.error("menu not found: " + indexPath);
    }
  }
  resetNodes(): void {
    this.selectedIndex = "";
    this.selectedNodes = [];
  }
  private findNode(indexPath: string[]): TreeNode<MenuItem>[] {
    let selectedNodes: TreeNode<MenuItem>[] = [];
    let nodes: TreeNode<MenuItem>[] | undefined = this.nodes;
    for (let index of indexPath) {
      if (nodes == undefined) {
        return [];
      }
      let selectedNode: TreeNode<MenuItem> | undefined = nodes.find(
        (x) => x.index == index
      );
      if (selectedNode) {
        selectedNodes.push(selectedNode);
      } else {
        return [];
      }
      if (selectedNode.items) {
        nodes = selectedNode.items;
      } else {
        nodes = undefined;
      }
    }
    return selectedNodes;
  }
  // tab container implementation
  items: TabItem<MenuItem>[] = [];
  selectTab(tabItem: TabItem<MenuItem>): void {
    this.selectNode(tabItem.value.indexPath ?? []);
  }
  closeTab(tabItem: TabItem<MenuItem>): void {
    if (tabItem.selected) {
      // 当前TAG关闭，打开它右侧最近的一个TAG，
      // 如果右侧没有TAG，则打开它左侧最近的一个TAG，
      // 如果左侧没有TAG，则默认打开首页
      const index = this.items.findIndex((x) => x == tabItem);
      if (index < 0) {
        return;
      }
      let nextIndex: number;
      if (index < this.items.length - 1) {
        nextIndex = index;
      } else if (index > 0) {
        nextIndex = index - 1;
      } else {
        nextIndex = -1;
      }
      this.removeTab(tabItem);
      if (nextIndex >= 0) {
        this.selectNode(this.items[nextIndex].value.indexPath ?? []);
      } else {
        this.selectNode([]);
      }
    } else {
      this.removeTab(tabItem);
    }
  }
  resetTabs(): void {
    this.items.forEach((x) => (x.selected = false));
    StorageManager.setObject(StorageManager.mainTabs, this.items);
  }
  private selectOrAddTab(tabItem: TabItem<MenuItem>) {
    this.items.forEach((x) => (x.selected = false));
    const item = this.items.find((x) => x.value.id === tabItem.value.id);
    if (item) {
      item.selected = true;
    } else {
      tabItem.selected = true;
      this.items.push(tabItem);
    }
    StorageManager.setObject(StorageManager.mainTabs, this.items);
  }
  private removeTab(tabItem: TabItem<MenuItem>) {
    this.items = this.items.filter((x) => x != tabItem);
    StorageManager.setObject(StorageManager.mainTabs, this.items);
  }
}
