import ApiClient from "@/libs/ApiClient";
import {
  ChangePhoneRequest,
  ChangePhoneResponse,
  FindPasswordRequest,
  FindPasswordResponse,
  GetCodeRequest,
  GetCodeResponse,
  LoginByCodeRequest,
  LoginByCodeResponse,
  LoginByPasswordRequest,
  LoginByPasswordResponse,
  LogoutRequest,
  LogoutResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
  VerifyTokenRequest,
  VerifyTokenResponse,
} from "./contracts/account";

export default class AccountService {
  static readonly base = "/accountservice/api";
  static readonly client = new ApiClient("");

  static async loginByPassword(
    request: LoginByPasswordRequest
  ): Promise<LoginByPasswordResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/account/LoginByPassword",
      request
    );
  }

  static async verifyToken(
    request: VerifyTokenRequest
  ): Promise<VerifyTokenResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/identity/VerifyToken",
      request
    );
  }

  static async logout(
    request: LogoutRequest
  ): Promise<LogoutResponse | undefined> {
    return await this.client.postAsync(this.base + "/account/logout", request);
  }

  static async getCode(
    request: GetCodeRequest
  ): Promise<GetCodeResponse | undefined> {
    return await this.client.postAsync(this.base + "/account/GetCode", request);
  }

  static async loginByCode(
    request: LoginByCodeRequest
  ): Promise<LoginByCodeResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/account/LoginByCode",
      request
    );
  }

  static async findPassword(
    request: FindPasswordRequest
  ): Promise<FindPasswordResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/account/FindPassword",
      request
    );
  }

  static async resetPassword(
    request: ResetPasswordRequest
  ): Promise<ResetPasswordResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/account/ResetPassword",
      request
    );
  }

  static async changePhone(
    request: ChangePhoneRequest
  ): Promise<ChangePhoneResponse | undefined> {
    return await this.client.postAsync(
      this.base + "/account/ChangePhone",
      request
    );
  }
}
