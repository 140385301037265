import { RequestBase, ResponseBase } from "./base";

export class LoginByPasswordRequestData {
  phoneNumber?: string;
  password?: string;
}

export class LoginByPasswordRequest extends RequestBase {
  data?: LoginByPasswordRequestData;
}

export class LoginByPasswordResponseData {
  token?: string;
}

export class LoginByPasswordResponse extends ResponseBase {
  data?: LoginByPasswordResponseData;
}

export class VerifyTokenRequest extends RequestBase {}

export class VerifyTokenResponse extends ResponseBase {}

export class LogoutRequest extends RequestBase {}

export class LogoutResponse extends ResponseBase {}

export class GetCodeRequestData {
  phoneNumber?: string;
  scope?: string;
}

export class GetCodeRequest extends RequestBase {
  data?: GetCodeRequestData;
}

export class GetCodeResponseData {
  resendSeconds?: number;
}

export class GetCodeResponse extends ResponseBase {
  data?: GetCodeResponseData;
}

export class LoginByCodeRequestData {
  phoneNumber?: string;
  code?: string;
}

export class LoginByCodeRequest extends RequestBase {
  data?: LoginByCodeRequestData;
}

export class LoginByCodeResponseData {
  token?: string;
}

export class LoginByCodeResponse extends ResponseBase {
  data?: LoginByCodeResponseData;
}

export class FindPasswordRequestData {
  phoneNumber?: string;
  verificationCode?: string;
  newPwd?: string;
}

export class FindPasswordRequest extends RequestBase {
  data?: FindPasswordRequestData;
}

export class FindPasswordResponse extends ResponseBase {}

export class ResetPasswordRequestData {
  oldPwd?: string;
  newPwd?: string;
}

export class ResetPasswordRequest extends RequestBase {
  data?: ResetPasswordRequestData;
}

export class ResetPasswordResponse extends ResponseBase {}

export class ChangePhoneRequestData {
  password?: string;
  verificationCode?: string;
  newPhoneNumber?: string;
}

export class ChangePhoneRequest extends RequestBase {
  data?: ChangePhoneRequestData;
}

export class ChangePhoneResponse extends ResponseBase {}
