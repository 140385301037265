
import AccountService from "@/api/AccountService";
import {
  GetListOfShopResponseShop,
  GetListOfShopsV2ResponseShop,
} from "@/api/contracts/user";
import OrderService from "@/api/OrderService";
import { BaseVue } from "@/libs/BaseVue";
import StorageManager from "@/libs/StorageManager";
import AppRouter from "@/router/AppRouter";
import { Options } from "vue-class-component";
import { Store } from "@/data/storage";
import Validator from "@/libs/Validator";

@Options({})
export default class LaunchPage extends BaseVue {
  loading = false;
  shopsVisible = false;
  switching = false;
  createCompanyButtonVisible = false;
  createCompanyDialogVisible = false;
  formData: CreateCompanyFormData = {};
  creating = false;
  stores: StoreTableRow<GetListOfShopsV2ResponseShop>[] = [];
  async created(): Promise<void> {
    if (await this.checkToken()) {
      const store: Store | undefined = StorageManager.getObject(
        StorageManager.store
      );
      if (store) {
        if (await this.checkSubscription()) {
          if (await this.loadShopData()) {
            AppRouter.instance.goTo(AppRouter.home, {});
          } else {
            this.logout();
          }
        } else {
          await this.loadShops();
        }
      } else {
        await this.loadShops();
      }
    } else {
      AppRouter.instance.goTo(AppRouter.loginByPassword, {});
    }
  }

  private async checkToken(): Promise<boolean> {
    this.loading = true;
    const token = StorageManager.getString(StorageManager.token);
    if (token.length === 0) {
      return false;
    }
    const verifyTokenResponse = await AccountService.verifyToken({});
    if (!verifyTokenResponse || verifyTokenResponse.status != 200) {
      return false;
    }
    if (!(await this.loadProfile())) {
      this.showErrorToast("加载数据失败");
      return false;
    }
    this.loading = false;
    return true;
  }

  private async checkSubscription(): Promise<boolean> {
    this.loading = true;
    const response = await OrderService.QuerySubscriptionInfo({});
    this.loading = false;
    if (
      response &&
      response.status === 200 &&
      response.data?.pc?.status == "EXPIRED"
    ) {
      return false;
    } else {
      return true;
    }
  }

  private async loadShops(): Promise<void> {
    this.loading = true;
    this.stores = [];
    const shopResponse = await OrderService.GetListOfShopsV2({});
    if (!shopResponse || shopResponse.status != 200) {
      this.handleApiError(shopResponse);
    }
    (shopResponse?.data?.companies ?? []).forEach((x) => {
      let leading = true;
      (x.shops ?? []).forEach((y) => {
        this.stores.push({
          source: y,
          companyName: x.name,
          label: y.name ?? "",
          shopNo: y.shopNo ?? "",
          prompt: x.subscription?.prompt,
          available: x.subscription?.status != "EXPIRED",
          subscribe: x.subscription?.status == "EXPIRED",
          spanRows: leading ? x.shops?.length ?? 0 : 0,
        });
        leading = false;
      });
    });
    const createCompanyResponse = await OrderService.CheckIfCreatedCompany({});
    if (
      createCompanyResponse &&
      createCompanyResponse.status == 200 &&
      createCompanyResponse.data?.companyCreated == false
    ) {
      this.createCompanyButtonVisible = true;
    }
    this.shopsVisible = true;
    this.loading = false;
  }

  async loadShopData(): Promise<boolean> {
    this.loading = true;
    const success =
      (await StorageManager.loadMenu()) &&
      (await StorageManager.loadCardTypes()) &&
      (await StorageManager.loadPayTypes()) &&
      (await StorageManager.loadProjects()) &&
      (await StorageManager.loadEmployees()) &&
      (await StorageManager.loadSecurePay()) &&
      (await StorageManager.loadPrinter());
    this.loading = false;
    return success;
  }

  async selectShop(
    row: StoreTableRow<GetListOfShopResponseShop>
  ): Promise<void> {
    StorageManager.setObject(StorageManager.store, {
      id: row.source.shopId,
      name: row.source.name ?? "",
      mobilePhone1: row.source.mobilePhone1 ?? "",
      mobilePhone2: row.source.mobilePhone2 ?? "",
      address: row.source.address ?? "",
      wechat: row.source.wechat ?? "",
      joinPassword: row.source.joinPassword ?? "",
    });
    if (await this.loadShopData()) {
      AppRouter.instance.goTo(AppRouter.home, {});
    } else {
      this.logout();
    }
  }

  subscribe(row: StoreTableRow<GetListOfShopResponseShop>): void {
    AppRouter.instance.goTo(
      AppRouter.subscriptionPlans,
      {},
      {
        origin: AppRouter.launch,
        shopId: row.source.shopId.toString(),
      }
    );
  }

  async createCompany(): Promise<void> {
    if (!Validator.isNotBlankString(this.formData.name)) {
      this.showErrorToast("店铺名称不能为空");
      return;
    }
    this.creating = true;
    const response = await OrderService.CreateCompany({
      data: {
        companyName: this.formData.name,
        shopName: this.formData.name,
      },
    });
    this.creating = false;
    if (response && response.status == 200 && response.data?.shopId) {
      const store: Store = {
        id: response.data.shopId,
        name: this.formData.name ?? "",
      };
      StorageManager.setObject(StorageManager.store, store);
      if (await this.loadShopData()) {
        AppRouter.instance.goTo(AppRouter.home, {});
      } else {
        this.logout();
      }
    } else {
      this.handleApiError(response);
    }
  }

  async switchAccount(): Promise<void> {
    this.switching = true;
    const response = await AccountService.logout({});
    this.switching = false;
    if (!response || response.status != 200) {
      this.handleApiError(response);
      return;
    }
    this.logout();
  }

  storesTableSpanMethod(args: SpanMethodArgs) {
    if (args.columnIndex == 0) {
      if (args.row.spanRows) {
        return {
          rowspan: args.row.spanRows,
          colspan: 1,
        };
      }
      return {
        rowspan: 0,
        colspan: 0,
      };
    } else if (args.columnIndex == 2 && args.row.subscribe == true) {
      if (args.row.spanRows) {
        return {
          rowspan: args.row.spanRows,
          colspan: 1,
        };
      }
      return {
        rowspan: 0,
        colspan: 0,
      };
    }else if (args.columnIndex == 3 && args.row.subscribe == true) {
      if (args.row.spanRows) {
        return {
          rowspan: args.row.spanRows,
          colspan: 1,
        };
      }
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
  }
}

interface StoreTableRow<T> {
  source: T;
  companyName?: string;
  label?: string;
  shopNo?: string;
  prompt?: string;
  available?: boolean;
  subscribe?: boolean;
  spanRows?: number;
}

interface CreateCompanyFormData {
  name?: string;
}

interface SpanMethodArgs {
  rowIndex: number;
  columnIndex: number;
  row: StoreTableRow<GetListOfShopsV2ResponseShop>;
}
