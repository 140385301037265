import Debug from "@/libs/Debug";
import Global from "@/libs/Global";
import { DataMessage, EmptyMessage, Message } from "./messages";

export default class RendererMessageManager {
  static instance = new RendererMessageManager();

  private renderer?: IpcRendererProxy;
  private loaded = false;
  private getRenderer(): IpcRendererProxy | undefined {
    if (!this.loaded && !this.renderer) {
      this.renderer = Global.get<IpcRendererProxy>("ipcRenderer");
      if (this.renderer) {
        Debug.info("[Renderer Message Manager] Renderer succeeded to load.");
      } else {
        Debug.info("[Renderer Message Manager] Renderer failed to load.");
      }
      this.loaded = true;
    }
    return this.renderer;
  }

  rendererLoaded(): boolean {
    return this.getRenderer() != undefined;
  }

  sendDataMessage<T>(message: DataMessage<T>) {
    const renderer = this.getRenderer();
    if (renderer) {
      renderer.send(message.channel, message);
      Debug.info("[Renderer Message Manager] sent: " + JSON.stringify(message));
    }
  }

  sendEmptyMessage(message: EmptyMessage) {
    const renderer = this.getRenderer();
    if (renderer) {
      renderer.send(message.channel, message);
      Debug.info("[Renderer Message Manager] sent: " + JSON.stringify(message));
    }
  }

  sendSyncDataMessage<TM, TR>(
    message: DataMessage<TM>
  ): DataMessage<TR> | undefined {
    const renderer = this.getRenderer();
    if (renderer) {
      const result = renderer.sendSync(message.channel, message) as DataMessage<
        TR
      >;
      Debug.info(
        "[Renderer Message Manager] sent: " +
          JSON.stringify(message) +
          " received: " +
          JSON.stringify(result)
      );
      return result;
    }
    return undefined;
  }

  listenDataMessage<T>(
    channel: string,
    handler: (message: DataMessage<T>) => void
  ) {
    const renderer = this.getRenderer();
    if (renderer) {
      renderer.on(channel, (message: Message) => {
        const dataMessage = message as DataMessage<T>;
        Debug.info(
          "[Renderer Message Manager] received: " + JSON.stringify(dataMessage)
        );
        handler(dataMessage);
      });
      Debug.info(
        "[Renderer Message Manager] event '" + channel + "' listening..."
      );
    }
  }

  listenEmptyMessage(
    channel: string,
    handler: (message: EmptyMessage) => void
  ) {
    const renderer = this.getRenderer();
    if (renderer) {
      renderer.on(channel, (message: Message) => {
        Debug.info(
          "[Renderer Message Manager] received: " + JSON.stringify(message)
        );
        handler(message);
      });
      Debug.info(
        "[Renderer Message Manager] event '" + channel + "' listening..."
      );
    }
  }
}

interface IpcRendererProxy {
  send(channel: string, message: Message): void;
  sendSync(channel: string, message: Message): any;
  on(channel: string, handler: (message: Message) => void): void;
}
