export default class Validator {
  static isNumber(value: unknown): boolean {
    return !isNaN(Number(value));
  }
  static isNonnegativeNumber(value: unknown): boolean {
    if (!this.isNumber(value)) {
      return false;
    }
    return Number(value) >= 0;
  }
  static isPositiveNumber(value: unknown): boolean {
    if (!this.isNumber(value)) {
      return false;
    }
    return Number(value) > 0;
  }
  static isPositiveInteger(value: unknown): boolean {
    if (!this.isNumber(value)) {
      return false;
    }
    const number = Number(value);
    return Number.isInteger(number) && number > 0;
  }
  static isNotBlankString(value: unknown): boolean {
    return (
      value != undefined && typeof value == "string" && value.trim().length > 0
    );
  }
  static isPhoneNumber(value: unknown): boolean {
    if (!this.isNotBlankString(value)) {
      return false;
    }
    return new RegExp("^1\\d{10}$", "i").test(String(value));
  }
}
