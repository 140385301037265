
import { Options } from "vue-class-component";
import { BaseVue } from "@/libs/BaseVue";
import AccountService from "@/api/AccountService";
import Md5 from "@/libs/Md5";
import AppRouter from "@/router/AppRouter";
import StorageManager from "@/libs/StorageManager";
import Validator from "@/libs/Validator";
import { LocalStorageProvider } from "@/libs/StorageProvider";

@Options({})
export default class LoginByPasswordPage extends BaseVue {
  loading = false;
  formData: LoginByPasswordFormData = {
    phone: "",
    password: "",
  };
  created(): void {
    const phone = new LocalStorageProvider().getItem(StorageManager.phone);
    if (phone.length > 0) {
      this.formData.phone = phone;
    }
  }
  async login(): Promise<void> {
    if (!Validator.isPhoneNumber(this.formData.phone)) {
      this.showErrorToast("非法手机号");
      return;
    }
    if (!Validator.isNotBlankString(this.formData.password)) {
      this.showErrorToast("密码不能为空");
      return;
    }
    this.loading = true;
    const response = await AccountService.loginByPassword({
      data: {
        phoneNumber: this.formData.phone,
        password: Md5.encode(this.formData.password ?? ""),
      },
    });
    this.loading = false;
    if (
      response &&
      response.status === 200 &&
      response.data &&
      response.data.token
    ) {
      StorageManager.setString(StorageManager.token, response.data.token);
      new LocalStorageProvider().setItem(
        StorageManager.phone,
        this.formData.phone ?? ""
      );
      AppRouter.instance.goTo(AppRouter.launch, {});
    } else {
      this.handleApiError(response);
    }
  }
}

interface LoginByPasswordFormData {
  phone?: string;
  password?: string;
}
