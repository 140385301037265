export default interface StorageProvider {
  getItem(key: string): string;
  setItem(key: string, value: string): void;
  removeItem(key: string): void;
}

export class LocalStorageProvider implements StorageProvider {
  getItem(key: string): string {
    return localStorage.getItem(key) ?? "";
  }
  setItem(key: string, value: string): void {
    localStorage.setItem(key, value);
  }
  removeItem(key: string): void {
    localStorage.removeItem(key);
  }
}

export class MemoryStorageProvider implements StorageProvider {
  getItem(key: string): string {
    return this.keyValues.get(key) ?? "";
  }
  setItem(key: string, value: string): void {
    this.keyValues.set(key, value);
  }
  removeItem(key: string): void {
    this.keyValues.delete(key);
  }
  keyValues = new Map<string, string>();
}
