
import { Options } from "vue-class-component";
import OrderService from "@/api/OrderService";
import { TabVue } from "@/libs/TabVue";
import { CountItemTableRow } from "@/api/contracts/report";

@Options({})
export default class RevenueReportPage extends TabVue {
  dateRange: string[] = [];
  querying = false;
  totalAmountTableData: TotalAmountTableRow[] = [];
  paymentAmountTableData: PaymentAmountTableRow[] = [];
  countPurchaseDetails: CountItemTableRow[]=[]
  async refresh(): Promise<void> {
    this.dateRange = [];
    this.querying = false;
    this.totalAmountTableData = [];
    this.paymentAmountTableData = [];
    this.countPurchaseDetails=[]
  }
  async query(): Promise<void> {

    if (!this.dateRange) {
      this.showErrorToast("请先选择查询起始和结束日期");
      return;
    }
    const startDate = this.dateRange[0];
    const endDate = this.dateRange[1];
    if (!startDate || !endDate) {
      this.showErrorToast("请先选择查询起始和结束日期");
      return;
    }
    this.querying = true;

    this.totalAmountTableData = [];
    this.paymentAmountTableData = [];
    this.countPurchaseDetails=[]
    const response = await OrderService.GetSalesReport({
      data: {
        startDate: startDate,
        endDate: endDate,
      },
    });
    this.querying = false;
    if (!response || response.status != 200) {
      this.handleApiError(response);
      return;
    }
    this.totalAmountTableData = [];
    this.totalAmountTableData.push({
      receivable: (response.data?.receivable ?? 0).toString(),
      guestPayment: (response.data?.guestPayment ?? 0).toString(),
      vipPurchasePayment: (
        (response.data?.vipCashPayment ?? 0) +
        (response.data?.vipCardPayBillAmount ?? 0)
      ).toString(),
      vipTopUpPayment: (response.data?.vipTopUpPayment ?? 0).toString(),
    });
    if (response.data?.guestPaymentDetails) {
      response?.data?.guestPaymentDetails.forEach((x) =>
        this.paymentAmountTableData.push({
          title: "散客消费",
          payType: x.payName,
          amount: (x.amount ?? 0).toString(),
        })
      );
    }
    if (response.data?.vipPurchasePaymentDetails) {
      response?.data?.vipPurchasePaymentDetails.forEach((x) =>
        this.paymentAmountTableData.push({
          title: "会员消费",
          payType: x.payName,
          amount: (x.amount ?? 0).toString(),
        })
      );
    }
    if (response.data?.vipTopUpPaymentDetails) {
      response?.data?.vipTopUpPaymentDetails.forEach((x) =>
        this.paymentAmountTableData.push({
          title: "会员充值",
          payType: x.payName,
          amount: (x.amount ?? 0).toString(),
        })
      );
    }
    if(response?.data?.countPurchaseDetails){
      response?.data?.countPurchaseDetails.forEach(a=>{
        this.countPurchaseDetails.push(a)
      })
    }
  }
  paymentAmountTableSpanMethod(args: SpanMethodArgs) {
    if (args.columnIndex == 0) {
      const countOfGuestPayment = this.paymentAmountTableData.filter(
        (x) => x.title == "散客消费"
      ).length;
      const countOfVipPurchasePayment = this.paymentAmountTableData.filter(
        (x) => x.title == "会员消费"
      ).length;
      const countOfVipTopUpPayment = this.paymentAmountTableData.filter(
        (x) => x.title == "会员充值"
      ).length;
      if (args.rowIndex < countOfGuestPayment) {
        if (args.rowIndex == 0) {
          return {
            rowspan: countOfGuestPayment,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
      if (
        args.rowIndex >= countOfGuestPayment &&
        args.rowIndex < countOfGuestPayment + countOfVipPurchasePayment
      ) {
        if (args.rowIndex == countOfGuestPayment) {
          return {
            rowspan: countOfVipPurchasePayment,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
      if (
        args.rowIndex >= countOfGuestPayment + countOfVipPurchasePayment &&
        args.rowIndex <
          countOfGuestPayment +
            countOfVipPurchasePayment +
            countOfVipTopUpPayment
      ) {
        if (args.rowIndex == countOfGuestPayment + countOfVipPurchasePayment) {
          return {
            rowspan: countOfVipTopUpPayment,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    }
  }
}

interface TotalAmountTableRow {
  receivable?: string;
  guestPayment?: string;
  vipPurchasePayment?: string;
  vipTopUpPayment?: string;
}

interface PaymentAmountTableRow {
  title?: string;
  payType?: string;
  amount?: string;
}

// interface CountItemTableRow {
//   itemName?: string;
//   count: number;
// }


interface SpanMethodArgs {
  rowIndex: number;
  columnIndex: number;
}
