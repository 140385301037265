
import { 
PayBillRequest, 
PayBillRequestData,
GetSystemBillNoRequest,
TopUpVipCardRequest,
TopUpVipCardRequestData,
GetPageOfVipRequest,
GetPageOfVipRequestData,
GetVipInfoRequest,
GetVipInfoRequestData
 } from "@/api/contracts/vip";
import OrderService from "@/api/OrderService";
import MarketingService from "@/api/MarketingService";
import { 
GetCouponInfoResponseData, 
GetCouponInfoRequest,
GetCouponInfoRequestData,
CouponLimit,
GetCouponInfoResponse
 } from "@/api/contracts/coupon";
import { CardType,ProjectItem,Employee, PayType, Printer} from "@/data/storage";
import { BaseVue } from "@/libs/BaseVue";
import StorageManager from "@/libs/StorageManager";
import { Options } from "vue-class-component";
import { ElMessage } from 'element-plus';
import { CalculateBonusRequest,CalculateBonusRequestData } from "@/api/contracts/bonus";
import BillPrintDialog from "@/components/BillPrintDialog.vue"
import moment from 'moment'
import SecureEnsureDialog from "@/components/SecureEnsureDialog.vue"

@Options({
  components: {
    BillPrintDialog,
    SecureEnsureDialog
  }
})
export default class PayBillPage extends BaseVue {
  formData: FormData = {
    userName: "",
    
    sex: "F",
    cardType:undefined,
    phone: "",
    remark:"",
    birthDay:"",
    cardNo:"",
    casherType:1,
    projects:[],
    activeTab:'cz',
    cashTab:'vipinfo',
    tclist:[],

    systemBillNo:'',
    manualBillNo:'',
    inputMoney:'',
    payId:1,
    payTime:'',
    discountAmount:'',
    couponNumber:'',
    securePayCode:''

  }
  securePay=false
  notify = true
  loading=false
  formRules = {
    userName: [{ required: true, message: "请输入会员姓名", trigger: "blur" }],
    phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
    cardType: [{ required: true, message: "请选择卡种", trigger: "blur" }],
  }
  projectOptions:ProjectOption [] =[ ];
  employeeOptions:EmployeeOption [] =[];
  cardTypeOptions: CardTypeOption[] = [];
  payTypeOptions:PayTypeOption[] = [];
  allPayTypeList:PayTypeOption[] = [];

  vipOptions:VipOption [] =[];
  vipInfo: Vip={
    vipId:0,
    name:"",
    gender:"",
    cardTypeId:0,
    phone:"",
    balance:0,
    discountValue:0,
    remark:"",
    points:0,
    timeBalance :[],
    enableSecurePay:false
  }

  errors: string[] = [];

  secure : any;

  billPrintId = 0;
  getCardTypeName(id:number){
    let node = this.cardTypeOptions.find(a=>a.value===id)
    return node?.label
  }
  changeCasherType(val:number){
    this.resetVipInfo()
    this.formData.userName=''
    this.formData.projects=[]
    this.formData.tclist=[]
    this.formData.couponNumber=''
    this.formData.discountAmount=''
    
    if(val===1){
 
      this.payTypeOptions=this.allPayTypeList
      this.formData.payId=1
    }else{
       this.payTypeOptions=this.allPayTypeList.filter(a=>a.type==='CASH')
       this.formData.payId=2
    }

    this.addProject()
    this.addStaff()
   
  }
  resetVipInfo(){
      this.vipInfo={
        vipId:0,
        name:"",
        gender:"",
        cardTypeId:0,
        phone:"",
        balance:0,
        discountValue:0,
        remark:"",
        points:0,
        timeBalance :[],
        enableSecurePay:false
      }
  }
  async couponNumberChange(val:string){
    if(!val) return
    const request=new GetCouponInfoRequest()
    request.data=new GetCouponInfoRequestData()
    request.data.couponNumber=val
    request.data.userId=this.vipInfo.vipId
    const res= await  MarketingService.GetCouponInfo(request)
    if(res?.data){
         if(res.data.expiryTime && new Date().getTime() >= new Date(res.data.expiryTime.replace(/-/g,'/')).getTime()  ){
           ElMessage.error(`券已经过期，过期时间：${res.data.expiryTime}，不能使用！`)
          this.formData.couponNumber=''
        }
        let lower = 0
        if(res.data.limits && res.data.limits[0] && res.data.limits[0].lower){
           lower =  res.data.limits[0].lower
        }
        if(res.data.limits && this.totalMoney() < lower ){
          ElMessage.error(`总金额${ this.totalMoney() }小于该券的最低消费门槛${res.data.limits[0].lower}，不能使用！`)
          this.formData.couponNumber=''
        }
    }
      
       
  }
  totalMoney(){
    let sum = 0
    this.formData.projects.forEach(a=>{
       sum = sum + a.realPrice * a.count
    })
    if(this.formData.discountAmount){
      sum = sum- Number(this.formData.discountAmount)
    }
    return sum
  }
  async activated(): Promise<void> {
    await this.init()
  }
  async created(): Promise<void> {
    
    await this.init()
  }

  async init ():Promise<void>{
    await this.getBillNo()
    const cardTypes: CardType[] | undefined = StorageManager.getObject(
      StorageManager.cardTypes
    );
     const projects: ProjectItem[] | undefined = StorageManager.getObject(
      StorageManager.projects
    );
     const employees: Employee[] | undefined = StorageManager.getObject(
      StorageManager.employees
    );
     const payTypes: PayType[] | undefined = StorageManager.getObject(
      StorageManager.payTypes
    );
    if (cardTypes) {
      for (const cardType of cardTypes) {
        this.cardTypeOptions.push({
          label: cardType.name ?? "",
          value: cardType.id,
        });
      }
    }
    if(employees){
      this.employeeOptions  = employees.map(a=> { 
         return {label:a.name,value:a.userId,position:a.position}
      })
    }
    if(projects){
      this.projectOptions  = projects.map(a=> { 
         return {label:a.name,value:a.id,price:a.price,vipPrice:a.vipPrice}
      })
    }
    if(payTypes){ 
      let list =payTypes.map(a=>{
        return { label:a.name?a.name:'',value:a.id,type:a.type }
      })
      this.allPayTypeList=list
      this.payTypeOptions=list
    }

  }

  async mounted():Promise<void>{
    
    this.addProject()
    this.addStaff()
    this.fillPayTime()
    this.secure  = this.$refs.secure;
     // 消费密码弹窗
    this.securePay = StorageManager.getObject<boolean>(StorageManager.securePay) || false
  }

  fillPayTime():void{
    const h = Number(moment().format('HH')) 
    if(h<6){
      // 每天凌晨6点之前，结算时间默认昨天
      this.formData.payTime = moment().add(-1,'days').format('YYYY-MM-DD')
    }

  }

  async getBillNo():Promise<void>{
     const response = await OrderService.GetSystemBillNo(new GetSystemBillNoRequest());
    
    if (response && response.status === 200) {
       if(response.data?.systemBillNo){
           this.formData.systemBillNo= response.data?.systemBillNo
       }
    } else {
      this.handleApiError(response);
    }
  }
addToCash(row:VipInfoTimeBalance){
    let node = this.projectOptions.find(a=>a.value===row.itemId)
    if(!node){
      ElMessage.error('该项目不存在，请检查是否已经被删除！')
      return
    }
    let has=this.formData.projects.find(a=>a.itemId===row.itemId)
    if( has){
      ElMessage.error('该项目已经存在，不能重复添加！')
      return
    }
     this.formData.projects.push({
      itemId:row.itemId,
      itemName:row.name,
      realPrice:0,
      count:1,
      price:0,
      type:1
    })
    ElMessage.success('成功添加到消费列表，快去结算吧')
}
 async remoteMethod(query:string){
     if (query !== '') {
          this.loading = true;
          // ajax  fetch data ,then fill this.vipOptions
          let request=new GetPageOfVipRequest()
          request.data=new GetPageOfVipRequestData()
          request.data.keyword=query
          const response = await OrderService.GetPageOfVip(request);
          this.loading = false;
          if (response && response.status === 200 && response.data) {
            this.vipOptions=response.data.items.map(a=>{
              return {
                userId:a.vipId,
                name:a.name,
                phone:a.phone,
                disabled:a.disabled
              }
            })  
          } else {
            this.handleApiError(response);
          }
        } else {
          this.vipOptions = [];
        }
  }

  async save(): Promise<void> {
    let formRef:any=this.$refs.baseForm
    formRef.validate((valid:boolean) => {
          if (valid) {
            this.saveCash()
          }
        }) 
   }

   secureOk(code:string):void{
     this.formData.securePayCode = code 
    //  this.save()
   }
  
  async saveCash():Promise<void> { 

    const request = new PayBillRequest();
    request.data = new PayBillRequestData();
     

      let payTime=this.formData.payTime?( moment(this.formData.payTime).format('YYYY-MM-DD') +' 12:18:28'):''
    
      request.data.vipId=this.vipInfo.vipId
      request.data.payId=this.formData.payId
      request.data.systemBillNo=this.formData.systemBillNo
      request.data.manualBillNo=this.formData.manualBillNo
      request.data.discountAmount=this.formData.discountAmount?Number(this.formData.discountAmount):0
      request.data.couponNumber=this.formData.couponNumber,
      request.data.payTime=payTime
      request.data.remark=this.formData.remark
      request.data.disableNodify = !this.notify
      request.data.securePayCode = this.formData.securePayCode

      if(this.formData.casherType===1){
        if(!this.vipInfo.vipId){
           ElMessage.error('请录入会员信息！')
          return 
        }
      }

    let validPrtojects = this.formData.projects.filter(a=>a.itemId)
    if(validPrtojects.length===0){
           ElMessage.error('请至少选择一个消费项目！')
          return 
    }
    
   
    if(this.vipInfo.vipId && this.vipInfo.enableSecurePay && this.securePay && !this.formData.securePayCode){
        this.secure.openDialog()
        return
    }


      request.data.billItems=validPrtojects.map(a=>{
        return {
          itemId: a.itemId,
          count: a.count,
          price: a.realPrice,
          purchaseType: a.type
        }
      })

      let validTcList=this.formData.tclist.filter(a=>a.userId)
      request.data.employeeBonuses=validTcList.map(a=>{
        return {
          source:0,
          amount:a.money,
          userId:a.userId
        }
      })
    
    
    this.showLoading()
    const response = await OrderService.PayBill(request);
    this.hideLoading()
    if (response && response.status === 200) {
      this.showAlert("买单成功！", () => {
        const printer: Printer | undefined = StorageManager.getObject(StorageManager.printer)
        if (printer && printer.payBill) {
          this.billPrintId = response?.data?.billId ?? 0
        }
      })
      this.clear();
    } else {
      this.handleApiError(response);
    }

  }
  clear(): void {
    this.formData = {
      userName: "",
      sex: "F",
      phone: "",
      cardType:undefined,
      cardNo:"",
      remark:"",
      birthDay:"",
      projects:[],
      casherType:1,
      activeTab:"cz",
      cashTab:'vipinfo',
      tclist:[],
      systemBillNo:'',
      manualBillNo:'',
      inputMoney:'',
      payId:1,
      payTime:'',
      discountAmount:'',
      couponNumber:'',
      securePayCode:''
      
    };
    this.errors = [];
    this.addProject()
    this.addStaff()
    this.getBillNo()
    this.resetVipInfo()
    this.fillPayTime()
  }

  addProject():void{
    this.formData.projects.push({
    // itemId:null,
    itemName:'',
    realPrice:0,
    count:1,
    price:0,
    type:0
    })
  }
  removeProject(index:number):void{
    this.formData.projects.splice(index,1)
  }
  addStaff():void{
    this.formData.tclist.push({
       userId:null,
       userName:'',
       money:''
    })
  }
  removeStaff(index:number):void{
    this.formData.tclist.splice(index,1)
  }
  async onVipSelect(val:number){
        let request=new GetVipInfoRequest()
          request.data=new GetVipInfoRequestData()
          let node = this.vipOptions.find( a=> a.userId===val)
          if(!node) return
          request.data.phone=node.phone
          const response = await OrderService.GetVipInfo(request);
          if (response && response.status === 200) { 
            this.vipInfo=response.data 
          } else {
            this.handleApiError(response);
          }
  }
  onProjectSelect(val:number,row:Project,index:number){
     //  edit row 
     // val 选中项目ID
     //row 编辑的那行
     // index 编辑行的序号
     let project = this.formData.projects[index] //
     let selected =this.projectOptions.find(a=>a.value===val)
     if(!selected){
       return
     }
     if(this.formData.casherType===1){
      let realPrice=''
      let vipPrice = selected.vipPrice > 0 ? selected.vipPrice : selected.price;
      project.price= vipPrice;
      if(this.vipInfo.discountValue>1){
        realPrice=(vipPrice -this.vipInfo.discountValue).toFixed(2).toString()
       }else if(this.vipInfo.discountValue>0 && this.vipInfo.discountValue<=1){
        realPrice=(vipPrice * this.vipInfo.discountValue).toFixed(2).toString()
       }else{
         realPrice=vipPrice.toString()
       }
       project.realPrice=Number(realPrice)
     }else{
       project.price= selected.price
       project.realPrice=selected.price
     }
     
  }
   async onStaffSelect(val:number,row:any,index:number){
     //  edit row 
     let tc = this.formData.tclist[index] //
     let selected =this.employeeOptions.find(a=>a.value===val)
     if(!selected){
       return
     }
     
    let bonus= await this.calculateBonus(val)

     tc.money=  bonus
      
  }

    async calculateBonus(userId:number):Promise<any>{

     let request=new CalculateBonusRequest()
     request.data=new CalculateBonusRequestData()
     
    //  let badItems = this.formData.projects.filter(a=>!a.itemId)

       let projects = this.formData.projects.filter(a=>a.itemId).map(a=>{
         return {
          id:a.itemId,
          type:a.type,//0 金额消费   1次数消费
          count:a.count,
          price:a.realPrice
        }
       })
 
     request.data.items=projects
     request.data.employees=[{userId:userId}]
     request.data.vipId = this.vipInfo.vipId;
     
     const response = await OrderService.CalculateBonus(request);
    
    if (response && response.status === 200) {
       if(response.data?.employees){
          let bonus=  response.data?.employees[0].bonus
          return bonus
       }
    } else {
      this.handleApiError(response);
    }
  }
}

interface CardTypeOption {
  label: string;
  value: number;
}


interface Vip{
  vipId:number
  name:string;
  gender:string;
  cardTypeId:number;
  phone:string;
  balance:number;
  discountValue:number;
  remark:string;
  points:number;
  timeBalance :Array<VipInfoTimeBalance>;
  enableSecurePay:boolean
}

interface VipInfoTimeBalance{
  itemId:number;
  count:number;
  name:string;
}

interface PayTypeOption {
  label: string;
  value: number;
  type?:string;
}
interface VipOption{
  name:string;
  phone:string;
  userId:number;
  disabled:boolean;
}

interface ProjectOption {
  label: string;
  value: number;
  price:number;
  vipPrice:number;
}

interface EmployeeOption {
  label: string;
  value: number;
  position:string;
}


interface Project{
    itemId?:number;
    itemName:string;
    realPrice:number;
    price:number;
    count:number;
    type?:number;

}

interface FormData {
  userName: string;
  sex: string;
  phone: string;
  cardType?: number;
  remark:string;
  cardNo:string;
  birthDay:string;
  casherType:number;
  projects:Array<Project>;
  activeTab:string;
  cashTab:string;
  tclist:Array<any>;
  systemBillNo:string;
  manualBillNo:string;
  inputMoney:string;
  payId:number;
  payTime:string;
  discountAmount:string;
  couponNumber:string;
  securePayCode:string;
}
