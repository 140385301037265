
import { GetCardTypesResponseItem } from "@/api/contracts/cardtype";
import OrderService from "@/api/OrderService";
import { TabVue } from "@/libs/TabVue";
import Validator from "@/libs/Validator";
import { ElMessageBox } from "element-plus";
import { Options } from "vue-class-component";
import StorageManager from '@/libs/StorageManager'
@Options({})
export default class CardTypeConfigurationPage extends TabVue {
  loading = true;
  typeOptions = [
    {
      label: "折扣优惠",
      value: "PERCENTAGE",
    },
    {
      label: "固定金额优惠",
      value: "FIXED_AMOUNT",
    },
    {
      label: "无优惠",
      value: "NO_DISCOUNT",
    },
  ];
  scopeOptions = [
    {
      label: "所有门店通用",
      value: "ALL",
    },
    {
      label: "仅限开卡门店使用",
      value: "ONE_SHOP_ONLY",
    },
  ];
  tableData: CardTypeTableRow<GetCardTypesResponseItem>[] = [];
  dialogVisible = false;
  formData: CardTypeFormData<GetCardTypesResponseItem> = {
    source: new GetCardTypesResponseItem(),
  };
  saving = false;
  async refresh(): Promise<void> {
    await this.loadTableData();
  }
  async loadTableData(): Promise<void> {
    this.tableData = [];
    this.loading = true;
    const response = await OrderService.GetLisfOfCardTypes({});
    this.loading = false;
    if (response && response.status === 200) {
      
      const storeList = response.data?.items?.map(a=>{
        return {id:a.id,name:a.name,discountType:a.discountType,amount:a.amount}
      })
       StorageManager.setObject(StorageManager.cardTypes, storeList);
     
      response.data?.items?.forEach((item) => {
        this.tableData.push({
          source: item,
          name: item.name,
          type:
            item.discountType == "PERCENTAGE"
              ? "折扣比例"
              : item.discountType == "FIXED_AMOUNT"
              ? "固定金额"
              : "无优惠(原价)",
          amount:
            item.discountType == "PERCENTAGE"
              ? (item.amount * 10).toFixed(1).toString() + "折"
              : item.discountType == "FIXED_AMOUNT"
              ? item.amount.toString() + "元"
              : "",
          deletable: item.deletable,
          editable: item.editable,
          scope: item.oneShopOnly ? "仅限开卡门店使用" : "所有门店通用",
        });
      });
    } else {
      this.handleApiError(response);
    }
  }
  handleDelete(
    _: number,
    row: CardTypeTableRow<GetCardTypesResponseItem>
  ): void {
    ElMessageBox.confirm(
      `确认删除卡类型“${row.source.name}”, 是否继续?`,
      "提示",
      {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        beforeClose: async (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            const response = await OrderService.DeleteCardType({
              data: {
                cardTypeId: row.source.id,
              },
            });
            if (response && response.status == 200) {
              await this.loadTableData();
              this.showSuccessToast("删除成功");
            } else {
              this.handleApiError(response);
            }
            done();
          } else {
            done();
          }
        },
      }
    ).then(() => {});
  }
  handleEdit(_: number, row: CardTypeTableRow<GetCardTypesResponseItem>): void {
    this.formData = {
      source: row.source,
      name: row.source.name,
      type: row.source.discountType,
      amount: row.source.amount.toString(),
      scope: row.source.oneShopOnly ? "ONE_SHOP_ONLY" : "ALL",
    };
    this.dialogVisible = true;
  }
  handleCreate(): void {
    this.formData = {
      source: new GetCardTypesResponseItem(),
    };
    this.dialogVisible = true;
  }
  async save(): Promise<void> {
    if (!Validator.isNotBlankString(this.formData.name)) {
      this.showErrorToast("卡种名称不能为空");
      return;
    }
    if (!this.formData.type) {
      this.showErrorToast("必须选择一种优惠类型");
      return;
    }
    let discountValue = 0;
    if (this.formData.type == "PERCENTAGE") {
      if (!Validator.isNonnegativeNumber(this.formData.amount)) {
        this.showErrorToast("优惠折扣必须为大于0小于1的值");
        return;
      }
      discountValue = Number(this.formData.amount);
      if (discountValue <= 0 || discountValue >= 1) {
        this.showErrorToast("优惠折扣必须为大于0小于1的值");
        return;
      }
    } else if (this.formData.type == "FIXED_AMOUNT") {
      if (!Validator.isNonnegativeNumber(this.formData.amount)) {
        this.showErrorToast("固定优惠金额必须为大于1的值");
        return;
      }
      discountValue = Number(this.formData.amount);
      if (discountValue <= 1) {
        this.showErrorToast("固定优惠金额必须为大于1的值");
        return;
      }
    } else if (this.formData.type == "NO_DISCOUNT") {
      discountValue = 1;
    }
    if (!this.formData.scope) {
      this.showErrorToast("请选择使用范围");
      return;
    }
    this.saving = true;
    if (this.formData.source.id > 0) {
      const response = await OrderService.UpdateCardType({
        data: {
          id: this.formData.source.id,
          name: this.formData.name,
          discountValue: discountValue,
          oneShopOnly: this.formData.scope == "ONE_SHOP_ONLY",
        },
      });
      if (!response || response.status != 200) {
        this.handleApiError(response);
        return;
      }
    } else {
      const response = await OrderService.CreateCardType({
        data: {
          name: this.formData.name,
          discountValue: discountValue,
          oneShopOnly: this.formData.scope == "ONE_SHOP_ONLY",
        },
      });
      if (!response || response.status != 200) {
        this.handleApiError(response);
        return;
      }
    }
    this.saving = false;
    this.showSuccessToast("保存成功");
    this.dialogVisible = false;
    await this.loadTableData();
  }
}

interface CardTypeTableRow<T> {
  source: T;
  name?: string;
  type?: string;
  amount?: string;
  deletable: boolean;
  editable: boolean;
  scope?: string;
}

interface CardTypeFormData<T> {
  source: T;
  name?: string;
  type?: string;
  amount?: string;
  scope?: string;
}
