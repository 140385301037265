import { RequestBase, ResponseBase } from "./base";

export class GetCouponStrategiesRequestData {
  pageNumber?: number;
  pageSize?: number;
}

export class GetCouponStrategiesRequest extends RequestBase {
  data?: GetCouponStrategiesRequestData;
}

export class GetCouponStrategiesResponseLimit {
  lower?: number;
  upper?: number;
  amount?: number;
}

export class GetCouponStrategiesResponseStrategy {
  strategyId?: number;
  shopId?: number;
  name?: string;
  title?: string;
  totalStock?: number;
  consumeStock?: number;
  multiple?: boolean;
  validDays?: number;
  enabled?: boolean;
  remark?: string;
  limits?: GetCouponStrategiesResponseLimit[];
}

export class GetCouponStrategiesResponseData {
  strategies?: GetCouponStrategiesResponseStrategy[];
  totalCount?: number;
}

export class GetCouponStrategiesResponse extends ResponseBase {
  data?: GetCouponStrategiesResponseData;
}

export class IssueCouponRequestData {
  strategyId?: number;
  userId?: number;
}

export class IssueCouponRequest extends RequestBase {
  data?: IssueCouponRequestData;
}
export class IssueCouponResponse extends ResponseBase {}

export class AddCouponStrategyRequestLimit {
  lower?: number;
  upper?: number;
  amount?: number;
}

export class AddCouponStrategyRequestData {
  name?: string;
  title?: string;
  stock?: number;
  multiple?: boolean;
  validDays?: number;
  remark?: string;
  limits?: AddCouponStrategyRequestLimit[];
}

export class AddCouponStrategyRequest extends RequestBase {
  data?: AddCouponStrategyRequestData;
}

export class AddCouponStrategyResponse extends ResponseBase {}

export class DeleteCouponStrategyRequestData {
  strategyId?: number;
}

export class DeleteCouponStrategyRequest extends RequestBase {
  data?: DeleteCouponStrategyRequestData;
}

export class DeleteCouponStrategyResponse extends ResponseBase {}

export class EnableCouponStrategyRequestData {
  strategyId?: number;
}

export class EnableCouponStrategyRequest extends RequestBase {
  data?: EnableCouponStrategyRequestData;
}

export class EnableCouponStrategyResponse extends ResponseBase {}

export class DisableCouponStrategyRequestData {
  strategyId?: number;
}

export class DisableCouponStrategyRequest extends RequestBase {
  data?: DisableCouponStrategyRequestData;
}

export class DisableCouponStrategyResponse extends ResponseBase {}



export class GetCouponInfoRequestData {
  couponNumber?: string;
  userId?: number;
}

export class GetCouponInfoRequest extends RequestBase {
  data?: GetCouponInfoRequestData;
}

export class GetCouponInfoResponseData {
  
  couponNumber?:string;
  title?:string;
  expiryTime?:string;
  remark?:string;
  limits?: CouponLimit[];

}

export class CouponLimit {
  lower?:number;
  upper?:number;
  amount?:number;
} 

export class GetCouponInfoResponse extends ResponseBase {
  data?: GetCouponInfoResponseData;
}