
import OrderService from "@/api/OrderService";
import { TabVue } from "@/libs/TabVue";
import { Options } from "vue-class-component";

@Options({})
export default class SmsConfigurationPage extends TabVue {
  loading = true;
  formData: SmsFormData = {
    enableTopUp: false,
    enablePayBill: false,
  };
  enableTopUpLoading = false;
  enablePayBillLoading = false;
  async refresh(): Promise<void> {
    await this.loadData();
  }
  async loadData(): Promise<void> {
    this.loading = true;
    const response = await OrderService.GetSmsConfiguration({});
    this.loading = false;
    if (response && response.status === 200) {
      if (response.data) {
        this.formData.enableTopUp = response.data.enableTopUp ?? false;
        this.formData.enablePayBill = response.data.enablePayBill ?? false;
      }
    } else {
      this.handleApiError(response);
    }
  }
  async toggleEnableTopUp(): Promise<boolean> {
    this.enableTopUpLoading = true;
    const response = await OrderService.UpdateSmsConfiguration({
      data: {
        enableTopUp: this.formData.enableTopUp == true ? "OFF" : "ON",
      },
    });
    this.enableTopUpLoading = false;
    if (!response || response.status != 200) {
      this.handleApiError(response);
      return false;
    }
    this.showSuccessToast("保存成功");
    return true;
  }
  async toggleEnablePayBill(): Promise<boolean> {
    this.enablePayBillLoading = true;
    const response = await OrderService.UpdateSmsConfiguration({
      data: {
        enablePayBill: this.formData.enablePayBill == true ? "OFF" : "ON",
      },
    });
    this.enablePayBillLoading = false;
    if (!response || response.status != 200) {
      this.handleApiError(response);
      return false;
    }
    this.showSuccessToast("保存成功");
    return true;
  }
}

interface SmsFormData {
  enableTopUp: boolean;
  enablePayBill: boolean;
}
