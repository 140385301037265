
import { GetListOfItemsResponseItem } from "@/api/contracts/item";
import OrderService from "@/api/OrderService";
import { TabVue } from "@/libs/TabVue";
import Validator from "@/libs/Validator";
import { ElMessageBox } from "element-plus";
import { Options } from "vue-class-component";
import StorageManager from "@/libs/StorageManager";
@Options({})
export default class ItemConfigurationPage extends TabVue {
  loading = true;
  tableData: ItemTableRow<GetListOfItemsResponseItem>[] = [];
  dialogVisible = false;
  formData: ItemFormData<GetListOfItemsResponseItem> = {
    source: new GetListOfItemsResponseItem(),
  };
  saving = false;
  moreCheck=false
  async refresh(): Promise<void> {
    await this.loadTableData();
  }
  async loadTableData(): Promise<void> {
    this.tableData = [];
    this.loading = true;
    const response = await OrderService.GetListOfItems({});
    this.loading = false;
    if (response && response.status === 200) {
      StorageManager.setObject(StorageManager.projects, response.data?.items);
      response.data?.items?.forEach((item) => {
        this.tableData.push({
          source: item,
          name: item.name,
          price: (item.price ?? 0).toString(),
          vipPrice: (item.vipPrice ?? 0).toString(),
        });
      });
    } else {
      this.handleApiError(response);
    }
  }
  handleEdit(_: number, row: ItemTableRow<GetListOfItemsResponseItem>): void {
    this.formData = {
      source: row.source,
      name: row.source.name,
      price: (row.source.price ?? 0).toString(),
      vipPrice: (row.source.vipPrice ?? 0).toString(),
    };
    this.dialogVisible = true;
  }
  handleDelete(_: number, row: ItemTableRow<GetListOfItemsResponseItem>): void {
    ElMessageBox.confirm("确认删除项目, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        this.loading = true;
        const response = await OrderService.DeleteItem({
          data: {
            id: row.source.id,
          },
        });
        this.loading = false;
        if (!response || response.status != 200) {
          this.handleApiError(response);
          return;
        }
        await this.loadTableData();
      })
      .catch(() => {});
  }
  handleCreate(): void {
    this.formData = {
      source: new GetListOfItemsResponseItem(),
      name: "",
      price: "",
      vipPrice: "",
    };
    this.dialogVisible = true;
  }
  async save(): Promise<void> {
    if (!Validator.isNotBlankString(this.formData.name)) {
      this.showErrorToast("项目名称不能为空");
      return;
    }
    if (
      !Validator.isNonnegativeNumber(this.formData.price) ||
      !Validator.isNonnegativeNumber(this.formData.vipPrice)
    ) {
      this.showErrorToast("价格必须输入数字(非负值)");
      return;
    }
    if (this.formData.source.id && this.formData.source.id > 0) {
      this.saving = true;
      const response = await OrderService.UpdateItem({
        data: {
          id: this.formData.source.id,
          name: this.formData.name,
          price: Number(this.formData.price),
          vipPrice: Number(this.formData.vipPrice),
        },
      });
      this.saving = false;
      if (!response || response.status != 200) {
        this.handleApiError(response);
        return;
      }
    } else {
      this.saving = true;
      const response = await OrderService.CreateItem({
        data: {
          name: this.formData.name,
          price: Number(this.formData.price),
          vipPrice: Number(this.formData.vipPrice),
        },
      });
      this.saving = false;
      if (!response || response.status != 200) {
        this.handleApiError(response);
        return;
      }
    }
    this.showSuccessToast("保存成功");
    this.dialogVisible = false;
    await this.loadTableData();
  }
}

interface ItemTableRow<T> {
  source: T;
  name?: string;
  price?: string;
  vipPrice?: string;
}

interface ItemFormData<T> {
  source: T;
  name?: string;
  price?: string;
  vipPrice?: string;
}
